export type Params = {
    LANGUAGE: string;
    USERID: string;
    SESSIONID: string;
    ROOMID: string;
    MOBILE: string;
    CSSOVERRIDEURL: string;
};

export const getQuery = () => {
    const params = new URLSearchParams(window.location.search);
    const result = {} as Params;
    for (const [key, value] of params) {
        result[key as keyof Params] = value;
    }
    return result;
};
