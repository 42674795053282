import { createContext, useContext, useEffect, useState } from 'react';
import { useHookstate } from '@hookstate/core';
import { onAccountStatus } from '../socket/backend/account-status';
import { onBuyCards } from '../socket/backend/buy-cards';
import { onCallMessage } from '../socket/backend/call-message';
import { onConnect } from '../socket/backend/connect';
import { onDisconnect } from '../socket/backend/disconnect';
import { onGameModeChange } from '../socket/backend/game-mode-change';
import { onJackpotContributionMessage } from '../socket/backend/jackpot-contribution-message';
import { onJackpotWinnerMessage } from '../socket/backend/jackpot-winner-message';
import { onJoinGame } from '../socket/backend/join-game';
import {
    BackendPayload,
    createBackendPayload,
} from '../socket/backend/payloads';
import { onGetPickedCards } from '../socket/backend/picked-cards';
import { onWinnerMessage } from '../socket/backend/winner-message';
import { onExtraWinnerMessage } from '../socket/backend/extra-winner-message';
import { BackendSocket, useSocket } from './use-socket';
import { screenConfig as screenConfigState } from '../stores/backend/';

export type Subscriber = (
    socket: BackendSocket,
    payload: BackendPayload
) => () => void;

export const BackendContext = createContext(
    {} as { backendPayload: BackendPayload }
);

export const useBackend = () => {
    const { createBackend } = useSocket();
    const [payload, setPayload] = useState<BackendPayload>();
    const { screenConfig } = useHookstate(screenConfigState);

    useEffect(() => {
        if (!screenConfig.room.gameId.value) {
            return;
        }

        const backend = createBackend();
        const payload = createBackendPayload(backend);

        setPayload(payload);

        const subscriptions = [
            onConnect,
            onDisconnect,
            onCallMessage,
            onAccountStatus,
            onBuyCards,
            onJoinGame,
            onGameModeChange,
            onWinnerMessage,
            onExtraWinnerMessage,
            onJackpotWinnerMessage,
            onJackpotContributionMessage,
            onGetPickedCards,
        ].map((onFn: Subscriber) => onFn(backend, payload));

        return () => {
            for (const unsubscribe of subscriptions) {
                unsubscribe();
            }
            backend.disconnect();
        };
    }, [createBackend, screenConfig.room.gameId.value]);

    return payload;
};

export const useBackendPayload = () => useContext(BackendContext);
