import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type MaxMinButtonProps = HTMLAttributes<HTMLButtonElement> &
    HTMLAttributes<HTMLImageElement> & {
        isMax: boolean;
        handleClick: () => void;
    };

export const MaxMinButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 0.875rem;
    border: solid 0.094rem #003d4b;
    box-shadow: inset 0 0.125rem 0 0 #1afffd,
        inset 0 -0.125rem 0 0 rgba(14, 223, 234, 0.4);
    background-image: linear-gradient(to bottom, #25d1ff, #3295fd 99%);
    cursor: pointer;
`;

export const MaxMinButtonImage = styled.img`
    width: 2.75rem;
    height: 2.75rem;
    image-rendering: -webkit-optimize-contrast;
`;

export const MaxMinButton: FC<MaxMinButtonProps> = ({
    isMax,
    handleClick,
    ...props
}) => {
    const theme = useTheme();
    const onClick = useSoundHandler(handleClick, 'click');

    const { maxButton, minButton } = theme.images;

    return (
        <MaxMinButtonContainer {...props} onClick={onClick}>
            <MaxMinButtonImage
                src={isMax ? minButton : maxButton}
                alt="Max/Min Toggle Icon"
            />
        </MaxMinButtonContainer>
    );
};
