import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type ArrowItemProps = {
    orientation: 'left' | 'right';
};

export type ArrowProps = HTMLAttributes<HTMLImageElement> &
    ArrowItemProps & {
        handleClick: () => void;
    };

const ArrowContainer = styled.img<ArrowItemProps>`
    width: 1.25rem;
    height: 3.75rem;
    border: none;
    padding: 0;
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
    user-select: none;
    transform: ${(props) =>
        props.orientation === 'right' ? 'scaleX(-1)' : null};
`;

export const Arrow: FC<ArrowProps> = ({ handleClick, ...props }) => {
    const theme = useTheme();
    const { arrowLeft } = theme.images;

    const onClick = useSoundHandler(handleClick, 'click');

    return <ArrowContainer src={arrowLeft} onClick={onClick} {...props} />;
};
