import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

type CommonButtonProps = HTMLAttributes<HTMLButtonElement> & {
    handleClick: () => void;
    disabled?: boolean;
};

export const CommonButtonContainer = styled.button`
    width: 13.75rem;
    height: 5rem;
    border-radius: 1.25rem;
    ${({ theme: { commonButton }, disabled }) => `
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        opacity: ${disabled ? 0.5 : 1};
        border: solid 0.094rem ${commonButton.border};
        background-image: linear-gradient(to bottom, ${
            commonButton.backgroundGradient.upper
        }, ${commonButton.backgroundGradient.lower} 99%);
        box-shadow: inset 0 0.25rem 0 0 ${commonButton.boxShadow.first},
        inset 0 -0.25rem 0 0 ${commonButton.boxShadow.second};
    `};
`;

export const CommonButtonText = styled.div`
    font-family: Signika-Bold;
    font-size: 3.081rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme: { commonButton } }) => commonButton.color};
`;

export const CommonButton: FC<CommonButtonProps> = ({
    children,
    handleClick,
    ...props
}) => {
    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <CommonButtonContainer onClick={onClick} {...props}>
            <CommonButtonText>{children}</CommonButtonText>
        </CommonButtonContainer>
    );
};
