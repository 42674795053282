import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import Linkify from 'linkify-react';
import styled from 'styled-components';
import reactStringReplace from 'react-string-replace';

type MessageProps = {
    text: string;
    usersMap: string[];
    isChatMaster?: boolean;
    imageMap: Array<Record<string, string>>;
};

const CustomEmoji = styled.img`
    ${isMobile
        ? `
            width: 2.188rem;
            height: 2.188rem;
            margin-bottom: -0.438rem;
        `
        : `
            width: 1.625rem;
            height: 1.625rem;
            margin-bottom: -0.375rem;
        `}
`;

const Mentions = styled.span`
    color: ${({ theme }) => theme.chatMain.color.mentions};
`;

const Link = styled.div`
    a {
        text-decoration: none;
        color: #007bff;
    }
`;

export const Message: FC<MessageProps> = ({
    text,
    imageMap,
    usersMap,
    isChatMaster,
}) => {
    const replaceEmoji = (text: string, emoji: Record<string, string>): any => {
        let replaced;

        // match @-mentions
        replaced = reactStringReplace(text, /@(\w+)/g, (match) => {
            if (usersMap.includes(match)) {
                return <Mentions>@{match}</Mentions>;
            }

            return <span>@{match}</span>;
        });

        // match custom emojis
        replaced = reactStringReplace(replaced, emoji.keyCode, () => (
            <CustomEmoji
                key={emoji.keyCode}
                src={emoji.image}
                alt={emoji.keyCode}
            />
        ));

        return replaced;
    };

    const replaceEmojis = (text: string) => {
        const repArray = [text];
        imageMap.forEach((emoji) => {
            repArray.forEach((item, index) => {
                if (typeof item === 'string') {
                    const replaceItems = replaceEmoji(item, emoji);

                    if (replaceItems.length > 1) {
                        repArray.splice(index, 1);
                        for (let i = replaceItems.length - 1; i >= 0; i--) {
                            repArray.splice(index, 0, replaceItems[i]);
                        }
                    }
                }
            });
        });

        return repArray;
    };

    return isChatMaster ? (
        <Linkify as={Link} options={{ target: '_blank' }}>
            {replaceEmojis(text)}
        </Linkify>
    ) : (
        <div>{replaceEmojis(text)}</div>
    );
};
