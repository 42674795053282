import { FC } from 'react';
import styled from 'styled-components';

export type PlayersCardsTypeProps = {
    type: 'players' | 'cards';
};

export type PlayersCardsProps = PlayersCardsTypeProps & {
    text: string | number;
};

const PlayersCardsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 7.375rem;
    height: 3rem;
    margin: 0 0 0 0.625rem;
    padding: 0.313rem;
    border-radius: 0.625rem;

    ${({ theme: { playersCards } }) => `
        box-shadow: inset 0 0.25rem 0 0 ${playersCards.boxShadow};
        background-image: linear-gradient(to bottom, ${playersCards.background.gradientTop} 2%,
            ${playersCards.background.gradientBottom} 97%);
    `};
`;

const PlayersCardsIcon = styled.div<PlayersCardsTypeProps>`
    width: 3.25rem;
    height: 2.5rem;
    border: none;
    padding: 0;
    image-rendering: -webkit-optimize-contrast;
    background: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${props.theme.images[props.type]})`};
`;

export const PlayersCardsText = styled.div`
    font-family: Signika-SemiBold;
    font-size: 1.375rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 3.375rem;
    height: 1.688rem;
    color: ${(props) => props.theme.playersCards.text.color};
`;

export const PlayersCards: FC<PlayersCardsProps> = ({
    text,
    type,
    ...props
}) => {
    return (
        <PlayersCardsContainer {...props}>
            <PlayersCardsIcon type={type} />
            <PlayersCardsText>{text}</PlayersCardsText>
        </PlayersCardsContainer>
    );
};
