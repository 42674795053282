import { FC } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CommonPanel } from '../CommonPanel';

const GoodluckPanelContainer = styled(CommonPanel)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${isMobile ? '12.375rem' : 'auto'};
`;

const UpperText = styled.p`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0.188rem 0 0 0;
    font-size: 2.125rem;
    color: ${(props) => props.theme.goodluckPanel.color.upperText};
`;

const LowerText = styled(UpperText)`
    font-size: 3.75rem;
    color: ${(props) => props.theme.goodluckPanel.color.lowerText};
`;

export const GoodluckPanel: FC = () => {
    const { t } = useTranslation();

    return (
        <GoodluckPanelContainer type="secondary">
            <UpperText>{t('callboardGameStarting.gameAboutToBegin')}</UpperText>
            <LowerText>{t('callboardGameStarting.goodLuck')}</LowerText>
        </GoodluckPanelContainer>
    );
};
