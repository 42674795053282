import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { SideGamesListMobile } from '../../components/SideGamesList/mobile';
import { SideGamesListDesktop } from '../../components/SideGamesList/desktop';
import { meta } from '../../stores/backend';
import { useSideGames } from '../../hooks/use-side-games';

export type SideGamesListProps = {
    size?: number;
    onToggleChat?: (size?: number) => void;
};

export const SideGamesList: FC<SideGamesListProps> = ({
    size,
    onToggleChat,
}) => {
    const $meta = useHookstate(meta);

    const $sideGames = useSideGames();

    const Component = isMobile ? SideGamesListMobile : SideGamesListDesktop;

    if ($sideGames.length < 1) {
        return null;
    }

    return (
        <Component
            size={size}
            sideGames={$sideGames}
            showFooter={$meta.footers.sideGames.value}
            onToggleChat={onToggleChat}
        />
    );
};
