import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type CheckboxProps = Omit<HTMLAttributes<HTMLInputElement>, 'type'> & {
    checked?: boolean;
    disabled: boolean;
};

export const CheckboxContainer = styled.input`
    display: grid;
    place-content: center;
    appearance: none;
    margin: 0;
    width: 3.813rem;
    height: 3.813rem;
    border-radius: 0.625rem;
    cursor: pointer;

    ${({ disabled, theme: { checkbox } }) => `
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? 'not-allowed' : 'auto'};
        border: ${disabled ? 'none' : checkbox.border};
        background-color: ${disabled ? 'gray' : checkbox.background.container};
        box-shadow: ${
            disabled
                ? 'none'
                : `0 0.25rem 0 0 ${checkbox.boxShadow.first},
        inset 0 0.25rem 0 0 ${checkbox.boxShadow.second}`
        };
    `};

    &::before {
        content: '';
        width: 3.125rem;
        height: 2.5rem;
        -webkit-clip-path: url(#my-clip-path);
        clip-path: url(#my-clip-path);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        background-color: ${({ theme }) =>
            `${theme.checkbox.background.content}`};
    }

    &:checked::before {
        transform: scale(1);
    }
`;

export const CheckSVG = styled.svg`
    position: absolute;
    width: 0;
    height: 0;
`;

export const CheckMark = styled.span``;

export const Checkbox: FC<CheckboxProps> = (props) => {
    const onChange = useSoundHandler(props.onChange, 'click');

    return (
        <>
            <CheckSVG>
                <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                    <path d="M1,0.006 c-0.013,0.02,-0.018,0.031,-0.024,0.035 c-0.122,0.088,-0.22,0.221,-0.307,0.368 c-0.102,0.173,-0.195,0.357,-0.293,0.535 c-0.013,0.023,-0.03,0.048,-0.049,0.055 c-0.014,0.005,-0.039,-0.012,-0.049,-0.03 C0.188,0.807,0.099,0.642,0.01,0.476 c-0.007,-0.014,-0.012,-0.038,-0.009,-0.054 c0.018,-0.091,0.102,-0.131,0.15,-0.067 c0.049,0.066,0.091,0.143,0.135,0.217 c0.028,0.048,0.033,0.05,0.062,0.002 c0.107,-0.173,0.221,-0.333,0.362,-0.451 C0.783,0.063,0.86,0.019,0.944,0.001 c0.015,-0.003,0.031,0.002,0.056,0.005"></path>
                </clipPath>
            </CheckSVG>
            <CheckboxContainer
                {...props}
                onChange={onChange}
                type="checkbox"
                disabled={props.disabled}
            />
        </>
    );
};
