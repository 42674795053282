import { State } from '@hookstate/core';
import { Part } from '../components/GamePart';
import { GameState, MetaState } from '../stores/backend';
import { useFormatCurrency } from './use-format-currency';

export const useGamePart = (game: State<GameState>, meta: State<MetaState>) => {
    const formatCurrency = useFormatCurrency(game, meta);

    const index = game.current_part.value;

    const gamePart = game.game_parts[index];

    const part = (index + 1) as Part;

    return {
        matches: gamePart?.num_matches?.value || 1,
        patterns: gamePart?.pat_code?.value || [[]],
        prize: formatCurrency(
            gamePart?.prize?.value || 0,
            game.game_currency.value
        ),
        prizeString: gamePart?.prizeString?.value,
        hasJackpot: !!game.jackpot.value,
        patternName: gamePart?.desc?.value,
        allGameParts: game.game_parts?.value,
        part,
    };
};
