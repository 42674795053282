import {
    useCallback,
    useContext,
    useEffect,
    useState,
    createContext,
} from 'react';
import { Howl } from 'howler';
import manifest from '../assets/audio.packer';

export type InitializationOptions = {
    loaded: boolean;
    sound: boolean;
    language: string;
    caller: string;
    announcer: string;
};

export type BingoSounds = {
    ui?: Howl;
    announcer?: Howl;
    ball75?: Howl;
    ball80?: Howl;
    ball90?: Howl;
};

export type SoundPlayer = {
    play: (key: keyof BingoSounds, id: string) => Promise<void>;
    mute: (isMuted: boolean) => void;
    initialized: boolean;
};

const createHowler = async (key: string) => {
    const audioManifest = manifest[key];

    if (!audioManifest) {
        return;
    }

    const response = await fetch(audioManifest.spriteFile);

    const sprite: Record<string, [number, number]> = await response.json();

    return new Howl({
        src: audioManifest.audioFiles,
        sprite: sprite,
        mute: true,
    });
};

export const SoundContext = createContext<SoundPlayer | null>(null);

export const useSounds = () => useContext(SoundContext) as SoundPlayer;

export const DEFAULT_SOUND_PLAYER: SoundPlayer = {
    play: () => Promise.resolve(),
    mute: () => {},
    initialized: false,
};

export const useSoundInitialization = ({
    loaded,
    sound,
    language,
    caller,
    announcer,
}: InitializationOptions) => {
    const [sounds, setSounds] = useState<SoundPlayer>(DEFAULT_SOUND_PLAYER);

    useEffect(() => {
        if (!loaded) {
            return;
        }

        let unmounted = false;

        async function initializeSounds() {
            const [sUI, sAnnouncer, sBall75, sBall80, sBall90] =
                await Promise.all([
                    createHowler('sounds/ui'),
                    createHowler(`speech/${language}/${announcer}/announcer`),
                    createHowler(`speech/${language}/${caller}/75`),
                    createHowler(`speech/${language}/${caller}/80`),
                    createHowler(`speech/${language}/${caller}/90`),
                ]);

            const howlers: BingoSounds = {
                ui: sUI,
                announcer: sAnnouncer,
                ball75: sBall75,
                ball80: sBall80,
                ball90: sBall90,
            };

            if (unmounted) {
                return;
            }

            setSounds({
                initialized: true,
                play(key, id) {
                    return new Promise<void>((resolve) => {
                        const howler = howlers[key];
                        if (!howler) {
                            resolve();
                            return;
                        }

                        const soundId = howler.play(id);
                        howler.on('end', function onEnd($soundId) {
                            if (soundId === $soundId) {
                                resolve();
                                howler.off('end', onEnd);
                            }
                        });
                    });
                },
                mute(isMuted) {
                    for (const key in howlers) {
                        const howler = howlers[key as keyof BingoSounds];
                        if (howler) {
                            howler.mute(isMuted);
                        }
                    }
                },
            });
        }

        initializeSounds();

        return () => {
            unmounted = true;
        };
    }, [loaded, language, caller, announcer]);

    if (sounds.initialized) {
        sounds.mute(!sound);
    }

    return sounds;
};

export const useSoundHandler = <A, R>(
    callback: ((...args: A[]) => R) | undefined,
    id: string,
    key: keyof BingoSounds = 'ui'
) => {
    const sounds = useSounds();

    return useCallback(
        (...args: A[]) => {
            sounds.play(key, id);
            if (callback) {
                return callback(...args);
            }
        },
        [callback, sounds, key, id]
    );
};
