import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export type GameIdProps = {
    gameId: number;
};

const GameIdContainer = styled.div`
    height: 1.5rem;
    margin-top: 0.313rem;
    font-family: Signika-SemiBold;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;

    ${({ theme: { gameId } }) => `
        color: ${gameId.color};
        text-shadow: 0 0.125rem 0.188rem ${gameId.textShadow};
    `};
`;

export const GameId: FC<GameIdProps> = ({ gameId, ...props }) => {
    const { t } = useTranslation();

    return (
        <GameIdContainer {...props}>
            {t('nav.gameId', { gameId })}
        </GameIdContainer>
    );
};
