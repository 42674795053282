import { FC, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import { useCalled } from '../../hooks/use-called';

export type CardNumberProps = {
    isCalled: boolean;
    autoDaub: boolean;
    bounce?: boolean;
    dauberColor?: string;
    dauberStyle?: string;
};

export const CardNumber75Container = styled.div<CardNumberProps>`
    width: 3.75rem;
    height: 3rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    caret-color: transparent;

    ${({
        isCalled,
        autoDaub,
        dauberColor,
        dauberStyle,
        theme: { cardNumber75 },
    }) => `
        background: ${cardNumber75.background};
        cursor: ${autoDaub ? 'auto' : 'pointer'};
    

        ${
            isCalled
                ? dauberColor && !dauberStyle
                    ? `background-color: ${dauberColor};`
                    : dauberStyle
                    ? ''
                    : `
                border: solid 0.094rem ${cardNumber75.border};
                box-shadow: inset 0 0.25rem 0 0 ${cardNumber75.boxShadow.upper}, inset 0 -0.25rem 0 0 ${cardNumber75.boxShadow.lower};
                background-image: linear-gradient(to bottom, ${cardNumber75.gradient.top},  ${cardNumber75.gradient.bottom} 99%);
            `
                : ''
        };
    `};
`;

export const CardNumber75Text = styled.div<{ isCalled: boolean }>`
    font-family: Signika-Bold;
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    ${({ isCalled, theme }) => `
        color: ${
            isCalled
                ? theme.cardNumber75.color.isCalled
                : theme.cardNumber75.color.normal
        };
    `};
`;

export const CardNumber75Dauber = styled.svg<{ fill: string }>`
    width: 2rem !important;
    ${({ fill }) => fill && `fill: ${fill}`};
`;

export const CardNumber75: FC<PropsWithChildren<CardNumberProps>> = ({
    dauberColor,
    dauberStyle,
    bounce,
    children,
    ...props
}) => {
    const theme = useTheme();
    const { daubed, handleClick } = useCalled(
        props.autoDaub,
        props.isCalled,
        children
    );

    const { dauberColor: dauberColorObject } = theme;
    const { dauberStyle: dauberStyleObject } = theme.images;

    return (
        <CardNumber75Container
            {...props}
            className={bounce ? 'bounce' : ''}
            isCalled={daubed}
            dauberStyle={dauberStyle}
            dauberColor={dauberColorObject[dauberColor!]}
            onClick={handleClick}
        >
            {daubed && dauberStyle ? (
                <CardNumber75Dauber
                    as={dauberStyleObject[dauberStyle!] as any}
                    fill={dauberColorObject[dauberColor!]}
                />
            ) : (
                <CardNumber75Text isCalled={daubed}>
                    {children}
                </CardNumber75Text>
            )}
        </CardNumber75Container>
    );
};
