import { State } from '@hookstate/core';
import { GameState } from '../stores/backend';
import { useBogo } from './use-bogo';
import { useBoughtTickets } from './use-bought-tickets';
import { useStripSize } from './use-strip-size';

export const useFreeTickets = (game: State<GameState>, buying: number) => {
    const stripOnly = game.game_strip_only.value;
    const stripSize = useStripSize(game);
    const ratio = stripOnly ? stripSize : 1;

    const freeCards = game.game_free_cards.value || 0;
    const playerCards = useBoughtTickets(game);
    const { purchaseBuy, purchaseGet } = useBogo(game);

    const rFreeCards = freeCards / ratio;
    const rPlayerCards = playerCards / ratio;
    const rBuying = buying / ratio;

    return (
        Math.floor(
            (((rPlayerCards - rFreeCards) % purchaseBuy) + rBuying) /
                purchaseBuy
        ) * purchaseGet
    );
};
