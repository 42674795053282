import { FC, HTMLAttributes, memo } from 'react';
import styled from 'styled-components';
import { BallHole } from '../BallHole';
import { BallTray } from '../BallTray';
import { Ball75 } from '../Ball75';
import { Ball80 } from '../Ball80';
import { Ball90 } from '../Ball90';
import { GameTypeNumbers } from '../GameType';

type BallCallsProps = HTMLAttributes<HTMLDivElement> & {
    gameType: GameTypeNumbers;
    calledBalls: number[];
};

const BallCallsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    min-width: 32.25rem;
    height: 10rem;
`;

const CalledBallsWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 7.75rem;
`;

const BallTrayItem = styled.div<{ x: number }>`
    user-select: none;
    position: absolute;
    transition: 0.5s ease-in-out left, 0.5s ease-in-out transform;
    transform: ${(props) => `rotate(${props.x * 360}deg)`};
    left: ${(props) => props.x * 6.625 + 'rem'};
`;

const BallHoleItem = styled.div<{ y: number }>`
    user-select: none;
    position: absolute;
    transition: 0.5s ease-in-out bottom;
    bottom: ${(props) => props.y * 9 + 'rem'};
`;

const BALLS = Array.from({ length: 90 }).map((_v, i) => i + 1);
const BALL_COMPONENTS = {
    75: Ball75,
    80: Ball80,
    90: Ball90,
};

export const BallCalls: FC<BallCallsProps> = memo(
    ({ gameType, calledBalls, ...props }) => {
        // slice first before reverse to make a new copy of the array
        const $reversed = calledBalls.slice().reverse();
        const $calledBalls = $reversed.slice(1);
        const $currentBall = $reversed.slice(0, 1);
        const Ball = BALL_COMPONENTS[gameType];

        return (
            <BallCallsContainer {...props}>
                <BallTray>
                    <CalledBallsWrapper>
                        {BALLS.map((ball) => {
                            return (
                                <BallTrayItem
                                    as={Ball}
                                    size="small"
                                    key={ball}
                                    number={ball}
                                    x={$calledBalls.indexOf(ball)}
                                />
                            );
                        })}
                    </CalledBallsWrapper>
                </BallTray>
                <BallHole>
                    {BALLS.map((ball) => {
                        return (
                            <BallHoleItem
                                as={Ball}
                                size="big"
                                key={ball}
                                number={ball}
                                y={$currentBall.indexOf(ball)}
                            />
                        );
                    })}
                </BallHole>
            </BallCallsContainer>
        );
    }
);
