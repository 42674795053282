import { FC, HTMLAttributes } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { Notification } from '../Notification';

type MessageBubbleProps = HTMLAttributes<HTMLImageElement> & {
    mentions: number;
    handleClick: () => void;
};

const buttonDimension = css`
    width: 4.375rem;
    height: 4.375rem;
    cursor: pointer;
`;

const MessageBubbleContainer = styled.div`
    ${buttonDimension};
`;

const Wrapper = styled.div`
    position: relative;
`;

export const MessageBubbleImage = styled.img`
    ${buttonDimension};
`;

export const MessageBubble: FC<MessageBubbleProps> = ({
    mentions,
    handleClick,
    ...props
}) => {
    const theme = useTheme();
    const { messageBubble } = theme.images;

    return (
        <MessageBubbleContainer {...props}>
            <Wrapper>
                <MessageBubbleImage
                    src={messageBubble}
                    alt="Message Bubble Image"
                    onClick={handleClick}
                />
                {mentions > 0 && <Notification value={mentions} />}
            </Wrapper>
        </MessageBubbleContainer>
    );
};
