import { FC, memo, HTMLAttributes, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { CommonCard } from '../CommonCard';
import { CardNumber75 } from '../CardNumber75';
import { CardId } from '../CardId';
import { CardToGo } from '../CardTogo';
import { SelectedText } from '../SelectedText';
import { GameTypeNumbers } from '../GameType';
import { useCalculateTogo } from '../../hooks/use-calculate-togo';
import { useSetGlowEffectsRx } from '../../hooks/use-set-glow-effects-rx';

const ARRAY = [...Array(5)];
const MIDDLE = 12;

export type BingoCard75Props = HTMLAttributes<HTMLDivElement> & {
    gameType: GameTypeNumbers;
    tiles: number[];
    cardId: string;
    autoDaub: boolean;
    hasFreeSpace: boolean;
    dauberColor?: string;
    dauberStyle?: string;
    patterns?: number[][];
    matches?: number;
    called?: number[];
    currentPart?: number;
    pickCards?: boolean;
    picked?: boolean;
    bought?: boolean;
    cardIndex?: number;
    stripIndex?: number;
    isStripped?: boolean;
    handleClick?: (index: number, stripIndex?: number) => void;
};

export const BingoCard75Container = styled(CommonCard)<{
    picked: boolean;
    bought: boolean;
    pickCards: boolean;
}>`
    ${({ theme: { pickedCards }, picked, bought, pickCards }) => `
        position: relative;
        ${pickCards ? `cursor: pointer;` : ''}
        ${
            bought
                ? `
                box-shadow: 0 0.25rem 0 0 ${pickedCards.boxShadow.bought.first}, inset 0 0.125rem 0 0 ${pickedCards.boxShadow.bought.second};
                background-color: ${pickedCards.background.bought};
            `
                : picked
                ? `
                box-shadow: 0 0.25rem 0 0 ${pickedCards.boxShadow.picked.first}, inset 0 0.125rem 0 0 ${pickedCards.boxShadow.picked.second};
                background-color: ${pickedCards.background.picked};
                `
                : ''
        }
    `};
`;

const CardNumberContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
`;

export const CardTextContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    margin-bottom: 0.8rem;
`;

export const CardTextOverlayContainer = styled(CardTextContainer)`
    position: absolute;

    left: 0.5rem;
    right: 0.5rem;
`;

export const CardText = styled.div`
    font-family: Mitr;
    font-size: 2.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    text-align: center;

    ${({ theme: { cardText } }) => `
        color: ${cardText.color};
        text-shadow: 0 0.125rem ${cardText.textShadow}, 0 0.063rem ${cardText.color};
    `};
`;

export const CardFooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin: 0.25rem 0;
`;

export const CardRow = styled.div`
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    justify-items: center;
    grid-row-gap: 0.25rem;
`;

export const CardNumber75Middle = styled.img`
    width: 3.125rem;
    height: 3.125rem;
`;

export const BingoCard75: FC<BingoCard75Props> = memo(
    ({
        gameType,
        tiles,
        cardId,
        autoDaub,
        hasFreeSpace,
        cardIndex,
        stripIndex,
        isStripped,
        dauberColor,
        dauberStyle,
        matches = 0,
        currentPart = 0,
        called = [],
        patterns = [],
        picked = false,
        bought = false,
        pickCards = false,
        handleClick,
        ...props
    }) => {
        const theme = useTheme();
        const { starImage, starGlow } = theme.images;
        const setGlowEffectRx = useSetGlowEffectsRx();
        const { cardType, lastNumber } = useCalculateTogo(
            gameType,
            tiles,
            patterns,
            matches,
            called,
            currentPart,
            hasFreeSpace
        );

        useEffect(() => setGlowEffectRx(), [cardType, setGlowEffectRx]);

        return (
            <BingoCard75Container
                cardType={pickCards ? 'default' : cardType}
                picked={picked}
                bought={bought}
                pickCards={pickCards}
                onClick={
                    pickCards && typeof cardIndex === 'number' && handleClick
                        ? () => handleClick(cardIndex, stripIndex)
                        : undefined
                }
                {...props}
                className={`${props.className} glow-effects`}
            >
                {cardType === 'oneTG' && (
                    <svg className="glow-container">
                        <filter id="blur">
                            <feGaussianBlur
                                stdDeviation="6"
                                edgeMode="duplicate"
                            />
                        </filter>
                        <rect
                            className="glow-blur"
                            strokeLinecap="round"
                            filter='url("#blur")'
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-line"
                            strokeLinecap="round"
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-blur-2"
                            strokeLinecap="round"
                            filter='url("#blur")'
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-line-2"
                            strokeLinecap="round"
                            pathLength={1000}
                        ></rect>
                    </svg>
                )}
                <CardTextContainer>
                    <CardText className={`${cardType}-BINGO-glow`}>B</CardText>
                    <CardText className={`${cardType}-BINGO-glow`}>I</CardText>
                    <CardText className={`${cardType}-BINGO-glow`}>N</CardText>
                    <CardText className={`${cardType}-BINGO-glow`}>G</CardText>
                    <CardText className={`${cardType}-BINGO-glow`}>O</CardText>
                </CardTextContainer>
                {/* Overlay for BINGO sequencial animation */}
                {cardType === 'oneTG' && (
                    <CardTextOverlayContainer>
                        <CardText className={`${cardType}-B-glow`}>B</CardText>
                        <CardText className={`${cardType}-I-glow`}>I</CardText>
                        <CardText className={`${cardType}-N-glow`}>N</CardText>
                        <CardText className={`${cardType}-G-glow`}>G</CardText>
                        <CardText className={`${cardType}-O-glow`}>O</CardText>
                    </CardTextOverlayContainer>
                )}
                <CardNumberContainer>
                    {ARRAY.map((_vRow, row) => (
                        <CardRow key={row}>
                            {ARRAY.map((_vColumn, column) => {
                                const index = row * ARRAY.length + column;
                                return hasFreeSpace &&
                                    row * 5 + column === MIDDLE ? (
                                    <CardNumber75Middle
                                        src={
                                            cardType === 'oneTG'
                                                ? starGlow
                                                : starImage
                                        }
                                        key={column}
                                    />
                                ) : (
                                    <CardNumber75
                                        key={column}
                                        autoDaub={autoDaub}
                                        dauberColor={dauberColor}
                                        dauberStyle={dauberStyle}
                                        isCalled={called.includes(tiles[index])}
                                        bounce={lastNumber.includes(
                                            tiles[index]
                                        )}
                                    >
                                        {tiles[index]}
                                    </CardNumber75>
                                );
                            })}
                        </CardRow>
                    ))}
                </CardNumberContainer>
                <CardFooterContainer>
                    {pickCards && (picked || bought) ? (
                        <SelectedText type={bought ? 'bought' : 'picked'} />
                    ) : (
                        <CardId isStripped={isStripped}>{cardId}</CardId>
                    )}
                    <CardToGo cardType={pickCards ? 'default' : cardType} />
                </CardFooterContainer>
            </BingoCard75Container>
        );
    }
);
