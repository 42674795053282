import { State } from '@hookstate/core';
import { GameState, MetaState } from '../stores/backend';

import { useFormatCurrency } from './use-format-currency';

export const useJackpot = (game: State<GameState>, meta: State<MetaState>) => {
    const {
        value = 0,
        name = '',
        call = 0,
        currency,
    } = game.jackpot.value || {};
    const formatCurrency = useFormatCurrency(game, meta);

    return {
        hasJackpot: !!game.jackpot.value,
        jackpotPrize: formatCurrency(value, currency),
        jackpotCall: call,
        jackpotName: name,
    };
};
