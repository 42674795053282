import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { createTiles } from '@parlaygames/bingo-utility';
import { CommonPanel } from '../CommonPanel';
import { CallBoardNumber } from '../CallBoardNumber';
import { CallBoard75Props as CallBoard80Props } from '../CallBoard75';

type SquareContainerProps = {
    background: string;
};

export const CallBoard80Container = styled(CommonPanel)`
    box-shadow: none;
    background-image: none;
    overflow: hidden;
    padding: 0;
    caret-color: transparent;
`;

const CallBoard80Number = styled(CallBoardNumber)`
    line-height: 1.91;
    margin: 0;
    padding: 0;
    font-size: 1.375rem;
    height: 2.781rem;
    text-align: center;
    color: ${(props) => props.theme.callBoard.board80.color};
`;

export const SquareContainer = styled.div<SquareContainerProps>`
    display: flex;
    justify-content: space-evenly;
    padding: 0 0.313rem 0 0.313rem;
    background: ${(props) => props.background};
`;

export const TILES = createTiles(1, 80, 20, false);

export const CallBoard80: FC<CallBoard80Props> = ({ called, ...props }) => {
    const theme = useTheme();
    const { background } = theme.callBoard.board80;
    return (
        <CallBoard80Container type="secondary" {...props}>
            {TILES.map((tiles, index) => {
                return (
                    <SquareContainer key={index} background={background[index]}>
                        {tiles.map((tile) => (
                            <CallBoard80Number
                                number={tile}
                                isCalled={called.includes(tile)}
                                key={tile}
                            >
                                {tile}
                            </CallBoard80Number>
                        ))}
                    </SquareContainer>
                );
            })}
        </CallBoard80Container>
    );
};
