import { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Notification } from '../Notification';
import { BuyButtonProps, WithTotalProps } from '.';

export const commonStyle = css`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    color: ${({ theme }) => theme.buyButton.color.default};
`;

export const BuyButtonContainer = styled.button<
    WithTotalProps & { disabled: boolean }
>`
    padding: 0;
    border-radius: 1.125rem;
    height: 8.5rem;
    opacity: 1;

    ${({
        withTotal,
        disabled: buttonDisabled,
        theme: {
            buyButton: { enabled, disabled },
        },
    }) => {
        return css`
            cursor: ${buttonDisabled ? 'not-allowed' : 'pointer'};
            width: ${withTotal ? '26rem' : '14rem'};
            border: solid 0.113rem
                ${buttonDisabled ? disabled.border : enabled.border};
            background-image: linear-gradient(
                to bottom,
                ${buttonDisabled
                        ? disabled.backgroundGradient.upper
                        : enabled.backgroundGradient.upper}
                    1%,
                ${buttonDisabled
                        ? disabled.backgroundGradient.lower
                        : enabled.backgroundGradient.lower}
                    99%
            );
            box-shadow: ${withTotal
                        ? `0 0.375rem 0 0 ${enabled.boxShadow.first},`
                        : ''}
                    inset 0 0.313rem 0 0
                    ${buttonDisabled
                        ? disabled.boxShadow.second
                        : enabled.boxShadow.second},
                inset 0 -0.313rem 0 0 ${buttonDisabled ? disabled.boxShadow.third : enabled.boxShadow.third};
        `;
    }};
`;

export const FlexColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 7rem;
`;

export const FlexWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageTextWrapper = styled.div<WithTotalProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ withTotal }) =>
        withTotal
            ? `
            height: 3.75rem;
        `
            : `
            height: auto;
        `};
`;

export const ImageWrapper = styled.div`
    position: relative;
`;

export const CartImage = styled.img<WithTotalProps>`
    ${({ withTotal }) => `
        width: ${withTotal ? '2.875rem' : '3.25rem'};
        height ${withTotal ? '3.438rem' : '4rem'};
    `};
`;

export const BuyButtonText = styled.div<
    WithTotalProps & { size: number; sizeWithTotal: number }
>`
    ${commonStyle};

    ${({ withTotal, size, sizeWithTotal }) => `
        font-size: ${withTotal ? `${sizeWithTotal}rem` : `${size}rem`};
        margin-top: ${withTotal ? '0.438rem' : ''};
    `};
`;

const NextGameText = styled.div`
    ${commonStyle};

    font-size: 1.75rem;
    margin-top: -0.75rem;
`;

export const LabelValueWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Signika-Bold;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
`;

export const TotalLabel = styled.div`
    margin-right: 0.5rem;

    color: ${({
        theme: {
            buyButton: { color },
        },
    }) => color.default};
`;

export const TotalValue = styled.div`
    color: ${({
        theme: {
            buyButton: { color },
        },
    }) => color.totalValue};
`;

export const BuyButtonDesktop: FC<BuyButtonProps> = ({
    withTotal,
    total,
    tickets,
    isDisabled,
    handleClick,
    ...props
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { cart } = theme.images;

    return (
        <BuyButtonContainer
            {...props}
            withTotal={withTotal}
            onClick={handleClick}
            disabled={isDisabled}
        >
            <FlexColWrapper>
                <FlexWrapper>
                    <ImageTextWrapper withTotal={withTotal}>
                        <ImageWrapper>
                            <CartImage
                                withTotal={withTotal}
                                src={cart}
                                alt="Cart Image"
                            />
                            {typeof tickets === 'number' && (
                                <Notification value={tickets} />
                            )}
                        </ImageWrapper>
                        <BuyButtonText
                            withTotal={withTotal}
                            size={t('buyBtn.buy.desktop.size')}
                            sizeWithTotal={t(
                                'buyBtn.buy.desktop.sizeWithTotal'
                            )}
                        >
                            {t('buyBtn.buy.msg')}
                        </BuyButtonText>
                    </ImageTextWrapper>
                </FlexWrapper>
                {withTotal ? (
                    <LabelValueWrapper>
                        <TotalLabel>{t('buyBtn.total')}:</TotalLabel>
                        <TotalValue>{total}</TotalValue>
                    </LabelValueWrapper>
                ) : (
                    <NextGameText>{t('infoBox.nextGame')}</NextGameText>
                )}
            </FlexColWrapper>
        </BuyButtonContainer>
    );
};
