import {
    current,
    next,
    meta,
    autoBuy as autoBuyState,
    resetAutoBuy,
    initialize,
    isFreeBuyConfirm,
    getTicketCost,
    getMaxCardsCanBuy,
    getBalance,
    openModal,
    pickedCards,
    screenConfig as screenConfigState,
} from '../../stores/backend';
import { BackendSocket } from '../../hooks/use-socket';
import { getQuery } from '../../lib/query';
import { BingoLoadGameResponse } from '../../types/bingo-events/in/backend/load-game-response';
import { InjectedStateInfo } from '../../types/bingo-events/out/backend/injected-state-info';

export type BackendPayload = ReturnType<typeof createBackendPayload>;

export const createBackendPayload = (socket: BackendSocket) => {
    const { USERID: userId, SESSIONID: sessionId } = getQuery();
    const { screenConfig } = screenConfigState.value;
    const game_id = screenConfig.room.gameId;

    const injectStateInfo = <T>(data: T): T & InjectedStateInfo => ({
        ...data,
        _stateInfo: {
            userId,
            sessionId,
            gameId: game_id,
        },
    });

    /**
     * If `param` is a number then it is treated as a `bingo:buyCards` output event,
     * otherwise, it will be treated as a `bingo:buyPickedCards` output event
     * @param param
     */
    const buyTicket = (param: number | string[]) => {
        const { game_sid: sid, game_number } = next.value;

        meta.loaders.buying.set(true);

        const [key, event] = Array.isArray(param)
            ? ['ids', 'bingo:buyPickedCards']
            : ['num_cards', 'bingo:buyCards'];

        socket.emit(
            event,
            injectStateInfo({
                game_id,
                game_number,
                [key]: param,
                sid,
            })
        );

        const callback = () => {
            meta.loaders.buying.set(false);
            pickedCards.selected.set([]);
            socket.off('bingo:buyCards', callback);
        };

        socket.on('bingo:buyCards', callback);
    };

    const autoBuy = () => {
        const {
            anyPrice,
            fixedPrice,
            games,
            maxTicketPrice,
            maxLoss,
            enableMaxLoss,
            enableUnlimited,
            enableMaxCards,
            balance,
            tickets,
        } = autoBuyState.value;

        const ticketCost = getTicketCost(next);

        const $buyTicket = () => {
            if (next.fixed_game.value && !fixedPrice) {
                return;
            }

            const ticketsToBuy =
                next.fixed_game.value || enableMaxCards
                    ? getMaxCardsCanBuy(next)
                    : Math.min(getMaxCardsCanBuy(next), tickets);

            buyTicket(ticketsToBuy);
        };

        const checkMaxLoss = () => {
            const accountBalance = getBalance();

            if (enableMaxLoss) {
                if (accountBalance >= balance - maxLoss) {
                    $buyTicket();
                } else {
                    openModal({
                        key: 'msgCommon',
                        name: 'autoBuy.maxLossThreshold',
                        autoClose: true,
                    });
                    autoBuyState.activation.set(false);
                }
            } else {
                $buyTicket();
            }
        };

        if (enableUnlimited && (anyPrice || ticketCost <= maxTicketPrice)) {
            checkMaxLoss();
            return;
        }

        if (games > 0 && (anyPrice || ticketCost <= maxTicketPrice)) {
            checkMaxLoss();
        }

        autoBuyState.games.set((games) => games - 1);

        if (games < 1) {
            openModal({
                key: 'msgCommon',
                name: 'autoBuy.gamesPlayedDone',
                autoClose: true,
            });
            resetAutoBuy();
        }
    };

    const attemptAutoBuy = () => {
        if (isFreeBuyConfirm(next)) {
            meta.modals.freeCards.set(true);
            buyTicket(next.game_cards_max.value);
        } else if (autoBuyState.activation.value) {
            autoBuy();
        }
    };

    const getAccountStatus = () => {
        const { game_sid: sid, game_number } = next.value;

        meta.loaders.accountStatus.set(true);

        socket.emit(
            'bingo:getAccountStatus',
            injectStateInfo({
                game_id,
                game_number,
                sid,
            })
        );

        const callback = () => {
            meta.loaders.accountStatus.set(false);
            socket.off('bingo:accountStatus', callback);
        };

        socket.on('bingo:accountStatus', callback);
    };

    const rejoin = () => {
        const currentGameSession = current.game_sid.value;
        const nextGameSession = next.game_sid.value;

        if (currentGameSession) {
            socket.emit('rejoin', currentGameSession);
        }

        if (nextGameSession) {
            socket.emit('rejoin', nextGameSession);
        }
    };

    const loadGame = (nextGame?: boolean) => {
        return new Promise<void>((resolve) => {
            const callback = (response: BingoLoadGameResponse) => {
                initialize(response, attemptAutoBuy);
                resolve();
                socket.off('bingo:loadGameResp', callback);
            };

            socket.on('bingo:loadGameResp', callback);

            socket.emit(
                'bingo:loadGame',
                injectStateInfo({
                    game_id,
                    next_game: nextGame ? '1' : '0',
                })
            );
        });
    };

    const getPickedCards = () => {
        meta.loaders.pickedCards.set(true);

        socket.emit(
            'bingo:getPickedCards',
            injectStateInfo({
                game_id,
                game_number: next.game_number.value,
                sid: next.game_sid.value,
                isNext: pickedCards.hasNext.value,
            })
        );

        socket.on('bingo:getPickedCards', function callback() {
            meta.loaders.pickedCards.set(false);
            socket.off('bingo:getPickedCards', callback);
        });
    };

    return {
        buyTicket,
        attemptAutoBuy,
        getAccountStatus,
        loadGame,
        rejoin,
        injectStateInfo,
        getPickedCards,
    };
};
