import { State } from '@hookstate/core';
import { useCallback } from 'react';

import { GameState, MetaState } from '../stores/backend';
import { useFormatCurrency } from './use-format-currency';
import { useIncrement } from './use-increment';
import { useCardBuy } from './use-card-buy';
import { useBoughtTickets } from './use-bought-tickets';
import { useTicketsDisplay } from './use-tickets-display';
import { useFreeTickets } from './use-free-tickets';
import { useCardCost } from './use-card-cost';

export type QuickTicketPick = {
    min: number;
    max: number;
    hasIncrementalChange: boolean;
    buying: number;
    tickets: number;
    freeTickets: number;
    total: string;
    increment: () => void;
    decrement: () => void;
    minimize: () => void;
    maximize: () => void;
};

export const useQuickTicketPick = (
    game: State<GameState>,
    meta: State<MetaState>
): QuickTicketPick => {
    const formatCurrency = useFormatCurrency(game, meta);
    const freeCards = game.game_free_cards.value || 0;
    const playerCards = useBoughtTickets(game);
    const min = useCardBuy(game);
    const change = min;
    const max = game.game_cards_max.value - playerCards + freeCards;
    const hasIncrementalChange = min !== max;

    const [buying, increment, decrement, setValue] = useIncrement(min, {
        min,
        change,
        max,
    });

    const freeTickets = useFreeTickets(game, buying);
    const tickets = useTicketsDisplay(game, buying);
    const total = useCardCost(game, buying);

    const minimize = useCallback(() => setValue(min), [setValue, min]);
    const maximize = useCallback(() => setValue(max), [setValue, max]);

    return {
        min,
        max,
        hasIncrementalChange,
        buying,
        tickets,
        freeTickets,
        total: formatCurrency(total, game.game_currency.value),
        increment,
        decrement,
        minimize,
        maximize,
    };
};
