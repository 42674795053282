import { State } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { GameState, MetaState } from '../stores/backend';
import { useStripSize } from './use-strip-size';
import { useFormatCurrency } from './use-format-currency';
import { useBogo } from './use-bogo';

export const useSpecialGame = (
    game: State<GameState>,
    meta: State<MetaState>
) => {
    const { t } = useTranslation();

    const specialGame = game.special.value;
    const stripsOnly = game.game_strip_only.value;
    const stripSize = useStripSize(game);
    const ticketCost = game.game_ticket_cost.value;
    const { purchaseBuy, purchaseGet } = useBogo(game);

    const formatCurrency = useFormatCurrency(game, meta);

    if (specialGame) {
        return t('modalPurchase.buyXgetY', {
            buy: purchaseBuy,
            get: purchaseGet,
        });
    }

    if (game.fixed_game.value) {
        const cardType = stripsOnly
            ? t('modalPurchase.cardTypeStrips')
            : t('modalPurchase.cardTypeTickets');
        const maxCards =
            game.game_cards_max.value / (stripsOnly ? stripSize : 1);
        const cardCost = stripsOnly ? game.game_strip_cost.value : ticketCost;

        return t('modalPurchase.fixedGame', {
            maxCards,
            cardType,
            cardCost: formatCurrency(cardCost, game.game_currency.value),
        });
    }

    if (stripsOnly) {
        return t('modalPurchase.stripsGame');
    }

    if (ticketCost === 0) {
        return t('modalPurchase.freeGame');
    }

    return '';
};
