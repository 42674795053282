import { BackendSocket } from '../../hooks/use-socket';
import { BingoExtraWinnerMessage } from '../../types/bingo-events/in/backend/extra-winner-message';
import { Winner } from '../../types/bingo-events/in/backend/winner-message';
import { meta, winners } from '../../stores/backend';
import { BackendPayload } from './payloads';

export const onExtraWinnerMessage = (
    socket: BackendSocket,
    payload: BackendPayload
) => {
    const callback = (data: BingoExtraWinnerMessage) => {
        payload.getAccountStatus();

        let part: number = 0;
        let extraWinners: Winner[] = [];

        extraWinners.push({
            ...data,
            winningNumber: 0,
            part: (part += 1), // use level instead of part
            currency: meta.currency.value,
        });

        winners.gameSessionId.set(data.gameSessionId);
        winners.winners.merge(extraWinners);

        meta.modals.winner.set(true);
    };

    socket.on('bingo:ExtraWinnerMessage', callback);

    return () => {
        socket.off('bingo:ExtraWinnerMessage', callback);
    };
};
