import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export type CreditsCardHeadProps = {
    type: 'CASH' | 'BONUS' | 'TOTAL';
};
export type CreditsCardProps = CreditsCardHeadProps & {
    title: string;
    body: string;
    hideCurrencyToggle?: boolean;
};

const TITLE = {
    CASH: css`
        ${({ theme: { credit } }) => `
            border: solid 0.094rem ${credit.headBorder.cash};
            box-shadow: inset 0 0.188rem 0 0 ${credit.headBoxShadow.cash};
            background-image: linear-gradient(to bottom, ${credit.headBackground.cash.gradientTop},
                ${credit.headBackground.cash.gradientBottom} 99%);
        `};
    `,
    BONUS: css`
        ${({ theme: { credit } }) => `
            border: solid 0.094rem ${credit.headBorder.bonus};
            box-shadow: inset 0 0.188rem 0 0 ${credit.headBoxShadow.bonus};
            background-image: linear-gradient(to bottom, ${credit.headBackground.bonus.gradientTop},
                ${credit.headBackground.bonus.gradientBottom} 98%);
        `};
    `,
    TOTAL: css`
        ${({ theme: { credit } }) => `
            border: solid 0.094rem ${credit.headBorder.total};
            box-shadow: inset 0 0.188rem 0 0 ${credit.headBoxShadow.total};
            background-image: linear-gradient(to bottom, ${credit.headBackground.total.gradientTop},
                ${credit.headBackground.total.gradientBottom} 98%);
        `};
    `,
};

const CreditsCardContainer = styled.div<{ hideCurrencyToggle?: boolean }>`
    font-family: Signika-SemiBold;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    overflow: hidden;
    border-radius: 0.75rem;

    ${({ hideCurrencyToggle }) => `
        width: ${isMobile ? '45%' : hideCurrencyToggle ? '32%' : '28%'};
    `}

    ${({ theme: { credit } }) => `
        color: ${credit.color};
    `};
`;

const CreditsCardHead = styled.div<CreditsCardHeadProps>`
    font-size: 1.25rem;
    padding: 0.063rem;
    height: 1.625rem;
    border-radius: 0.75rem 0.75rem 0 0;

    ${({ type }) => TITLE[type]}
`;

const CreditCardBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    padding: 0.313rem;
    height: 2.75rem;
    background-image: ${({
        theme: { credit },
    }) => `linear-gradient(to bottom, ${credit.bodyBackground.gradientTop} 29%,
        ${credit.bodyBackground.gradientBottom} 107%)`};
`;

export const CreditsCard: FC<CreditsCardProps> = ({
    type,
    title,
    body,
    ...props
}) => (
    <CreditsCardContainer {...props}>
        <CreditsCardHead type={type}>{title}</CreditsCardHead>
        <CreditCardBody>{body}</CreditCardBody>
    </CreditsCardContainer>
);
