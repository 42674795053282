import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { BingoLetter } from '../CallBoardText';
import { CommonBall, CommonBallContainerProps, BallSize } from '../CommonBall';

export type BallColor = 'blue' | 'red' | 'purple' | 'green' | 'yellow';

export type Ball75ColorProps = {
    color: BallColor;
};

export type Ball75Props = HTMLAttributes<HTMLDivElement> & {
    number: number;
} & CommonBallContainerProps;

const BIG_INNER_CIRCLE_SIZE = {
    big: css`
        width: 7.063rem;
        height: 7.063rem;
        border-width: 0.25rem;
    `,
    small: css`
        width: 4.875rem;
        height: 4.875rem;
        border-width: 0.188rem;
    `,
    xsmall: css`
        width: 3rem;
        height: 3rem;
        border-width: 0.125rem;
        padding: 0.188rem;
    `,
    xxsmall: css`
        width: 1.875rem;
        height: 1.875rem;
        border-width: 0.063rem;
        padding: 0.063rem;
    `,
};

const SMALL_INNER_CIRCLE_SIZE = {
    big: css`
        width: 5.938rem;
        height: 5.938rem;
    `,
    small: css`
        width: 3.875rem;
        height: 3.875rem;
    `,
    xsmall: css`
        width: 2.375rem;
        height: 2.375rem;
    `,
    xxsmall: css`
        width: 1.625rem;
        height: 1.625rem;
    `,
};

const LETTER_SIZE = {
    big: css`
        font-size: 1.875rem;
    `,
    small: css`
        font-size: 1.313rem;
    `,
    xsmall: css`
        font-size: 0.813rem;
    `,
    xxsmall: css`
        font-size: 0.688rem;
    `,
};

const NUMBER_SIZE = {
    big: css`
        font-size: 3.625rem;
    `,
    small: css`
        font-size: 2.5rem;
    `,
    xsmall: css`
        font-size: 1.625rem;
        margin-top: -0.625rem;
    `,
    xxsmall: css`
        font-size: 1rem;
        margin-top: -0.375rem;
    `,
};

export const Ball75BigInnerCircle = styled.div`
    padding: 0.313rem;
    border-radius: 50%;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-style: solid;
    border-color: ${({ theme }) => theme.ball75.border};
`;
export const Ball75SmallInnerCircle = styled.div`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.ball75.background};
`;

export const Ball75Letter = styled.div<Ball75ColorProps>`
    color: ${({ color, theme }) => theme.ball75[color].color};
`;
export const Ball75Number = styled.div`
    margin-top: -0.938rem;
    color: ${({ theme }) => theme.ball75.color};
`;

export const Ball75Container = styled(CommonBall)<Ball75ColorProps>`
    ${({ color, size, theme }) => `
        border-color: ${theme.ball75[color].border};
        background-image: radial-gradient(
            circle at 50% 0,
            ${theme.ball75[color].backgroundGradient.first} 1%,
            ${theme.ball75[color].backgroundGradient.second} 15%,
            ${theme.ball75[color].backgroundGradient.third} 50%,
            ${theme.ball75[color].backgroundGradient.fourth} 120%
        );

        ${Ball75BigInnerCircle} {
            ${BIG_INNER_CIRCLE_SIZE[size as BallSize]}
        }
        ${Ball75SmallInnerCircle} {
            ${SMALL_INNER_CIRCLE_SIZE[size as BallSize]}
        }
        ${Ball75Letter} {
            ${LETTER_SIZE[size as BallSize]}
        }
        ${Ball75Number} {
            ${NUMBER_SIZE[size as BallSize]}
        }
    `};
`;

export const LETTER_COLORS = {
    B: 'blue',
    I: 'red',
    N: 'purple',
    G: 'green',
    O: 'yellow',
};

export const Ball75: FC<Ball75Props> = ({ number, ...props }) => {
    const BINGO = 'BINGO';

    /**
        Default letter is needed because BallCalls Component will pass a number more than 75.
        It will cause an undefined value for letter.
    */
    const letter = (BINGO[Math.floor((number - 1) / 15)] as BingoLetter) || 'B';
    const color = LETTER_COLORS[letter] as BallColor;

    return (
        <Ball75Container {...props} color={color}>
            <Ball75BigInnerCircle>
                <Ball75SmallInnerCircle>
                    <Ball75Letter color={color}>{letter}</Ball75Letter>
                    <Ball75Number>{number}</Ball75Number>
                </Ball75SmallInnerCircle>
            </Ball75BigInnerCircle>
        </Ball75Container>
    );
};
