import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAutobuyOptions } from '../../hooks/use-autobuy-options';
import { Checkbox } from '../Checkbox';
import { CommonButton } from '../CommonButton';
import { CommonModal, CommonModalContainer } from '../CommonModal';
import { ModalLabel } from '../ModalLabel';
import { PlusMinusInput } from '../PlusMinusInput';
import {
    AutobuyModalProps,
    ModalTitleWrapper,
    ActivationToggle,
    AutobuyMessageCommon,
    ActivationContainer,
} from './mobile';

export const AutobuyModalContainer = styled(CommonModalContainer)`
    width: 64.5rem;
    margin-top: 4rem;
    height: auto;
    left: 16.875rem;
    padding: 2.75rem 3.25rem;
`;

export const FlexRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const FlexColContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
`;

export const FlexItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
`;

export const LabelFlexItem = styled(FlexItem)`
    flex-basis: 16.3125rem;
`;

export const ValueFlexItem = styled(FlexItem)`
    flex-basis: 13.75rem;
    justify-content: space-between;
`;

export const ActivationLabel = styled(ModalLabel)`
    font-size: 1.875rem;
    margin-bottom: 0.5rem;
`;

export const ToggleLable = styled(ModalLabel)`
    font-size: 1.875rem;
    margin: 0 1rem 1rem 0;
    width: 9rem;
    text-align: right;
`;

export const UnlimitedToggle = styled(ActivationToggle)`
    margin-bottom: 1.25rem;
`;

export const MaxCardsToggle = styled(ActivationToggle)`
    margin-bottom: 1.25rem;
`;

export const ButtonContainer = styled(FlexRowContainer)`
    justify-content: center;
`;

export const OKButton = styled(CommonButton)`
    margin: 0.75rem 0 1rem;
`;

export const AutobuyMessage1 = styled(AutobuyMessageCommon)`
    font-size: 1.313rem;
    line-height: normal;
    width: 9rem;
`;

export const AutobuyMessage2 = styled(AutobuyMessageCommon)`
    font-size: 1.313rem;
    line-height: normal;
    margin-bottom: 0.938rem;
`;

export const AutobuyMessage3 = styled(AutobuyMessageCommon)`
    font-size: 1.125rem;
`;

export const AutobuyModalDesktop: FC<AutobuyModalProps> = ({
    enableMaxLoss,
    enableUnlimited,
    enableMaxCards,
    onActivate,
    formatCurrency,
    ...props
}) => {
    const { t } = useTranslation();
    const {
        INC_TICKETS,
        INC_MAX_TICKET_PRICE,
        INC_MAX_LOSS,
        INC_GAMES,
        games,
        tickets,
        fixedPrice,
        anyPrice,
        maxTicketPrice,
        maxLoss,
        unlimited,
        maxCards,
        activation,
        incGames,
        decGames,
        incTickets,
        decTickets,
        incMaxTicketPrice,
        decMaxTicketPrice,
        incMaxLoss,
        decMaxLoss,
        handleCheckAnyPrice,
        handleCheckFixedPrice,
        handleCheckUnlimited,
        handleCheckMaxCards,
        handleCheckActivation,
    } = useAutobuyOptions(
        {
            maxLossLimit: props.maxLossLimit,
            gamesLimit: props.gamesLimit,
            games: props.games,
            tickets: props.tickets,
            maxTicketPrice: props.maxTicketPrice,
            maxLoss: props.maxLoss,
            fixedPrice: props.fixedPrice,
            anyPrice: props.anyPrice,
            activation: props.activation,
        },
        enableMaxLoss,
        enableUnlimited,
        enableMaxCards,
        onActivate
    );

    return (
        <CommonModal {...props} Container={AutobuyModalContainer}>
            <ActivationContainer activation={activation}>
                <ModalTitleWrapper title={t('modalAutoBuy.header')} />
                <FlexRowContainer style={{ alignItems: 'flex-start' }}>
                    <FlexColContainer style={{ width: '63%' }}>
                        <FlexRowContainer>
                            <LabelFlexItem>
                                <ModalLabel
                                    label={t('modalAutoBuy.lblGames')}
                                />
                            </LabelFlexItem>
                            <ValueFlexItem>
                                <PlusMinusInput
                                    min={INC_GAMES.min}
                                    max={INC_GAMES.max}
                                    initialValue={games}
                                    disabled={activation || unlimited}
                                    handleIncrement={
                                        activation ? undefined : incGames
                                    }
                                    handleDecrement={
                                        activation ? undefined : decGames
                                    }
                                />
                            </ValueFlexItem>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <LabelFlexItem>
                                <ModalLabel
                                    label={t('modalAutoBuy.lblTickets')}
                                />
                            </LabelFlexItem>
                            <ValueFlexItem>
                                <PlusMinusInput
                                    min={INC_TICKETS.min}
                                    max={INC_TICKETS.max}
                                    initialValue={tickets}
                                    disabled={activation || maxCards}
                                    handleIncrement={
                                        activation ? undefined : incTickets
                                    }
                                    handleDecrement={
                                        activation ? undefined : decTickets
                                    }
                                />
                            </ValueFlexItem>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <LabelFlexItem>
                                <ModalLabel
                                    label={t('modalAutoBuy.lblFixedPrice')}
                                />
                            </LabelFlexItem>
                            <ValueFlexItem>
                                <Checkbox
                                    checked={fixedPrice}
                                    disabled={activation}
                                    onChange={
                                        activation
                                            ? undefined
                                            : handleCheckFixedPrice
                                    }
                                />
                                <AutobuyMessage1>
                                    {t('modalAutoBuy.infoFixedPrice')}
                                </AutobuyMessage1>
                            </ValueFlexItem>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <LabelFlexItem>
                                <ModalLabel
                                    label={t('modalAutoBuy.lblMaxPriceAny')}
                                />
                            </LabelFlexItem>
                            <ValueFlexItem>
                                <Checkbox
                                    checked={anyPrice}
                                    disabled={activation}
                                    onChange={
                                        activation
                                            ? undefined
                                            : handleCheckAnyPrice
                                    }
                                />
                            </ValueFlexItem>
                        </FlexRowContainer>

                        {!anyPrice && (
                            <FlexRowContainer>
                                <LabelFlexItem>
                                    <ModalLabel
                                        label={t(
                                            'modalAutoBuy.lblMaxTickPrice'
                                        )}
                                    />
                                </LabelFlexItem>
                                <ValueFlexItem>
                                    <PlusMinusInput
                                        min={formatCurrency(
                                            INC_MAX_TICKET_PRICE.min
                                        )}
                                        max={formatCurrency(
                                            INC_MAX_TICKET_PRICE.max
                                        )}
                                        initialValue={formatCurrency(
                                            maxTicketPrice
                                        )}
                                        disabled={activation}
                                        handleIncrement={
                                            activation
                                                ? undefined
                                                : incMaxTicketPrice
                                        }
                                        handleDecrement={
                                            activation
                                                ? undefined
                                                : decMaxTicketPrice
                                        }
                                    />
                                </ValueFlexItem>
                            </FlexRowContainer>
                        )}
                        {enableMaxLoss && (
                            <FlexRowContainer>
                                <LabelFlexItem>
                                    <ModalLabel
                                        label={t('modalAutoBuy.lblMaxLoss')}
                                    />
                                </LabelFlexItem>
                                <ValueFlexItem>
                                    <PlusMinusInput
                                        min={formatCurrency(INC_MAX_LOSS.min)}
                                        max={formatCurrency(INC_MAX_LOSS.max)}
                                        initialValue={formatCurrency(maxLoss)}
                                        disabled={activation}
                                        handleIncrement={
                                            activation ? undefined : incMaxLoss
                                        }
                                        handleDecrement={
                                            activation ? undefined : decMaxLoss
                                        }
                                    />
                                </ValueFlexItem>
                            </FlexRowContainer>
                        )}
                    </FlexColContainer>
                    <FlexColContainer
                        style={{
                            width: '33%',
                            alignSelf: 'flex-start',
                        }}
                    >
                        {enableUnlimited && (
                            <FlexRowContainer>
                                <UnlimitedToggle
                                    checked={unlimited}
                                    disabled={activation}
                                    onChange={handleCheckUnlimited}
                                />
                                <ToggleLable
                                    label={t('modalAutoBuy.lblUnlimited')}
                                />
                            </FlexRowContainer>
                        )}
                        {enableMaxCards && (
                            <FlexRowContainer>
                                <MaxCardsToggle
                                    checked={maxCards}
                                    disabled={activation}
                                    onChange={handleCheckMaxCards}
                                />
                                <ToggleLable
                                    label={t('modalAutoBuy.lblMaxCards')}
                                />
                            </FlexRowContainer>
                        )}
                        <ActivationLabel
                            label={t('modalAutoBuy.lblActivate')}
                        />
                        <AutobuyMessage2>
                            {t('modalAutoBuy.infoActivate')}
                        </AutobuyMessage2>
                        <ActivationToggle
                            checked={activation}
                            onChange={handleCheckActivation}
                        />
                    </FlexColContainer>
                </FlexRowContainer>
                <ButtonContainer>
                    <OKButton handleClick={props.onClose}>
                        {t('modalAutoBuy.btnOk')}
                    </OKButton>
                </ButtonContainer>
                <FlexRowContainer>
                    <AutobuyMessage3>
                        {t('modalAutoBuy.footer')}
                    </AutobuyMessage3>
                </FlexRowContainer>
            </ActivationContainer>
        </CommonModal>
    );
};
