import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { IncrementButton } from '../IncrementButton';
import { DecrementButton } from '../DecrementButton';

type PlusMinusInputProps = HTMLAttributes<HTMLDivElement> & {
    min?: number | string;
    max?: number | string;
    disabled?: boolean;
    hasChange?: boolean;
    initialValue: number | string;
    handleIncrement?: () => void;
    handleDecrement?: () => void;
};

export const PlusMinusInputContainer = styled.div<{
    disabled?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 13.75rem;
    height: 3.813rem;
    border-radius: 0.625rem;

    ${({ theme: { plusMinusInput }, disabled }) => `
        border: solid 0.063rem ${plusMinusInput.border};
        background-color: ${plusMinusInput.background};
        box-shadow:  0 0.25rem 0 0 ${plusMinusInput.boxShadow.first},
            inset 0 0.25rem 0 0 ${plusMinusInput.boxShadow.second};
        cursor: ${disabled ? 'not-allowed' : 'auto'};
        opacity: ${disabled ? 0.5 : 1};
    `};
`;

export const InitialValue = styled.div`
    font-family: Signika-Bold;
    font-size: 1.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.plusMinusInput.color};
`;

/* These components are wrapped so that the design can be
overriden by other components (e.g. BuyModal Component) */

export const IncrementButtonWrapper = styled(IncrementButton)``;

export const DecrementButtonWrapper = styled(DecrementButton)``;

export const PlusMinusInput: FC<PlusMinusInputProps> = ({
    min,
    max,
    disabled,
    hasChange = true,
    initialValue,
    handleIncrement,
    handleDecrement,
    ...props
}) => {
    const $disabled = disabled || !hasChange;

    return (
        <PlusMinusInputContainer {...props} disabled={disabled}>
            <DecrementButtonWrapper
                handleClick={handleDecrement}
                disabled={$disabled || initialValue === min}
            />

            <InitialValue>{initialValue}</InitialValue>

            <IncrementButtonWrapper
                handleClick={handleIncrement}
                disabled={$disabled || initialValue === max}
            />
        </PlusMinusInputContainer>
    );
};
