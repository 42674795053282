import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GameTypeNumbers } from '../GameType';
import { CardId } from '../CardId';
import { BingoCard75 } from '../BingoCard75';
import { BingoCard80 } from '../BingoCard80';
import { BingoCard90 } from '../BingoCard90';
import { SortedCardsResult } from '../../hooks/use-sorted-cards';

export type BingoCardListProps = {
    cards: SortedCardsResult;
    gameType: GameTypeNumbers;
    matches: number;
    currentPart: number;
    called: number[];
    patterns: number[][];
    dauberColor: string;
    dauberStyle: string;
    cardTogoTextSize?: string;
    cardWinnerTextSize?: string;
    autoDaub: boolean;
    hasFreeSpace: boolean;
};

export const BingoCardListContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-grow: 1;
    row-gap: 1.75rem;
    column-gap: 1.625rem;
    height: 0;
    overflow: auto;
    padding-bottom: 1.25rem;
`;

export const StripContainer = styled.div<{ active?: boolean }>`
    padding: 0.313rem 0.125rem 1rem;
    border-radius: 0.625rem;
    height: auto;
    opacity: ${({ active }) => (active ? 1 : 0.5)};
    pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
    background-color: ${({ theme }) => theme.cardList.background};
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 1.25rem;
`;

export const StripId = styled(CardId)`
    text-align: center;
    margin-bottom: 0.313rem;
    width: auto;
`;

export const BINGO_CARD_COMPONENT = {
    75: BingoCard75,
    80: BingoCard80,
    90: BingoCard90,
};

export const BingoCardListMobile: FC<BingoCardListProps> = ({
    cards,
    called,
    matches,
    gameType,
    patterns,
    autoDaub,
    dauberColor,
    dauberStyle,
    hasFreeSpace,
    currentPart,
    ...props
}) => {
    const { t } = useTranslation();
    const BingoCard = BINGO_CARD_COMPONENT[gameType];

    return (
        <BingoCardListContainer {...props}>
            {cards.type === 'regular' &&
                cards.list.map(({ cardId, cardNumbers }) => (
                    <BingoCard
                        key={cardId}
                        cardId={cardId}
                        tiles={cardNumbers}
                        called={called}
                        matches={matches}
                        gameType={gameType}
                        patterns={patterns}
                        currentPart={currentPart}
                        autoDaub={autoDaub}
                        dauberColor={dauberColor}
                        dauberStyle={dauberStyle}
                        hasFreeSpace={hasFreeSpace}
                    />
                ))}
            {cards.type === 'stripped' &&
                cards.list.map((item) => (
                    <StripContainer key={item.stripId} active={true}>
                        <StripId>{`${t('modalPurchase.cardTypeStrips')} ${
                            item.stripId
                        }`}</StripId>
                        <CardsContainer>
                            {item.cards.map(({ cardId, cardNumbers }) => (
                                <BingoCard
                                    key={cardId}
                                    cardId={cardId}
                                    tiles={cardNumbers}
                                    called={called}
                                    matches={matches}
                                    gameType={gameType}
                                    patterns={patterns}
                                    currentPart={currentPart}
                                    autoDaub={autoDaub}
                                    dauberColor={dauberColor}
                                    dauberStyle={dauberStyle}
                                    hasFreeSpace={hasFreeSpace}
                                    isStripped={
                                        cards.type === 'stripped' ? true : false
                                    }
                                />
                            ))}
                        </CardsContainer>
                    </StripContainer>
                ))}
        </BingoCardListContainer>
    );
};
