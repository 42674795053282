import { FC, useCallback } from 'react';
import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { meta } from '../../stores/backend';
import { BuyingInvalidModal as BuyingInvalidModalComponent } from '../../components/BuyingInvalidModal';

export const BuyingInvalidModal: FC = () => {
    const { t } = useTranslation();

    const $meta = useState(meta);

    const message =
        t($meta.modals.errorBuy.name.value) || t('error.buycards.unknown');

    const onClose = useCallback(() => {
        $meta.modals.errorBuy.isOpen.set(false);
    }, [$meta.modals.errorBuy.isOpen]);

    return (
        <BuyingInvalidModalComponent
            isOpen={$meta.modals.errorBuy.isOpen.value}
            message={message}
            onClose={onClose}
        />
    );
};
