import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CommonPanel } from '../CommonPanel';
import { GamePart, GamePartDot, Part } from '../GamePart';

export type Winners = {
    name: string;
    amount: string;
};

export type CongratulationsPanelProps = HTMLAttributes<HTMLImageElement> & {
    winners: Winners[];
    part: Part;
    gameParts: Part;
};

export const CongratulationsPanelContainer = styled(CommonPanel)`
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0;
    height: auto;
`;

export const StarBurst = styled.img`
    position: absolute;
    bottom: 0;
    height: 10.063rem;
    image-rendering: -webkit-optimize-contrast;
`;

export const StarBurstLeft = styled(StarBurst)`
    left: 0;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
`;

export const StarBurstRight = styled(StarBurst)`
    right: 0;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0.625rem 0;
`;

export const UpperText = styled.div`
    font-size: 3.313rem;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: ${({
        theme: {
            congratulationsPanel: { upperText },
        },
    }) =>
        `linear-gradient(to bottom, ${upperText.gradientTop}, ${upperText.gradientMiddle} 58%, ${upperText.gradientBottom})`};
`;

export const MiddleTexTContainer = styled.div`
    display: flex;
    justify-content: space-around;
    font-size: 2.5rem;
    width: 24.375rem;
    color: ${({ theme }) => theme.congratulationsPanel.middleText.color};
`;

export const WinnerName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
`;

export const WinnerAmount = styled(WinnerName)`
    margin-left: 0.5rem;
    text-align: left;
    width: 40%;
`;

export const LowerTextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.625rem;
    margin-top: 0.625rem;
    color: ${({ theme }) => theme.congratulationsPanel.lowerText.color};
`;

export const GamePartWrapper = styled(GamePart)`
    ${GamePartDot} {
        width: 1.188rem;
        height: 1.188rem;
    }
`;

export const PartWinnerText = styled.div`
    margin-left: 0.438rem;
`;

export const CongratulationsPanel: FC<CongratulationsPanelProps> = ({
    winners,
    part,
    gameParts,
    ...props
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { starBurst } = theme.images;

    return (
        <CongratulationsPanelContainer {...props} type="secondary">
            <StarBurstLeft src={starBurst} alt="start burst left" />
            <TextContainer>
                <UpperText>{t('callboardGame.congratulations')}!</UpperText>
                {winners.map((winner, index) => {
                    return (
                        <MiddleTexTContainer key={index}>
                            <WinnerName>{winner.name}</WinnerName>
                            <WinnerAmount>{winner.amount}</WinnerAmount>
                        </MiddleTexTContainer>
                    );
                })}
                <LowerTextContainer>
                    <GamePartWrapper gameParts={gameParts} currentPart={part} />
                    <PartWinnerText>
                        {t('callboardGame.partNumWinners', { part })}
                    </PartWinnerText>
                </LowerTextContainer>
            </TextContainer>
            <StarBurstRight src={starBurst} alt="start burst right" />
        </CongratulationsPanelContainer>
    );
};
