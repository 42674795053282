import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { BingoClassicMobile } from './mobile';
import { BingoClassicDesktop } from './desktop';
import { Loading } from '../../components/Loading';
import {
    current as currentState,
    meta as metaState,
} from '../../stores/backend';

export const BingoClassic: FC = () => {
    const current = useHookstate(currentState);
    const meta = useHookstate(metaState);

    if (!current.game_sid.value || !meta.loaded.screenConfig.value) {
        return <Loading />;
    }

    const Component = isMobile ? BingoClassicMobile : BingoClassicDesktop;

    return <Component />;
};
