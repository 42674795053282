import { FC, useMemo, useCallback } from 'react';
import { useHookstate } from '@hookstate/core';
import { useMobileOrientation } from 'react-device-detect';
import { PartWinnerPreviewSGPortrait } from '../../components/PartWinnerPreviewSG/portrait';
import { PartWinnerPreviewSGLandscape } from '../../components/PartWinnerPreviewSG/landscape';
import { Part } from '../../components/GamePart';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { useWinners } from '../../hooks/use-winners';
import { useCountdown } from '../../hooks/use-countdown';
import { useHidePartWinners } from '../../hooks/use-hide-part-winners';
import { current, meta, winners as winnersState } from '../../stores/backend';

export const PartWinnerPreviewSG: FC = () => {
    const $current = useHookstate(current);
    const $meta = useHookstate(meta);
    const $winners = useHookstate(winnersState);

    const currentPart = $current.current_part.value || 0;
    const gamePartDelay = $current.game_partdelay.value;

    const formatCurrency = useFormatCurrency($current, $meta);

    const winners = useWinners($winners, currentPart, formatCurrency);
    const { seconds } = useCountdown(new Date($meta.timer.date.value));

    const isPartWinnerPreviewVisible = useHidePartWinners(
        winners,
        gamePartDelay,
        seconds
    );

    const parts = useMemo(
        () => Object.keys($current.game_parts.value).length,
        [$current.game_parts]
    );

    const onClick = useCallback(() => {
        $meta.modals.partWinners.set(true);
    }, [$meta.modals.partWinners]);

    const orientation = useMobileOrientation();

    const Component = orientation.isPortrait
        ? PartWinnerPreviewSGPortrait
        : PartWinnerPreviewSGLandscape;

    if (
        isPartWinnerPreviewVisible &&
        currentPart + 1 < parts &&
        winners.length > 0
    ) {
        return (
            <Component
                gameParts={parts as Part}
                currentPart={(currentPart + 1) as Part}
                winner={winners[0]}
                handleClick={onClick}
            />
        );
    }

    return null;
};
