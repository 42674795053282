import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { CARD_COLORS } from '../components/CommonCard';
import { calculateTogo as calculateTG } from '../lib/togo';

export const useCalculateTogo = (
    gameType: 75 | 80 | 90,
    cardNumbers: number[],
    patterns: number[][],
    matches: number,
    called: number[],
    currentPart: number,
    hasFreeSpace: boolean
) => {
    const CARD_TYPES: Array<keyof typeof CARD_COLORS> = useMemo(
        () => ['winner', 'oneTG', 'twoTG', 'threeTG'],
        []
    );

    const calculateTogo = useCallback(
        () =>
            calculateTG(
                gameType,
                cardNumbers,
                patterns,
                matches,
                called,
                hasFreeSpace
            ),
        [gameType, cardNumbers, patterns, matches, called, hasFreeSpace]
    );

    const [togo, setTogo] = useState<{
        cardType: keyof typeof CARD_COLORS;
        lastNumber: number[];
    }>({
        cardType: CARD_TYPES[calculateTogo().togo] || 'default',
        lastNumber: [],
    });

    const ref = useRef(currentPart);

    useEffect(() => {
        // only calculate togo if current call number is in the card
        if (
            cardNumbers.indexOf(called[called.length - 1]) >= 0 ||
            ref.current !== currentPart
        ) {
            const $togo = calculateTogo();

            setTogo({
                cardType: CARD_TYPES[$togo.togo] || 'default',
                lastNumber: $togo.lastNumber,
            });

            ref.current = currentPart;
        }
    }, [called, cardNumbers, matches, currentPart, CARD_TYPES, calculateTogo]);

    return togo;
};
