import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { DauberColorModal as DauberColorModalComponent } from '../../components/DauberColorModal';
import { meta as metaState } from '../../stores/backend';

export const DauberColorModal: FC = () => {
    const meta = useHookstate(metaState);

    const onClose = () => {
        meta.modals.dauberColor.set(false);
    };

    const onSelect = (value: string) => {
        meta.dauberColor.set(value);
        localStorage.setItem('dauberColor', meta.dauberColor.value + '');
        onClose();
    };

    const onDefault = () => {
        meta.dauberColor.set('');
        localStorage.removeItem('dauberColor');
        onClose();
    };

    return (
        <DauberColorModalComponent
            isOpen={meta.modals.dauberColor.value}
            onClose={onClose}
            onSelect={onSelect}
            onDefault={onDefault}
        />
    );
};
