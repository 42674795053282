import { State } from '@hookstate/core';
import { GameState, MetaState } from '../stores/backend';
import { useGamePart } from './use-game-part';
import { useGameType } from './use-game-type';
import { calculateTogo } from '../lib/togo';

export type CardItem = {
    cardId: string;
    cardNumbers: number[];
    togo: number;
};

export const useCards = (
    game: State<GameState>,
    meta: State<MetaState>,
    calls: State<number[]>
) => {
    const playerCards = game.player_cards.value || {};
    const gameType = useGameType(game);
    const { patterns, matches } = useGamePart(game, meta);
    const called = calls.value;
    const hasFreeSpace = game.game_hasFreeSpace.value;

    return Object.keys(playerCards).map((id) => {
        const cardNumbers = (playerCards[id] || []).map(Number);
        const { togo, patternHit } = calculateTogo(
            gameType,
            cardNumbers,
            patterns,
            matches,
            called,
            hasFreeSpace
        );

        return {
            cardId: id,
            cardNumbers,
            togo,
            patternHit,
        };
    });
};
