import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { DauberStyleModal as DauberStyleModalComponent } from '../../components/DauberStyleModal';
import { meta as metaState } from '../../stores/backend';

export const DauberStyleModal: FC = () => {
    const meta = useHookstate(metaState);

    const onClose = () => {
        meta.modals.dauberStyle.set(false);
    };

    const onSelect = (value: string) => {
        meta.dauberStyle.set(value);
        localStorage.setItem('dauberStyle', meta.dauberStyle.value + '');
        onClose();
    };

    const onDefault = () => {
        meta.dauberStyle.set('');
        localStorage.removeItem('dauberStyle');
        onClose();
    };

    return (
        <DauberStyleModalComponent
            isOpen={meta.modals.dauberStyle.value}
            fill={meta.dauberColor.value}
            onClose={onClose}
            onSelect={onSelect}
            onDefault={onDefault}
        />
    );
};
