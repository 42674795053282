import { State } from '@hookstate/core';

import { GameState } from '../stores/backend';

import { useStripSize } from './use-strip-size';

export const useTicketsDisplay = (game: State<GameState>, buying: number) => {
    const stripSize = useStripSize(game);
    const ratio = game.game_strip_only.value ? stripSize : 1;
    return (game.fixed_game.value || buying) / ratio;
};
