import { FC, useCallback, RefObject } from 'react';
import { useHookstate } from '@hookstate/core';
import { JackpotModal as JackpotModalComponent } from '../../components/JackpotModal';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { meta, current, jackpotWinner } from '../../stores/backend';

type JackpotModalProps = {
    inputRef: RefObject<HTMLInputElement>;
};

export const JackpotModal: FC<JackpotModalProps> = ({ inputRef }) => {
    const $meta = useHookstate(meta);
    const $current = useHookstate(current);
    const $jackpotWinner = useHookstate(jackpotWinner);

    const formatCurrency = useFormatCurrency($current, $meta);

    const onClose = useCallback(() => {
        $meta.modals.jackpot.set(false);
    }, [$meta.modals.jackpot]);

    const onAfterOpen = () => {
        inputRef.current?.focus();
    };

    return (
        <JackpotModalComponent
            isOpen={$meta.modals.jackpot.value}
            onClose={onClose}
            onAfterOpen={onAfterOpen}
            name={$jackpotWinner.userAlias.value}
            amount={formatCurrency($jackpotWinner.amount.value)}
        />
    );
};
