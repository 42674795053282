import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type SelectedTextProps = HTMLAttributes<HTMLDivElement> & {
    type: 'picked' | 'bought';
};

export const SelectedTextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Signika-Bold;
    font-size: 1.656rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    width: 100%;
`;

export const CheckContainer = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.188rem;
    margin-right: 0.625rem;
    background-color: #fff;
`;

export const CheckSymbol = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    color: #e61c04;
`;

export const SelectedText: FC<SelectedTextProps> = ({ type, ...props }) => {
    const { t } = useTranslation();

    return (
        <SelectedTextContainer {...props}>
            <CheckContainer>
                <CheckSymbol>&#10003;</CheckSymbol>
            </CheckContainer>
            {type === 'picked'
                ? t('modalPickCards.selected')
                : t('modalPurchase.boughtCards').toUpperCase()}
        </SelectedTextContainer>
    );
};
