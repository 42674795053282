import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalTitle } from '../ModalTitle';
import { ModalLabel } from '../ModalLabel';
import { CongratulationsPanelProps } from '../CongratulationsPanel';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
} from '../CommonModal';

type PartWinnerModalSGProps = CommonModalProps &
    Omit<CongratulationsPanelProps, 'gameParts'>;

const PartWinnerModalSGContainer = styled(CommonModalContainer)`
    width: 38.75rem;
    min-height: 35.625rem;
    padding: 3.75rem 2.813rem;
`;

const ModalTitleWrapper = styled(ModalTitle)`
    text-align: center;
`;

const NamePriceContainer = styled.div`
    width: 31.25rem;
    height: auto;
    padding: 2.75rem;
    border-radius: 0.788rem;
    margin-top: 3.563rem;
    background-color: ${({ theme }) => theme.partWinnerModal.background};
`;

const NamePriceFlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const WinnerName = styled(ModalLabel)`
    font-size: 2.5rem;
    flex-basis: 65%;
`;
const WinnerPrice = styled(ModalLabel)`
    font-size: 2.5rem;
    flex-basis: 35%;
`;

export const PartWinnerModalSG: FC<PartWinnerModalSGProps> = ({
    winners,
    part,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <CommonModal {...props} Container={PartWinnerModalSGContainer}>
            <ModalTitleWrapper
                title={t('callboardGame.partNumWinners', { part })}
            />
            <NamePriceContainer>
                {winners.map((winner, index) => (
                    <NamePriceFlexWrapper key={index}>
                        <WinnerName label={winner.name} />
                        <WinnerPrice label={winner.amount} />
                    </NamePriceFlexWrapper>
                ))}
            </NamePriceContainer>
        </CommonModal>
    );
};
