import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { CommonBall, CommonBallContainerProps } from '../CommonBall';

export type Ball90Props = HTMLAttributes<HTMLDivElement> & {
    number: number;
} & CommonBallContainerProps;

const INNER_CIRCLE_SIZE = {
    big: css`
        width: 6.25rem;
        height: 6.063rem;
        margin-top: 1.25rem;
    `,
    small: css`
        width: 4.375rem;
        height: 4.188rem;
        margin-top: 0.938rem;
    `,
    xsmall: css`
        width: 2.875rem;
        height: 2.688rem;
        margin-top: 0.625rem;
    `,
    xxsmall: css`
        width: 1.875rem;
        height: 1.75rem;
        margin-top: 0.313rem;
    `,
};

const NUMBER_SIZE = {
    big: css`
        font-size: 4rem;
    `,
    small: css`
        font-size: 2.75em;
    `,
    xsmall: css`
        font-size: 1.875rem;
    `,
    xxsmall: css`
        font-size: 1.25rem;
    `,
};

export const Ball90InnerCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: ${({ theme }) => theme.ball90.background};
`;

export const Ball90Number = styled.div`
    color: ${({ theme }) => theme.ball90.color};
`;

export const Ball90Container = styled(CommonBall)`
    ${({ size, theme: { ball90 } }) => `
        border-color: ${ball90.border};
        background-image: radial-gradient(
            circle at 50% 0,
            ${ball90.backgroundGradient.first} 1%,
            ${ball90.backgroundGradient.second} 15%,
            ${ball90.backgroundGradient.third} 50%,
            ${ball90.backgroundGradient.fourth} 120%
        );

        ${Ball90InnerCircle} {
            ${INNER_CIRCLE_SIZE[size]}
        }

        ${Ball90Number} {
            ${NUMBER_SIZE[size]}
        }
    `};
`;

export const Ball90: FC<Ball90Props> = ({ number, size, ...props }) => {
    return (
        <Ball90Container {...props} size={size}>
            <Ball90InnerCircle>
                <Ball90Number>{number}</Ball90Number>
            </Ball90InnerCircle>
        </Ball90Container>
    );
};
