import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type InfoButtonProps = HTMLAttributes<HTMLButtonElement> &
    HTMLAttributes<HTMLImageElement> & {
        handleClick: () => void;
    };

const InfoButtonContainer = styled.button`
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 3.75rem;
    height: 3.75rem;
`;

const InfoButtonImage = styled.img`
    width: 100%;
    height: 100%;
    image-rendering: -webkit-optimize-contrast;
`;

export const InfoButton: FC<InfoButtonProps> = ({ handleClick, ...props }) => {
    const theme = useTheme();
    const { infoButton } = theme.images;

    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <InfoButtonContainer {...props} onClick={onClick}>
            <InfoButtonImage src={infoButton} />
        </InfoButtonContainer>
    );
};
