import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type BingoLetter = 'B' | 'I' | 'N' | 'G' | 'O';

export type TextProps = {
    letter: BingoLetter;
};

export type CallBoardTextProps = HTMLAttributes<HTMLDivElement> & TextProps;

export const CallBoardTextContainer = styled.div<TextProps>`
    display: inline-block;
    font-family: Mitr;
    font-size: 1.55rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    width: 2.25rem;
    height: 2.25rem;

    ${({ letter, theme }) => `
        color: ${theme.callBoard.color.text};
        background-color: ${theme.callBoardText.background[letter]}
    `};
`;

export const CallBoardText: FC<CallBoardTextProps> = ({ letter, ...props }) => {
    return (
        <CallBoardTextContainer letter={letter} {...props}>
            {letter}
        </CallBoardTextContainer>
    );
};
