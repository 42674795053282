import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BingoMarketingModal, {
    AppProps as BingoMarketingModalProps,
} from '@parlaygames/bingomarketingmodal';
import { CreditsCard } from '../CreditsCard';
import { GameId, GameIdProps } from '../GameId';
import { MoreButton } from '../MoreButton';
import { PrebuyButton } from '../PrebuyButton';
import { TabHeader } from '../TabHeader';

export type HeaderProps = HTMLAttributes<HTMLDivElement> &
    GameIdProps & {
        isOpen: boolean;
        isMax: boolean;
        cash: string;
        bonus: string;
        total: string;
        currency: string;
        username: string;
        hideSideGames: boolean;
        hideCurrencyToggle: boolean;
        marketingModalConfig: Omit<
            BingoMarketingModalProps,
            'isOpen' | 'onClose'
        >;
        onMarketingModalToggle: () => void;
        onSettingsOpen: () => void;
        onToggleCurrency: () => void;
        onToggleScreen: () => void;
    };

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1.25rem 0.875rem;
`;

const CreditsCardContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 80%;
`;

export const HeaderMobile: FC<HeaderProps> = ({
    isOpen,
    gameId,
    cash,
    bonus,
    hideSideGames,
    marketingModalConfig,
    onMarketingModalToggle,
    onSettingsOpen,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        <>
            {location.pathname !== '/side' && (
                <>
                    <GameId gameId={gameId} />
                    <HeaderContainer>
                        <MoreButton handleClick={onSettingsOpen} />
                        <CreditsCardContainer>
                            <CreditsCard
                                type="CASH"
                                title={t('nav.cash')}
                                body={cash}
                            />
                            <CreditsCard
                                type="BONUS"
                                title={t('nav.bonus')}
                                body={bonus}
                            />
                        </CreditsCardContainer>
                        <PrebuyButton handleClick={onMarketingModalToggle} />
                    </HeaderContainer>
                    <BingoMarketingModal
                        {...marketingModalConfig}
                        isOpen={isOpen}
                        onClose={onMarketingModalToggle}
                    />
                </>
            )}
            <TabHeader hideSideGames={hideSideGames} />
        </>
    );
};
