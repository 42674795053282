import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type CallBoardNumberContainerProps = {
    isCalled: boolean;
};

export type CallBoardNumberProps = HTMLAttributes<HTMLDivElement> & {
    number: number;
} & CallBoardNumberContainerProps;

export const CallBoardNumberContainer = styled.div<CallBoardNumberContainerProps>`
    display: inline-block;
    font-family: Signika-SemiBold;
    font-size: 1.575rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    width: 2.25rem;
    height: 2.25rem;

    ${({ isCalled, theme }) => `
        color: ${theme.callBoard.color.number};
        opacity: ${isCalled ? '1' : '0.4'};
    `};
`;

export const CallBoardNumber: FC<CallBoardNumberProps> = ({
    number,
    isCalled,
    ...props
}) => {
    return (
        <CallBoardNumberContainer isCalled={isCalled} {...props}>
            {number}
        </CallBoardNumberContainer>
    );
};
