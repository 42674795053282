import { FC } from 'react';
import { useState } from '@hookstate/core';
import { useBackgroundSounds } from '../../hooks/use-background-sounds';
import { current, calls, meta } from '../../stores/backend';

export const BackgroundSounds: FC = () => {
    const $game = useState(current);
    const $calls = useState(calls);
    const $meta = useState(meta);

    useBackgroundSounds($game, $calls, $meta);

    return <></>;
};
