import { createState } from '../../lib/create-state';
import { InGetPickedCardsData } from '../../types/bingo-events/in/backend/picked-cards';

export type PickedCardsState = InGetPickedCardsData & { selected: string[] };

export const [pickedCards, resetPickedCards] = createState<PickedCardsState>({
    bought: [],
    cards: {},
    selected: [],
});
