import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type CommonToggleProps = Omit<
    HTMLAttributes<HTMLLabelElement>,
    'onChange'
> &
    Pick<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
        checked?: boolean;
        disabled?: boolean;
    };

export const CommonToggleContainer = styled.label`
    position: relative;
    display: inline-block;
    width: 9.625rem;
    height: 4.625rem;
`;

export const CommonToggleSlider = styled.span<{ disabled: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2.981rem;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: ${({ theme }) => theme.commonToggle.background.slider};

    ${({ disabled }) => `
        opacity: ${disabled ? 0.5 : 1};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
    `}

    &:before {
        position: absolute;
        content: '';
        width: 4.188rem;
        height: 4.188rem;
        left: 0.313rem;
        bottom: 0.25rem;
        background-color: white;
        border-radius: 50%;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        box-shadow: ${({ theme }) =>
            `0 0.438rem 0.25rem 0 ${theme.commonToggle.boxShadow.slider}`};
    }
`;

export const CommonToggleInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${CommonToggleSlider} {
        background-color: ${({ theme }) => theme.commonToggle.background.input};
    }

    &:focus + ${CommonToggleSlider} {
        box-shadow: ${({ theme }) =>
            `0 0 0.063rem ${theme.commonToggle.boxShadow.input}`};
    }

    &:checked + ${CommonToggleSlider}:before {
        -webkit-transform: translateX(4.813rem);
        -ms-transform: translateX(4.813rem);
        transform: translateX(4.813rem);
    }
`;

export const CommonToggle: FC<CommonToggleProps> = ({
    checked,
    disabled = false,
    onChange,
    ...props
}) => {
    const $onChange = useSoundHandler(onChange, 'click');
    return (
        <CommonToggleContainer {...props}>
            <CommonToggleInput
                type="checkbox"
                checked={checked}
                onChange={$onChange}
                disabled={disabled}
            />
            <CommonToggleSlider disabled={disabled}></CommonToggleSlider>
        </CommonToggleContainer>
    );
};
