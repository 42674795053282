import { useState, useEffect } from 'react';
import { Winners } from '../components/CongratulationsPanel';

export const useHidePartWinners = (
    winners: Winners[],
    gamePartDelay: number,
    seconds: number
) => {
    const [isPartWinnerPreviewVisible, setPartWinnerPreviewVisible] =
        useState<boolean>(true);

    useEffect(() => {
        if (winners.length === 0 || seconds > 0) {
            return;
        }

        const id = setTimeout(() => {
            setPartWinnerPreviewVisible(false);
        }, gamePartDelay);

        return () => {
            clearTimeout(id);
        };
    }, [winners, gamePartDelay, seconds]);

    return isPartWinnerPreviewVisible;
};
