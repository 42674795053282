import { createState } from '../../lib/create-state';

import { BingoAccountStatusData } from '../../types/bingo-events/in/backend/account-status';

export type AccountState = BingoAccountStatusData;

export const DEFAULT_ACCOUNT_STATE: AccountState = {
    player_cash: 0,
    player_bonus: 0,
    wagered: 0,
};

export const [account, resetAccount] = createState(DEFAULT_ACCOUNT_STATE);

export const getBalance = () =>
    account.player_cash.value + account.player_bonus.value;
