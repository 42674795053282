import { FC } from 'react';
import styled from 'styled-components';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
    CUSTOM_STYLES,
} from '../CommonModal';

export type MessageModalProps = CommonModalProps & {
    message: string;
};

const MessageModalContainer = styled(CommonModalContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 38.75rem;
    min-height: 26.125rem;
`;

const MessageModalText = styled.div`
    font-family: Signika-Bold;
    font-size: 2.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    width: 26rem;
    color: ${({ theme }) => theme.color};
`;

export const MessageModal: FC<MessageModalProps> = ({ message, ...props }) => {
    return (
        <CommonModal
            {...props}
            style={{
                overlay: { ...CUSTOM_STYLES.overlay, zIndex: 1000 },
                content: { ...CUSTOM_STYLES.content },
            }}
            Container={MessageModalContainer}
            className="ReactModal__Content__Message--after-open-override"
        >
            <MessageModalText>{message}</MessageModalText>
        </CommonModal>
    );
};
