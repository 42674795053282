import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { LinkedGameIcon } from '../BuyModal/mobile';

export type PrimaryPanelTextItemsProps = {
    type: 'big' | 'small';
};

export type PrimaryPanelTextProps = HTMLAttributes<HTMLDivElement> &
    PrimaryPanelTextItemsProps & {
        label: string;
        value: string;
        gameLinked?: boolean;
    };

const PrimaryPanelTextContainer = styled.div`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-wrap: wrap;
`;

const PrimaryPanelTextLabel = styled.div<PrimaryPanelTextItemsProps>`
    ${({ type, theme }) => `
        font-size: ${type === 'big' ? '1.75rem' : '1.625rem'};
        color: ${theme.primaryPanel.color.label};
    `};
`;

const PrimaryPanelTextValue = styled.span<PrimaryPanelTextItemsProps>`
    ${({ type, theme }) => `
        font-size: ${type === 'big' ? '1.875rem' : '1.625rem'};
        color: ${theme.primaryPanel.color.value};
    `};
`;

export const PrimaryPanelText: FC<PrimaryPanelTextProps> = ({
    type,
    label,
    value,
    gameLinked,
    ...props
}) => {
    const theme = useTheme();
    const { link } = theme.images;

    return (
        <PrimaryPanelTextContainer {...props}>
            <PrimaryPanelTextLabel type={type}>
                {`${label}: `}
                <PrimaryPanelTextValue type={type}>
                    {value}
                    {gameLinked && type === 'big' ? (
                        <LinkedGameIcon src={link} alt="Link Icon" />
                    ) : null}
                </PrimaryPanelTextValue>
            </PrimaryPanelTextLabel>
        </PrimaryPanelTextContainer>
    );
};
