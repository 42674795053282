import { FC, useEffect, useCallback, useRef, memo, RefObject } from 'react';
import { useHookstate } from '@hookstate/core';
import { useLocation } from 'react-router-dom';
import { ChatHeader } from '../ChatHeader';
import { ChatMain } from '../../components/ChatMain';
import { ChatUsers } from '../../components/ChatUsers';
import { ChatRooms } from '../../components/ChatRooms';
import { EmojiPicker, EmojiSelectEvent } from '../../components/EmojiPicker';
import { useCurrentRoom } from '../../hooks/use-current-room';
import { useSortUsers } from '../../hooks/use-sort-users';
import { useChatPayload } from '../../hooks/use-chat';
import { chat, chatMeta } from '../../stores/chat/';
import { SIZES } from '../BingoClassic/desktop';

type ChatBoxProps = {
    size?: number;
    disabled: boolean;
    endMessageRef: RefObject<HTMLDivElement>;
    onClickOutside: () => void;
    handleClick: (alias: string) => void;
    onToggleChat?: (size?: number) => void;
    onSelect: (e: EmojiSelectEvent) => void;
};

export const ChatBox: FC<ChatBoxProps> = memo(
    ({
        size,
        disabled,
        endMessageRef,
        onSelect,
        handleClick,
        onToggleChat,
        onClickOutside,
    }) => {
        const chatSize = SIZES[size as keyof typeof SIZES];

        const messageContainerReference = useRef<HTMLDivElement>(null);

        const $chat = useHookstate(chat);
        const $chatMeta = useHookstate(chatMeta);

        const { pathname } = useLocation();

        const { chatPayload } = useChatPayload();
        const currentRoom = useCurrentRoom(
            $chat.rooms.value,
            $chat.currentRoomId.value
        );

        const users = useSortUsers($chat.players.value)
            .filter((player) => !player.cm)
            .map((player) => player.name);

        const mods = useSortUsers($chat.players.value)
            .filter((player) => player.cm)
            .map((player) => player.name);

        const rooms = $chat.rooms.value
            .filter((room) => room.id !== $chat.currentRoomId.value)
            .map((room) => {
                return {
                    id: room.id,
                    name: room.name,
                };
            });

        const onUsers = useCallback(() => {
            $chatMeta.usersToggle.set(false);
        }, [$chatMeta.usersToggle]);

        const onJoin = useCallback(
            (roomId: number) => {
                chatPayload.changeRoom(roomId);
                $chat.currentRoomId.set(roomId);
                $chat.messages.set([]);
                $chatMeta.roomsToggle.set(false);
            },
            [
                chatPayload,
                $chat.currentRoomId,
                $chat.messages,
                $chatMeta.roomsToggle,
            ]
        );

        useEffect(() => {
            if ($chatMeta.chatToggle.value) {
                const id = setTimeout(() => {
                    endMessageRef.current?.scrollIntoView({ behavior: 'auto' });
                }, 150);

                return () => {
                    clearInterval(id);
                };
            }
        }, [$chatMeta.chatToggle.value, endMessageRef]);

        useEffect(() => {
            const isVisible = function (
                end: RefObject<HTMLDivElement>,
                container: RefObject<HTMLDivElement>
            ) {
                const emdRect = end.current?.getBoundingClientRect();
                const containerRect =
                    container.current?.getBoundingClientRect();

                if (emdRect && containerRect) {
                    return emdRect.top <= containerRect.top
                        ? containerRect.top - emdRect.top <=
                              emdRect.height + 100
                        : emdRect.bottom - containerRect.bottom <=
                              emdRect.height + 100;
                }
            };

            if (isVisible(endMessageRef, messageContainerReference)) {
                endMessageRef.current?.scrollIntoView({ behavior: 'auto' });
            }
        }, [$chat.messages.length, endMessageRef]);

        if (!$chatMeta.chatToggle.value) {
            return null;
        }

        return (
            <>
                {$chatMeta.usersToggle.value && (
                    <ChatUsers
                        size={chatSize}
                        users={users}
                        mods={mods}
                        onUsers={onUsers}
                    />
                )}
                {$chatMeta.roomsToggle.value && (
                    <ChatRooms
                        size={chatSize}
                        currentRoom={currentRoom}
                        rooms={rooms}
                        onJoin={onJoin}
                    />
                )}
                {!$chatMeta.roomsToggle.value &&
                !$chatMeta.usersToggle.value ? (
                    <ChatMain
                        size={chatSize}
                        disabled={disabled}
                        users={users.concat(mods)}
                        currentUser={$chat.currentPlayer.name?.value}
                        pathname={pathname}
                        messages={$chat.messages.value}
                        endMessageRef={endMessageRef}
                        messageContainerRef={messageContainerReference}
                        handleClick={handleClick}
                    />
                ) : null}
                <EmojiPicker
                    show={$chatMeta.emojiToggle.value}
                    onSelect={onSelect}
                    onClickOutside={onClickOutside}
                />
                <ChatHeader size={chatSize} handleToggle={onToggleChat} />
            </>
        );
    }
);
