import { FC, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import { useCalled } from '../../hooks/use-called';
import {
    CardNumberProps,
    CardNumber75Dauber as CardNumber90Dauber,
} from '../CardNumber75';

export const CardNumber90Container = styled.div<CardNumberProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.375rem;
    caret-color: transparent;

    ${({
        isCalled,
        autoDaub,
        dauberColor,
        dauberStyle,
        theme: { cardNumber90 },
    }) => `
        cursor: ${autoDaub ? 'auto' : 'pointer'};

        ${
            isCalled
                ? dauberColor && !dauberStyle
                    ? `background-color: ${dauberColor};`
                    : dauberStyle
                    ? `
                    background-image: none;
                    box-shadow: none;
                    border: none; 
                    background-color: ${cardNumber90.background};
                    `
                    : `
            box-shadow: inset 0 0.25rem 0 0 ${cardNumber90.boxShadow.upper}, inset 0 -0.25rem 0 0 ${cardNumber90.boxShadow.lower};
            border: solid 0.094rem ${cardNumber90.border};
            background-image: linear-gradient(to bottom,${cardNumber90.gradient.top}, ${cardNumber90.gradient.bottom} 99%);
        `
                : `
            background-image: none;
            box-shadow: none;
            border: none; 
            background-color: ${cardNumber90.background};
        `
        };
    `};
`;

export const CardNumber90Text = styled.div<{ isCalled: boolean }>`
    font-family: Signika-Bold;
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    ${({ isCalled, theme }) => `
        color: ${
            isCalled
                ? theme.cardNumber90.color.isCalled
                : theme.cardNumber90.color.normal
        };
    `};
`;

export const CardNumber90: FC<PropsWithChildren<CardNumberProps>> = ({
    dauberColor,
    dauberStyle,
    bounce,
    children,
    ...props
}) => {
    const theme = useTheme();
    const { daubed, handleClick } = useCalled(
        props.autoDaub,
        props.isCalled,
        children
    );

    const { dauberColor: dauberColorObject } = theme;
    const { dauberStyle: dauberStyleObject } = theme.images;

    return (
        <CardNumber90Container
            {...props}
            className={bounce ? 'bounce' : ''}
            isCalled={daubed}
            dauberStyle={dauberStyle}
            dauberColor={dauberColorObject[dauberColor!]}
            onClick={handleClick}
        >
            {daubed && dauberStyle ? (
                <CardNumber90Dauber
                    as={dauberStyleObject[dauberStyle!] as any}
                    fill={dauberColorObject[dauberColor!]}
                />
            ) : (
                <CardNumber90Text isCalled={daubed}>
                    {children !== 0 ? children : null}
                </CardNumber90Text>
            )}
        </CardNumber90Container>
    );
};
