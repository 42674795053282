import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useMobileOrientation } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';

export const FOOTER_HEIGHT_REM = 6.25;
export const FOOTER_LANDSCAPE_HEIGHT_REM = 3;
export const PADDING_VERTICAL_REM = 0.625;

export type FooterSgProps = PropsWithChildren & {
    showFooter: boolean;
    onToggleFooter?: () => void;
};

const FooterSGcontainer = styled.div<{
    isPortrait: boolean;
    showFooter: boolean;
}>`
    position: fixed;
    bottom: 0;
    z-index: 10;
    display: ${({ showFooter }) => (showFooter ? 'flex' : 'none')};

    ${({ isPortrait }) =>
        isPortrait
            ? `
            max-width: 100%;
            width: 46.875rem;
            height: ${FOOTER_HEIGHT_REM}rem;
            padding: ${PADDING_VERTICAL_REM}rem 1.125rem;
        `
            : `
            width: 100%;
            height: ${FOOTER_LANDSCAPE_HEIGHT_REM}rem;
            left: 0;
            right: 0;
        `};

    ${({ theme: { footerSG } }) => `
        box-shadow: inset 0 0.313rem 0 0 ${footerSG.boxShadow.first}, inset 0 -0.25rem 0 0 ${footerSG.boxShadow.second};
        background-image: linear-gradient(to bottom, ${footerSG.backgroundGradient.upper}, ${footerSG.backgroundGradient.lower} 122%);
    `};
`;

const ArrowLeftImage = styled.img<{ showFooter: boolean }>`
    position: fixed;
    right: 1rem;
    bottom: 0.5rem;
    width: 2.25rem;
    height: 2rem;
    z-index: 10;

    transform: ${({ showFooter }) => (showFooter ? 'none' : 'rotate(180deg)')};
`;

export const FooterSG: FC<FooterSgProps> = ({
    showFooter,
    onToggleFooter,
    ...props
}) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const orientation = useMobileOrientation();

    const { arrowLeftFooter } = theme.images;

    return (
        <>
            <FooterSGcontainer
                {...props}
                showFooter={showFooter}
                isPortrait={orientation.isPortrait}
            >
                {props.children}
            </FooterSGcontainer>
            {orientation.isLandscape && pathname === '/side' ? (
                <ArrowLeftImage
                    src={arrowLeftFooter}
                    alt="Arrow Left Image"
                    showFooter={showFooter}
                    onClick={onToggleFooter}
                />
            ) : null}
        </>
    );
};
