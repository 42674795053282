import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '../../hooks/use-navigate';

type TabHeaderProps = HTMLAttributes<HTMLDivElement> & {
    hideSideGames: boolean;
};

const TabHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Mitr;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.125rem;
    color: ${(props) => props.theme.tabHeader.color};
`;

const TabHeaderPage = styled.div<{ active: boolean; hideSideGames?: boolean }>`
    width: ${({ hideSideGames }) => (hideSideGames ? '100%' : '50%')};
    text-align: center;
    cursor: pointer;
    user-select: none;

    ${({ active, theme: { tabHeader } }) => `
        border-bottom: 0.188rem solid ${tabHeader.borderBottom};
        opacity: ${active ? '1' : '0.3'};
    `};
`;
export const TabHeader: FC<TabHeaderProps> = ({ hideSideGames, ...props }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <TabHeaderContainer {...props}>
            <TabHeaderPage
                active={pathname === '/'}
                hideSideGames={hideSideGames}
                onClick={() => navigate('/')}
            >
                {t('nav.bingo')}
            </TabHeaderPage>
            {!hideSideGames && (
                <TabHeaderPage
                    active={pathname === '/side'}
                    onClick={() => navigate('/side')}
                >
                    {t('nav.sideGames')}
                </TabHeaderPage>
            )}
        </TabHeaderContainer>
    );
};
