export const useSetGlowEffectsRx = () => {
    const setGlowEffectRx = () => {
        const glowEffects = document.querySelectorAll('.glow-effects');

        glowEffects.forEach((glowEffect) => {
            const glowLines = glowEffect.querySelectorAll('rect');
            const rx = getComputedStyle(glowEffect).borderRadius;

            glowLines.forEach((glowLine) => {
                glowLine.setAttribute('rx', rx);
            });
        });
    };

    return setGlowEffectRx;
};
