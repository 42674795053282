import { chat } from '../../stores/chat';
import { ChatSocket } from '../../hooks/use-socket';
import { ChatInfo } from '../../types/bingo-events/in/chat/info';

export const onInfo = (socket: ChatSocket) => {
    const callback = (response: ChatInfo) => {
        if (response.message) {
            chat.messages.merge([
                {
                    message: response.message,
                    isSystemMsg: true,
                },
            ]);
        }
    };

    socket.on('info:parlay', callback);

    return () => {
        socket.off('info:parlay', callback);
    };
};
