import { useMemo } from 'react';
import { useHookstate } from '@hookstate/core';
import { screenConfig } from '../stores/backend';

export const useSideGames = () => {
    const {
        sideGames,
        screenConfig: { casinoAssetsURL },
    } = useHookstate(screenConfig);

    const $sideGames = useMemo(
        () =>
            sideGames.value.map((game) => {
                return {
                    title: game.name,
                    thumbnail: `${
                        casinoAssetsURL.value
                    }/${game.type.toUpperCase()}/${game.style.toUpperCase()}/img/icons/standard.jpg`,
                    src: game.launch,
                };
            }),
        [sideGames, casinoAssetsURL]
    );

    return $sideGames;
};
