import { chat, chatMeta } from '../../stores/chat';
import { ChatSocket } from '../../hooks/use-socket';
import { ChatJoin } from '../../types/bingo-events/in/chat/join';
import { getQuery } from '../../lib/query';

export const onJoin = (socket: ChatSocket) => {
    const { USERID: userId } = getQuery();

    const callback = (response: ChatJoin) => {
        const { players, rooms, msg } = response;

        const currentPlayer = players.find((player) => player.id === userId);
        const openRoom = rooms.find((room) => room.defaultOpen);

        let newRooms = rooms;
        let currentRoomId = chat.currentRoomId.value;

        if (!currentRoomId) {
            currentRoomId = openRoom?.id || 0;
        }

        chatMeta.initialized.set(true);

        if (!openRoom && rooms.length > 1) {
            newRooms = rooms.map((room, index) => {
                if (index === 0) {
                    return { ...room, defaultOpen: true };
                }
                return { ...room };
            });

            if (!currentRoomId) {
                currentRoomId = newRooms[0].id;
            }
        }

        chat.merge({
            ...response,
            rooms: newRooms,
            currentPlayer,
            currentRoomId,
            initialized: true,
        });

        if (msg) {
            chat.messages.merge([{ message: msg, isSystemMsg: false }]);
        }

        if (currentPlayer?.muted) {
            chat.messages.merge([
                { message: 'You are currently muted.', isSystemMsg: true },
            ]);
        }
    };

    socket.on('join:parlay', callback);

    return () => {
        socket.off('join:parlay', callback);
    };
};
