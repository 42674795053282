import { createState } from '../../lib/create-state';

import { BingoWinnerMessage } from '../../types/bingo-events/in/backend/winner-message';

export type WinnerState = BingoWinnerMessage;

export const DEFAULT_WINNER_STATE: WinnerState = {
    gameSessionId: 0,
    winners: [],
    consolationWinners: [],
};

export const [winners, resetWinners] = createState(DEFAULT_WINNER_STATE);
