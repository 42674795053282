import { FC, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { SideGamesThumbnail } from '../SideGamesThumbnail';
import { SideGamesListProps } from './mobile';

const SideGamesListContainer = styled.div<{ size?: number }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    height: 22.5rem;
    transition: padding-bottom 0.5s ease-out;
    box-shadow: inset 0 0.625rem 0.625rem -0.75rem #000,
        inset 0 -0.625rem 0.625rem -0.75rem #000;
    z-index: 10;

    ${({ size, theme: { sideGames } }) => `
        padding-bottom: ${size === 2 ? '11rem' : 0};
        transition:  ${size === 2 ? 'padding-bottom 0.5s ease' : 'none'};
        background-image: linear-gradient(
        to bottom,
            ${sideGames.backgroundGradient.upper},
            ${sideGames.backgroundGradient.middle} 50%,
            ${sideGames.backgroundGradient.lower} 100%
        );
    `}
`;

const SideGamesListItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    row-gap: 3rem;
    padding: 0 1.5rem;
    width: 100%;
`;

const SideGamesListItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 11rem;
    height: 7.125rem;
    cursor: pointer;
`;

const Arrow = styled.svg`
    position: absolute;
    fill: none;
    stroke: #fff;
    stroke-width: 3;
    width: 2.5rem;
    cursor: pointer;
`;

const ArrowLeft = styled(Arrow)`
    left: 0;
`;

const ArrowRight = styled(Arrow)`
    right: 0;
`;

const SideGamesFrameContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const SideGamesFrameClose = styled.img`
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 2.125rem;
    height: 2.125rem;
    z-index: 1;
    cursor: pointer;
`;

const SideGamesFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

export const SideGamesListDesktop: FC<SideGamesListProps> = ({
    size,
    sideGames,
    onToggleChat,
    ...props
}) => {
    const theme = useTheme();
    const { closeButton } = theme.images;

    const [showIframe, setShowIframe] = useState<boolean>(false);
    const [iframeSource, setIframSource] = useState<string>('');
    const [display, setDisplay] = useState<{ start: number; end: number }>({
        start: 0,
        end: 4,
    });

    const $size = size === 2 ? 2 : 4;

    const SIDE_GAMES_DISPLAY = {
        min: $size,
        max: sideGames.length,
    };

    const onClick = (src: string) => {
        setIframSource(src);
        setShowIframe((prevState) => !prevState);
    };

    const onClose = () => {
        setShowIframe((prevState) => !prevState);
    };

    const onPrevious = () => {
        setDisplay({
            start: display.start - $size,
            end: display.end - $size,
        });
    };

    const onNext = () => {
        setDisplay({
            start: display.start + $size,
            end: display.end + $size,
        });
    };

    useEffect(() => {
        if (size === 2) {
            setDisplay({
                start: 0,
                end: 2,
            });
        } else {
            setDisplay({
                start: 0,
                end: 4,
            });
        }
    }, [size]);

    return (
        <SideGamesListContainer {...props} size={size}>
            {display.start >= SIDE_GAMES_DISPLAY.min && (
                <ArrowLeft viewBox="0 0 24 24" onClick={onPrevious}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                </ArrowLeft>
            )}
            <SideGamesListItemsContainer>
                {sideGames
                    .slice(display.start, display.end)
                    .map(({ title, thumbnail, src }, i) => (
                        <SideGamesListItems
                            key={title + i}
                            onClick={() => {
                                onClick(src);
                                onToggleChat!(1);
                            }}
                        >
                            <SideGamesThumbnail thumbnail={thumbnail} />
                        </SideGamesListItems>
                    ))}
            </SideGamesListItemsContainer>
            {display.end < SIDE_GAMES_DISPLAY.max && (
                <ArrowRight viewBox="0 0 24 24" onClick={onNext}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                </ArrowRight>
            )}
            {showIframe && (
                <SideGamesFrameContainer>
                    <SideGamesFrame src={iframeSource} />
                    <SideGamesFrameClose
                        src={closeButton}
                        alt="Close Button Image"
                        onClick={onClose}
                    />
                </SideGamesFrameContainer>
            )}
        </SideGamesListContainer>
    );
};
