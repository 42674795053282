import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { useCountdown } from '../../hooks/use-countdown';

export type CountdownTimerProps = HTMLAttributes<HTMLDivElement> & {
    to: Date;
};

export const TimerContainer = styled.div<{ time: string }>`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 6.875rem;
    line-height: 6.875rem;

    ${({ time, theme: { timer } }) => `
        color: ${timer.color};
        opacity: ${time ? '1' : '0'}
    `};
`;

export const CountdownTimer: FC<CountdownTimerProps> = ({ to, ...props }) => {
    const { formatted } = useCountdown(to);

    return (
        <TimerContainer time={formatted} {...props}>
            {formatted || '&nbsp;'}
        </TimerContainer>
    );
};
