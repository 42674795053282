import { createState } from '../../lib/create-state';

import { BingoJackpotContributionMessage } from '../../types/bingo-events/in/backend/jackpot-contribution-message';

export type JackpotContributionState = BingoJackpotContributionMessage;

export const DEFAULT_JACKPOT_CONTRIBUTION_STATE: JackpotContributionState = {
    id: 0,
    amount: 0,
};

export const [jackpotContribution, resetJackpotContribution] = createState(
    DEFAULT_JACKPOT_CONTRIBUTION_STATE
);
