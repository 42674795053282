import { chat } from '../../stores/chat';
import { ChatSocket } from '../../hooks/use-socket';
import { ChatEnter } from '../../types/bingo-events/in/chat/enter';

export const onEnter = (socket: ChatSocket) => {
    const callback = (response: ChatEnter) => {
        const playerExist = chat.players.find(
            (player) => player.id.value === response.player.id
        );

        if (!playerExist) {
            chat.players.merge([response.player]);
        }

        if (response.message) {
            chat.messages.merge([
                { message: response.message, isSystemMsg: true },
            ]);
        }
    };

    socket.on('enter:parlay', callback);

    return () => {
        socket.off('enter:parlay', callback);
    };
};
