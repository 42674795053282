import { BackendSocket } from '../../hooks/use-socket';
import { BingoWinnerMessage } from '../../types/bingo-events/in/backend/winner-message';
import { meta, winners, current } from '../../stores/backend';
import { BackendPayload } from './payloads';

export const onWinnerMessage = (
    socket: BackendSocket,
    payload: BackendPayload
) => {
    const callback = (data: BingoWinnerMessage) => {
        const parts = Object.keys(current.game_parts.value).length;

        payload.getAccountStatus();
        winners.gameSessionId.set(data.gameSessionId);
        winners.winners.merge(data.winners);
        winners.consolationWinners.merge(data.consolationWinners);

        if (current.current_part.value + 1 === parts) {
            meta.modals.winner.set(true);
        }
    };

    socket.on('bingo:winnerMessage', callback);

    return () => {
        socket.off('bingo:winnerMessage', callback);
    };
};
