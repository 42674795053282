import { FC } from 'react';
import styled from 'styled-components';
import { Footer as FooterComponent } from '../../components/Footer';
import { ChatTextFieldDesktop } from '../../components/ChatTextField/desktop';
import { ChatBox } from '../ChatBox';
import { FooterProps } from './mobile';

const FooterWrapper = styled(FooterComponent)<{ focused: boolean }>`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border: none;
    border-radius: 0 0 1rem 1rem;
    box-shadow: none;
    background-image: none;
    z-index: 10;

    background-color: ${({ focused }) => (focused ? '#e9eaed' : 'transparent')};
`;

export const FooterDesktop: FC<FooterProps> = ({
    size,
    trialUser,
    message,
    pathname,
    placeholder,
    $chat,
    $chatMeta,
    inputReference,
    endMessageReference,
    onSend,
    onFocus,
    onClick,
    onSelect,
    onChange,
    onEnter,
    onEmoticon,
    onToggleChat,
    onClickOutside,
}) => {
    return (
        <>
            <ChatBox
                size={size}
                disabled={trialUser}
                endMessageRef={endMessageReference}
                onSelect={onSelect}
                handleClick={onClick}
                onToggleChat={onToggleChat}
                onClickOutside={onClickOutside}
            />
            {pathname !== '/side' || $chatMeta.chatToggle.value ? (
                <FooterWrapper
                    focused={$chatMeta.chatToggle.value}
                    pathname={pathname}
                >
                    {$chat.rooms.value.length !== 0 ? (
                        <ChatTextFieldDesktop
                            message={message}
                            inputRef={inputReference}
                            focused={$chatMeta.chatToggle.value}
                            placeholder={placeholder}
                            disabledButtons={trialUser}
                            mentions={$chatMeta.mentions.value}
                            disabled={
                                !$chatMeta.initialized.value ||
                                !$chatMeta.chatEnabled.value ||
                                $chatMeta.isBanned.value ||
                                $chatMeta.isDisabled.value ||
                                (trialUser && $chatMeta.chatToggle.value)
                            }
                            onChange={onChange}
                            onEmoticon={onEmoticon}
                            onSend={onSend}
                            onFocus={onFocus}
                            onEnter={onEnter}
                        />
                    ) : null}
                </FooterWrapper>
            ) : null}
        </>
    );
};
