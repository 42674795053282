import { createContext, useContext, useEffect, useState } from 'react';
import { useHookstate } from '@hookstate/core';
import { onConnect } from '../socket/chat/connect';
import { onDisconnect } from '../socket/chat/disconnect';
import { onEnter } from '../socket/chat/enter';
import { onInfo } from '../socket/chat/info';
import { onJoin } from '../socket/chat/join';
import { onLeave } from '../socket/chat/leave';
import { onMute } from '../socket/chat/mute';
import { ChatPayload, createChatPayload } from '../socket/chat/payload';
import { onPost } from '../socket/chat/post';
import { onUnmute } from '../socket/chat/unmute';
import { ChatSocket, useSocket } from './use-socket';
import { screenConfig as screenConfigState } from '../stores/backend/';

export type Subscriber = (
    socket: ChatSocket,
    payload: ChatPayload
) => () => void;

export const ChatContext = createContext({} as { chatPayload: ChatPayload });

export const useChat = () => {
    const { createChat } = useSocket();
    const [payload, setPayload] = useState<ChatPayload>();
    const { screenConfig } = useHookstate(screenConfigState);

    useEffect(() => {
        if (!screenConfig.site.siteId.value) {
            return;
        }

        const chat = createChat();
        const payload = createChatPayload(chat);

        setPayload(payload);

        const subscriptions = [
            onConnect,
            onDisconnect,
            onEnter,
            onInfo,
            onJoin,
            onLeave,
            onMute,
            onPost,
            onUnmute,
        ].map((onFn: Subscriber) => onFn(chat, payload));

        return () => {
            for (const unsubscribe of subscriptions) {
                unsubscribe();
            }
            chat.disconnect();
        };
    }, [createChat, screenConfig.site.siteId.value]);

    return payload;
};

export const useChatPayload = () => useContext(ChatContext);
