import { FC, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import { useCalled } from '../../hooks/use-called';
import {
    CardNumberProps,
    CardNumber75Dauber as CardNumber80Dauber,
} from '../CardNumber75';

export type CardNumber80Color = 'red' | 'yellow' | 'blue' | 'gray';

export type CardNumber80Props = CardNumberProps & {
    color: CardNumber80Color;
};

export const CardNumber80Container = styled.div<CardNumber80Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 4.063rem;
    height: 3.125rem;
    border-radius: 0.625rem;
    caret-color: transparent;

    ${({
        isCalled,
        autoDaub,
        color,
        dauberColor,
        dauberStyle,
        theme: { cardNumber80 },
    }) => `
        cursor: ${autoDaub ? 'auto' : 'pointer'};

        ${
            isCalled
                ? dauberColor && !dauberStyle
                    ? `background-color: ${dauberColor};`
                    : dauberStyle
                    ? `
                        box-shadow: ${cardNumber80.background[color].isCalled.boxShadow};
                        background-color: ${cardNumber80.background[color].isCalled.background};
                    `
                    : `
                box-shadow: ${cardNumber80.background[color].normal.boxShadow};
                background-color: ${cardNumber80.background[color].normal.background};
                border: solid 0.125rem ${cardNumber80.border};
            `
                : `
                box-shadow: ${cardNumber80.background[color].isCalled.boxShadow};
                background-color: ${cardNumber80.background[color].isCalled.background};
            `
        };
    `};
`;

export const ContainerStick = styled.div<{ isCalled: boolean }>`
    position: absolute;
    width: 0.625rem;
    height: 3.25rem;
    border-radius: 0.625rem;
    ${({
        isCalled,
        theme: {
            cardNumber80: { stickBackground },
        },
    }) =>
        isCalled
            ? `
            left: 0;
            margin-left: -0.188rem;
            box-shadow: ${stickBackground.boxShadow};
            background-image: ${stickBackground.background};
        `
            : `
            right: 0;
            margin-right: -0.188rem;
            box-shadow: ${stickBackground.boxShadow};
            background-image: ${stickBackground.background};
        `};
`;

export const CardNumber80Text = styled.div<{ isCalled: boolean }>`
    font-family: Signika-Bold;
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    ${({ isCalled, theme }) => `
        color: ${
            isCalled
                ? theme.cardNumber80.color.isCalled
                : theme.cardNumber80.color.normal
        };
        opacity: ${isCalled ? '1' : '0.7'};
        text-shadow: ${
            isCalled
                ? `0.063rem 0.063rem ${theme.cardNumber80.color.normal}`
                : `0.063rem 0.063rem ${theme.cardNumber80.color.isCalled}`
        };
    `};
`;

export const CardNumber80: FC<PropsWithChildren<CardNumber80Props>> = ({
    dauberColor,
    dauberStyle,
    bounce,
    children,
    ...props
}) => {
    const theme = useTheme();
    const { daubed, handleClick } = useCalled(
        props.autoDaub,
        props.isCalled,
        children
    );

    const { dauberColor: dauberColorObject } = theme;
    const { dauberStyle: dauberStyleObject } = theme.images;

    return (
        <CardNumber80Container
            {...props}
            className={bounce ? 'bounce' : ''}
            isCalled={daubed}
            dauberStyle={dauberStyle}
            dauberColor={dauberColorObject[dauberColor!]}
            onClick={handleClick}
        >
            <ContainerStick isCalled={daubed} />
            {daubed && dauberStyle ? (
                <CardNumber80Dauber
                    as={dauberStyleObject[dauberStyle!] as any}
                    fill={dauberColorObject[dauberColor!]}
                />
            ) : (
                <CardNumber80Text isCalled={daubed}>
                    {children}
                </CardNumber80Text>
            )}
        </CardNumber80Container>
    );
};
