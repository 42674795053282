import { useCallback } from 'react';
import { State } from '@hookstate/core';
import { formatCurrency } from '@parlaygames/currency';

import { MetaState } from '../stores/backend';
import { GameState } from '../stores/backend/game';

export const useFormatCurrency = (
    game: State<GameState>,
    meta: State<MetaState>
) =>
    useCallback(
        (value: number, to = game.player_currency.value) => {
            if (isNaN(value)) {
                return '';
            }

            try {
                const currency = game.currencies.value[meta.currency.value];
                const conversion = currency.convert[to] || 1;

                return formatCurrency(value * conversion, currency);
            } catch (error) {
                return '';
            }
        },
        [game.currencies.value, game.player_currency.value, meta.currency.value]
    );
