import { FC } from 'react';
import styled from 'styled-components';
import Modal, { Props as ModalProps, Styles as ModalStyles } from 'react-modal';

import { CloseButton } from '../CloseButton';

export type CommonModalProps = ModalProps & {
    onClose: () => void;
    Container?: typeof CommonModalContainer;
};

export const CUSTOM_STYLES: ModalStyles = {
    overlay: {
        overflowY: 'auto',
        overflowX: 'hidden',
        background: 'rgba(0, 0, 0, 0.25)',
        zIndex: 5,
    },
    content: {
        position: 'relative',
        padding: 0,
        background: 'transparent',
        border: '0',
        top: '1.25rem',
        right: '0',
        bottom: '1.25rem',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem',
    },
};

export const CommonModalContainer = styled.div`
    position: relative;
    padding: 4rem 4.938rem 4.5rem 4.938rem;
    border-radius: 1.25rem;
    display: inline-block;

    ${({ theme: { commonModal } }) => `
        border: 0.938rem solid ${commonModal.border};
        box-shadow: inset 0 0.25rem 0 0 ${commonModal.boxShadow};
        background-image: linear-gradient(to bottom, ${commonModal.backgroundGradient.upper} 2%, ${commonModal.backgroundGradient.lower} 97%);
    `};
`;

export const CloseButtonContainer = styled.div`
    position: absolute;
    top: -0.938rem;
    right: -0.938rem;
    border-radius: 1.25rem;

    ${({ theme: { commonModal } }) => `
        border: 0.5rem solid ${commonModal.border};
        background-color: ${commonModal.background};
    `};
`;

export const CommonModal: FC<CommonModalProps> = ({
    children,
    onClose,
    Container = CommonModalContainer,
    ...props
}) => {
    return (
        <Modal style={CUSTOM_STYLES} {...props}>
            <Container>
                <CloseButtonContainer>
                    <CloseButton handleClick={onClose} />
                </CloseButtonContainer>
                {children}
            </Container>
        </Modal>
    );
};
