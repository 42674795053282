import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    CommonModal,
    CommonModalProps,
    CommonModalContainer,
} from '../CommonModal';

export type BuyingInvalidModalProps = CommonModalProps & {
    message: string;
};

const BuyingInvalidModalContainer = styled(CommonModalContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38.75rem;
    min-height: 26.125rem;

    left: ${isMobile ? '0' : '16.875rem'};
`;

const BuyingInvalidModalImage = styled.img`
    width: 12.813rem;
    height: 12rem;
    margin-bottom: 2.688rem;
`;

const BuyingInvalidModalText = styled.div`
    font-family: Signika-Bold;
    font-size: 3.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.87;
    letter-spacing: normal;
    text-align: center;
    width: 29.5rem;
    color: #ffd000;
`;

export const BuyingInvalidModal: FC<BuyingInvalidModalProps> = ({
    message,
    ...props
}) => {
    const theme = useTheme();
    const { salesClosed } = theme.images;

    return (
        <CommonModal {...props} Container={BuyingInvalidModalContainer}>
            <BuyingInvalidModalImage
                src={salesClosed}
                alt="Sales Closed Image"
            />
            <BuyingInvalidModalText>{message}</BuyingInvalidModalText>
        </CommonModal>
    );
};
