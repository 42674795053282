import { next, pickedCards } from '../../stores/backend';
import { BackendSocket } from '../../hooks/use-socket';
import { InGetPickedCards } from '../../types/bingo-events/in/backend/picked-cards';

export const onGetPickedCards = (socket: BackendSocket) => {
    const callback = (response: InGetPickedCards) => {
        if (response.success) {
            const cardIds = Object.keys(pickedCards.cards.value);
            const boughtAndSelected = response.data.bought.concat(
                pickedCards.selected.value
            );

            let newCards: Record<string, string[]> = {};

            // sort cards so that bought and selected cards appear at the top of the pickCardsModal
            const customSort = (
                a: [string, string[]],
                b: [string, string[]]
            ) => {
                const index1 = boughtAndSelected.indexOf(a[0]);
                const index2 = boughtAndSelected.indexOf(b[0]);

                if (index1 === -1 && index2 === -1)
                    return parseInt(a[0]) - parseInt(b[0]);

                if (index1 === -1) return 1;

                if (index2 === -1) return -1;

                return index1 - index2;
            };

            // filter incoming cards so that only the bought, selected and new set of cards are available in the pickCardsModal.
            if (next.game_strip_active.value) {
                const pickedCardsStripId = pickedCards.selected.value.map(
                    (id) => {
                        const [strip] = id.split('-');
                        return strip;
                    }
                );

                const boughtCardsStripId = response.data.bought.map((id) => {
                    const [strip] = id.split('-');
                    return strip;
                });

                newCards = Object.fromEntries(
                    Object.entries(response.data.cards)
                        .filter((card) => {
                            const [stripId] = card[0].split('-');

                            return (
                                !cardIds.includes(card[0]) ||
                                pickedCardsStripId.includes(stripId) ||
                                boughtCardsStripId.includes(stripId)
                            );
                        })
                        .slice()
                        .sort(customSort)
                );
            } else {
                newCards = Object.fromEntries(
                    Object.entries(response.data.cards)
                        .filter((card) => {
                            return (
                                !cardIds.includes(card[0]) ||
                                pickedCards.selected.value.includes(card[0]) ||
                                response.data.bought.includes(card[0])
                            );
                        })
                        .slice()
                        .sort(customSort)
                );
            }

            pickedCards.merge({ ...response.data, cards: newCards });
        }
    };

    socket.on('bingo:getPickedCards', callback);

    return () => {
        socket.off('bingo:getPickedCards', callback);
    };
};
