import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CommonCardContainerProps } from '../../components/CommonCard';

type CardWinnerTextProps = {
    size: string;
    size90: string;
};

type CardTogoProps = {
    is90Ball?: boolean;
};

export const CardToGoText = styled.div<CardTogoProps & { size: string }>`
    font-family: Mitr;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.063rem;
    left: 0;

    ${({ is90Ball, size, theme: { cardTogo } }) => `
        position: ${is90Ball ? 'static' : 'absolute'};
        font-size: ${size};
        color: ${cardTogo.color};
        text-shadow: 0 0.125rem ${cardTogo.textShadow}, 0 0.063rem ${
        cardTogo.color
    };
    `};
`;

export const CardWinnerText = styled.div<CardTogoProps & CardWinnerTextProps>`
    font-family: Mitr;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.063rem;
    left: 0;
    margin: 0;

    ${({ is90Ball, size, size90, theme: { cardWinner } }) => `
        position: ${is90Ball ? 'static' : 'absolute'};
        font-size: ${is90Ball ? size90 : size};
        background: linear-gradient(to bottom, ${cardWinner.gradient.top}, ${
        cardWinner.gradient.middle
    } 58%, ${cardWinner.gradient.bottom});
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(0 -0.063rem ${
            cardWinner.color
        }) drop-shadow(0 0.125rem ${cardWinner.textShadow});
    `};
`;

export const CardToGo: FC<CommonCardContainerProps & CardTogoProps> = ({
    cardType,
    is90Ball,
    ...props
}) => {
    const { t } = useTranslation();

    const CARD_TOGO = {
        oneTG: `${t('baseCard.XToGo.msg', { toGo: 1 })}!`,
        twoTG: `${t('baseCard.XToGo.msg', { toGo: 2 })}!`,
        threeTG: `${t('baseCard.XToGo.msg', { toGo: 3 })}!`,
        default: '',
    };

    return (
        <>
            {cardType === 'winner' ? (
                <CardWinnerText
                    {...props}
                    is90Ball={is90Ball}
                    size={t('baseCard.winner.size')}
                    size90={t('baseCard.winner.size90')}
                >
                    {t('baseCard.winner.msg')}!
                </CardWinnerText>
            ) : (
                <CardToGoText
                    {...props}
                    is90Ball={is90Ball}
                    size={t('baseCard.XToGo.size')}
                    className={`${cardType}-glow`}
                >
                    {CARD_TOGO[cardType]}
                </CardToGoText>
            )}
        </>
    );
};
