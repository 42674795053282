import { useCallback } from 'react';
import { State } from '@hookstate/core';
import { ChatState, ChatMetaState } from '../stores/chat';
import { ChatPayload } from '../socket/chat/payload';

export const useOpenChat = (
    chat: State<ChatState>,
    chatMeta: State<ChatMetaState>,
    chatPayload: ChatPayload
) => {
    return useCallback(() => {
        chatMeta.chatToggle.set(true);
        chatMeta.mentions.set(0);
        chatPayload.changeRoom(chat.currentRoomId.value);
    }, [
        chat.currentRoomId.value,
        chatMeta.mentions,
        chatMeta.chatToggle,
        chatPayload,
    ]);
};
