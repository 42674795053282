import { MemoryRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import AppLayout from './layout';
import {
    SocketContext,
    createBackendSocket,
    createChatSocket,
} from './hooks/use-socket';
import './lib/i18n';
import { useAppTheme } from './hooks/use-app-theme';

const App = () => {
    const theme = useAppTheme();

    return (
        <MemoryRouter>
            <ThemeProvider theme={theme}>
                <Normalize />
                <SocketContext.Provider
                    value={{
                        createBackend: createBackendSocket,
                        createChat: createChatSocket,
                    }}
                >
                    <AppLayout />
                </SocketContext.Provider>
            </ThemeProvider>
        </MemoryRouter>
    );
};

export default App;
