import { createContext, useContext } from 'react';
import io, { IOSocket } from 'socket.io-client';
import { InBingoEvents } from '../types/bingo-events/in/backend';
import { OutBingoEvents } from '../types/bingo-events/out/backend';
import { InChatEvents } from '../types/bingo-events/in/chat';
import { OutChatEvents } from '../types/bingo-events/out/chat';

Object.assign(window, { io });

export type BackendSocket = IOSocket<InBingoEvents, OutBingoEvents>;

export type ChatSocket = IOSocket<InChatEvents, OutChatEvents>;

export type SocketStreams = {
    createBackend: () => BackendSocket;
    createChat: () => ChatSocket;
};

export const SocketContext = createContext({} as SocketStreams);

export const createSocket = (url: string) => {
    let origin = window.location.origin;
    let path = url;

    if (url.match(/^(wss?)|(https?):\/\//)) {
        const $url = new URL(url);
        origin = $url.origin;
        path = $url.pathname;
    }

    return io(origin, {
        path,
        transports: ['websocket'],
    });
};

export const createBackendSocket = (): BackendSocket =>
    createSocket(process.env.REACT_APP_WS_BACKEND_URL || '/ws-backend');

export const createChatSocket = (): ChatSocket =>
    createSocket(process.env.REACT_APP_WS_CHAT_URL || '/ws-chat');

export const useSocket = () => useContext(SocketContext);
