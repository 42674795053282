import {
    FC,
    useEffect,
    HTMLAttributes,
    RefObject,
    useLayoutEffect,
    useRef,
} from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';
import { Message } from '../Message';
import { CHAT_HEADER_HEIGHT_REM as CHAT_HEADER_HEIGHT_REM_MOBILE } from '../ChatHeader/mobile';
import { CHAT_HEADER_HEIGHT_REM as CHAT_HEADER_HEIGHT_REM_DESKTOP } from '../ChatHeader/desktop';
import { FOOTER_HEIGHT_REM as FOOTERSG_HEIGHT_REM } from '../FooterSG';
import { FOOTER_HEIGHT_REM } from '../Footer';
import { Messages } from '../../stores/chat';

const PADDING_TOP = isMobile
    ? CHAT_HEADER_HEIGHT_REM_MOBILE
    : CHAT_HEADER_HEIGHT_REM_DESKTOP;

type ChatMainProps = HTMLAttributes<HTMLDivElement> & {
    size?: number;
    disabled: boolean;
    messages: Messages[];
    users: string[];
    currentUser: string;
    pathname: string;
    endMessageRef: RefObject<HTMLDivElement>;
    messageContainerRef: RefObject<HTMLDivElement>;
    handleClick: (alias: string) => void;
};

const fontStyleCommon = css`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.03rem;

    font-size: ${isMobile ? '1.875rem' : '1.25rem'};
`;

const ChatMainContainer = styled.div<{
    isPortrait: boolean;
    pathname?: string;
    size?: number;
}>`
    z-index: 10;

    ${({ pathname, size }) => `
        ${
            isMobile
                ? `
            position: fixed;
            top: 16.5rem;
            bottom: 0;
            width: 46.875rem;
            max-width: 100%;
            padding: ${PADDING_TOP + 1.875}rem 0.375rem ${
                      FOOTER_HEIGHT_REM +
                      (pathname === '/side' ? FOOTERSG_HEIGHT_REM : 0) +
                      0.375
                  }rem;
        `
                : `
            position: absolute;
            top: ${size}rem;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            padding: ${PADDING_TOP + 0.5}rem 0.375rem ${
                      FOOTER_HEIGHT_REM + 0.375
                  }rem;
    `
        }
    `}

    border-radius: 1rem;
    background-color: ${({ theme }) => theme.chatMain.background};
`;

const ChatMainMask = styled(ChatMainContainer)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
`;

const ChatMainScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    row-gap: 0.5rem;
    height: 100%;
    overflow-x: auto;

    padding: ${isMobile ? '0 1.75rem' : '0 1rem'};

    ${({ theme: { chatMain } }) => `
        background-color: ${chatMain.background};
    

        &::-webkit-scrollbar-track {
            border-radius: 0.75rem;
            background-color: ${chatMain.scroll.track};
            border-left: 0.438rem solid ${chatMain.border};
            border-right: 0.438rem solid ${chatMain.border};
        }

        &::-webkit-scrollbar {
            width: 1.625rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.75rem;
            background-color: ${chatMain.scroll.thumb};
        }
    `};
`;

const WelcomeContainer = styled.div`
    font-family: Roboto-Medium;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;

    font-size: ${isMobile ? '1.875rem' : '1.25rem'};
    margin-bottom: ${isMobile ? '2.188rem' : '1rem'};
    color: ${({ theme }) => theme.chatMain.color.welcome};
`;

const NameMessageContainer = styled.div`
    display: flex;
    align-items: start;
`;

const ChatName = styled.div<{ isChatMaster?: boolean }>`
    ${fontStyleCommon};

    font-family: Roboto-Bold;
    margin-right: 0.375rem;
    cursor: pointer;
    color: ${({ isChatMaster, theme }) =>
        isChatMaster
            ? theme.chatMain.color.master.name
            : theme.chatMain.color.normal.name};
`;

const ChatMessage = styled.div<{ type: keyof typeof MESSAGE_TYPE }>`
    ${fontStyleCommon};

    font-family: Roboto;
    word-break: break-word;
    color: ${({ type, theme }) => theme.chatMain.color[type].message};
`;

const EndMessage = styled.div`
    margin-top: -5rem;
`;

const MESSAGE_TYPE = {
    master: 'master',
    normal: 'normal',
    system: 'system',
};

export const ChatMain: FC<ChatMainProps> = ({
    size,
    disabled,
    messages,
    users,
    currentUser,
    pathname,
    endMessageRef,
    messageContainerRef,
    handleClick,
    ...props
}) => {
    const ref = useRef<Messages | null>(null);
    const theme = useTheme();
    const orientation = useMobileOrientation();
    const { customEmoji } = theme.images;

    useEffect(() => {
        endMessageRef.current?.scrollIntoView({ behavior: 'auto' });
    }, [endMessageRef]);

    useLayoutEffect(() => {
        const last = messages[messages.length - 1];

        if (last?.alias === currentUser && ref.current !== last) {
            ref.current = last;
            setTimeout(() => {
                endMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 150);
        }
    }, [messages, currentUser, endMessageRef]);

    return (
        <ChatMainContainer
            {...props}
            isPortrait={orientation.isPortrait}
            size={size}
            pathname={pathname}
        >
            <ChatMainScrollContainer ref={messageContainerRef}>
                <WelcomeContainer>{messages[0]?.message}</WelcomeContainer>
                {messages.map((message, index) => {
                    const alias = message.alias;

                    if (index === 0) {
                        return null;
                    }

                    let type: keyof typeof MESSAGE_TYPE = 'normal';

                    if (message.isChatMaster) {
                        type = 'master';
                    }

                    if (message.bannedWord || message.isSystemMsg) {
                        type = 'system';
                    }

                    return (
                        <NameMessageContainer key={index}>
                            <ChatName
                                isChatMaster={message.isChatMaster}
                                onClick={
                                    alias && alias !== currentUser
                                        ? () => handleClick(alias)
                                        : undefined
                                }
                            >
                                {message.isChatMaster
                                    ? alias
                                        ? `#${alias}#:`
                                        : ''
                                    : alias
                                    ? `${alias}:`
                                    : ''}
                            </ChatName>
                            <ChatMessage type={type}>
                                <Message
                                    usersMap={users}
                                    text={message.message}
                                    imageMap={customEmoji}
                                    isChatMaster={message.isChatMaster}
                                />
                            </ChatMessage>
                        </NameMessageContainer>
                    );
                })}
                <EndMessage ref={endMessageRef} />
            </ChatMainScrollContainer>
            {disabled && <ChatMainMask isPortrait={orientation.isPortrait} />}
        </ChatMainContainer>
    );
};
