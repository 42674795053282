import { State } from '@hookstate/core';
import { ChangeEvent, FC, KeyboardEvent, RefObject } from 'react';
import styled from 'styled-components';
import { ChatTextFieldMobile } from '../../components/ChatTextField/mobile';
import { EmojiSelectEvent } from '../../components/EmojiPicker';
import { Footer as FooterComponent } from '../../components/Footer';
import { useSoundHandler } from '../../hooks/use-sound-handler';
import { ChatMetaState, ChatState } from '../../stores/chat';
import { ChatBox } from '../ChatBox';
import { NextGameSG } from '../NextGameSG';
import { PartWinnerPreviewSG } from '../PartWinnerPreviewSG';
import { PlayModeSG } from '../PlayModeSG';
import { MobileBuyButton } from './mobile-buy-button';

export type FooterProps = {
    size?: number;
    trialUser: boolean;
    message: string;
    pathname: string;
    placeholder: string;
    $chat: State<ChatState, {}>;
    $chatMeta: State<ChatMetaState, {}>;
    inputReference: RefObject<HTMLInputElement>;
    endMessageReference: RefObject<HTMLDivElement>;
    onBuy: () => void;
    onSend: () => void;
    onFocus: () => void;
    onEmoticon: () => void;
    onClickOutside: () => void;
    onToggleFooter: () => void;
    onClick: (alias: string) => void;
    onToggleChat?: (size?: number) => void;
    onSelect: (e: EmojiSelectEvent) => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onEnter: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const BuyButtonContainer = styled.div<{ focused: boolean }>`
    margin-left: 1rem;

    ${({ focused }) => `
        ${focused ? `display: none` : null}
    `};
`;

const FooterWrapper = styled(FooterComponent)<{ focused: boolean }>`
    z-index: 10;

    ${({ focused }) => `
        ${
            focused
                ? `
            background-color: #e9eaed;
            border: none;
            box-shadow: none;
            background-image: none;
        `
                : ''
        }
    `};
`;

export const FooterMobile: FC<FooterProps> = ({
    trialUser,
    message,
    pathname,
    placeholder,
    $chat,
    $chatMeta,
    inputReference,
    endMessageReference,
    onBuy,
    onSend,
    onFocus,
    onClick,
    onSelect,
    onChange,
    onEnter,
    onEmoticon,
    onClickOutside,
    onToggleFooter,
}) => {
    const $onBuy = useSoundHandler(onBuy, 'click');

    return (
        <>
            <ChatBox
                disabled={trialUser}
                endMessageRef={endMessageReference}
                onSelect={onSelect}
                handleClick={onClick}
                onClickOutside={onClickOutside}
            />
            {pathname !== '/' && (
                <>
                    <NextGameSG onToggleFooter={onToggleFooter} />
                    <PlayModeSG onToggleFooter={onToggleFooter} />
                    <PartWinnerPreviewSG />
                </>
            )}

            {pathname !== '/side' || $chatMeta.chatToggle.value ? (
                <FooterWrapper
                    focused={$chatMeta.chatToggle.value}
                    pathname={pathname}
                >
                    {$chat.rooms.value.length !== 0 ? (
                        <ChatTextFieldMobile
                            message={message}
                            inputRef={inputReference}
                            focused={$chatMeta.chatToggle.value}
                            placeholder={placeholder}
                            disabledButtons={trialUser}
                            mentions={$chatMeta.mentions.value}
                            disabled={
                                !$chatMeta.initialized.value ||
                                !$chatMeta.chatEnabled.value ||
                                $chatMeta.isBanned.value ||
                                $chatMeta.isDisabled.value ||
                                (trialUser && $chatMeta.chatToggle.value)
                            }
                            onChange={onChange}
                            onEmoticon={onEmoticon}
                            onSend={onSend}
                            onFocus={onFocus}
                            onEnter={onEnter}
                        />
                    ) : null}
                    <BuyButtonContainer focused={$chatMeta.chatToggle.value}>
                        <MobileBuyButton handleClick={$onBuy} />
                    </BuyButtonContainer>
                </FooterWrapper>
            ) : null}
        </>
    );
};
