import { useCallback, useMemo, useEffect, FC, RefObject } from 'react';
import { useHookstate } from '@hookstate/core';
import {
    PartWinners,
    WinnerModal as WinnerModalComponent,
} from '../../components/WinnerModal';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import {
    current as currentSate,
    meta as metaState,
    winners as winnersState,
    resetWinners,
} from '../../stores/backend';

type WinnerModalProps = {
    inputRef: RefObject<HTMLInputElement>;
};

export const WINNER_MODAL_CLOSE_DELAY = 15000;

export const WinnerModal: FC<WinnerModalProps> = ({ inputRef }) => {
    const meta = useHookstate(metaState);
    const current = useHookstate(currentSate);
    const winners = useHookstate(winnersState);

    const formatCurrency = useFormatCurrency(current, meta);

    const { partWinners, totalPrizes, lastWinner, consolationWinners } =
        useMemo(() => {
            const gameWinners = winners.winners.value || [];
            const partWinners: PartWinners[][] = [];

            let currency = '';
            let totalPrizes = 0;

            for (const winner of gameWinners) {
                (partWinners[winner.part - 1] =
                    partWinners[winner.part - 1] || []).push({
                    name: winner.userAlias,
                    prize: formatCurrency(winner.amount, winner.currency),
                });
                totalPrizes += winner.amount;
                currency = winner.currency;
            }

            return {
                partWinners,
                consolationWinners: winners.consolationWinners.value,
                lastWinner: gameWinners[gameWinners.length - 1],
                totalPrizes: formatCurrency(totalPrizes, currency),
            };
        }, [
            winners.winners.value,
            winners.consolationWinners.value,
            formatCurrency,
        ]);

    const onClose = useCallback(() => {
        meta.modals.winner.set(false);
        if (!meta.modals.winner.value) {
            resetWinners();
        }
    }, [meta.modals.winner]);

    const onAfterOpen = () => {
        inputRef.current?.focus();
    };

    useEffect(() => {
        const id = setTimeout(onClose, WINNER_MODAL_CLOSE_DELAY);
        return () => {
            clearTimeout(id);
        };
    }, [onClose]);

    return (
        <WinnerModalComponent
            isOpen={meta.modals.winner.value}
            gameName={current.game_desc.value}
            gameId={winners.gameSessionId.value}
            winningCall={lastWinner?.winningCall}
            winningNumber={lastWinner?.winningNumber}
            partWinners={partWinners}
            consolationWinners={consolationWinners}
            totalPrizes={totalPrizes}
            gameContinued={current.game_continued.value}
            recentWinners={false}
            onClose={onClose}
            onAfterOpen={onAfterOpen}
            loaded={true}
        />
    );
};
