import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {
    DauberCommonModal,
    DauberCommonModalProps,
    DauberCommonModalContainer,
} from '../DauberCommonModal';

export type DauberColorModalProps = DauberCommonModalProps & {
    onSelect: (value: string) => void;
    onDefault: () => void;
};

export const DauberColorModalContainer = styled(DauberCommonModalContainer)`
    width: 32.25rem;
    height: 36rem;
    left: ${isMobile ? '0' : '17rem'};
`;

export const DauberColorModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 3.25rem;
`;

export const DauberColorModalTitle = styled.div`
    font-family: Signika-Bold;
    font-size: 2rem;
    color: ${({ theme: { dauberModal } }) => dauberModal.title.color};
`;

export const DauberColorModalDefaultButton = styled.button`
    font-family: Signika-Medium;
    font-size: 1.25rem;
    border-radius: 0.313rem;
    padding: 0.25rem 1rem;
    cursor: pointer;

    ${({ theme: { settingsMenuButton } }) => `
        color: ${settingsMenuButton.color};
        box-shadow: ${settingsMenuButton.boxShadow.first} 0px 0.25rem 0px 0px inset,
        ${settingsMenuButton.boxShadow.second} 0px -0.25rem 0px 0px inset;
        border: 0.094rem solid ${settingsMenuButton.border};
        background-image: linear-gradient(
        ${settingsMenuButton.backgroundGradient.upper},
        ${settingsMenuButton.backgroundGradient.lower} 99%
    );
    `}
`;

export const DauberColorContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 1.875rem;
    row-gap: 1.875rem;
    height: 28rem;
    margin-top: 2rem;
    overflow-y: auto;

    ${({ theme: { dauberModal } }) => `
        background-color: ${dauberModal.background};
    

        &::-webkit-scrollbar-track {
            border-radius: 0.75rem;
            background-color: ${dauberModal.scroll.track};
            border-left: 0.438rem solid ${dauberModal.border};
            border-right: 0.438rem solid ${dauberModal.border};
        }

        &::-webkit-scrollbar {
            width: 1.625rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.75rem;
            background-color: ${dauberModal.scroll.thumb};
        }
    `};
`;

export const DauberColorItem = styled.div<{ bgColor?: string }>`
    width: 4.75rem;
    height: 4.75rem;
    border-radius: 0.313rem;
    cursor: pointer;

    background-color: ${({ bgColor }) => bgColor};
`;

export const DauberColorModal: FC<DauberColorModalProps> = ({
    onSelect,
    onDefault,
    ...props
}) => {
    const { dauberColor } = useTheme();

    return (
        <DauberCommonModal {...props} Container={DauberColorModalContainer}>
            <DauberColorModalHeader>
                <DauberColorModalTitle>Dauber Color</DauberColorModalTitle>
                <DauberColorModalDefaultButton onClick={onDefault}>
                    DEFAULT
                </DauberColorModalDefaultButton>
            </DauberColorModalHeader>
            <DauberColorContainer>
                {Object.keys(dauberColor).map((color) => {
                    return (
                        <DauberColorItem
                            key={color}
                            bgColor={dauberColor[color]}
                            onClick={() => onSelect(color)}
                        />
                    );
                })}
            </DauberColorContainer>
        </DauberCommonModal>
    );
};
