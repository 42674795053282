import { FC, useMemo } from 'react';
import { useHookstate } from '@hookstate/core';
import { useMobileOrientation } from 'react-device-detect';
import { ToggleFooterProps } from '../NextGameSG';
import { PlayModeSGPortrait } from '../../components/PlayModeSG/portrait';
import { PlayModeSGLandscape } from '../../components/PlayModeSG/landscape';
import { Part } from '../../components/GamePart';
import { useTogoMarkers } from '../../hooks/use-togo-markers';
import { useGameType } from '../../hooks/use-game-type';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useOpenChat } from '../../hooks/use-open-chat';
import { useChatPayload } from '../../hooks/use-chat';
import { calls, current, meta } from '../../stores/backend';
import { chat, chatMeta } from '../../stores/chat';

export const PlayModeSG: FC<ToggleFooterProps> = ({ onToggleFooter }) => {
    const $current = useHookstate(current);
    const $calls = useHookstate(calls);
    const $meta = useHookstate(meta);
    const $chat = useHookstate(chat);
    const $chatMeta = useHookstate(chatMeta);

    const gameType = useGameType($current);
    const togos = useTogoMarkers($current, $calls, $meta);

    const called = $calls.value;
    const gamePart = $current.current_part.value;
    const gameMode = $current.game_mode.value;
    const bought = useBoughtTickets($current);
    const { chatPayload } = useChatPayload();

    const parts = useMemo(
        () => Object.keys($current.game_parts.value).length,
        [$current.game_parts]
    );

    const onMentions = useOpenChat($chat, $chatMeta, chatPayload);

    const orientation = useMobileOrientation();

    const Component = orientation.isPortrait
        ? PlayModeSGPortrait
        : PlayModeSGLandscape;

    if (
        gameMode === 'GAME_OVER' ||
        (gameMode === 'GAME_MODE' && bought === 0) ||
        gameMode === 'PURCHASE_MODE'
    ) {
        return null;
    }

    return (
        <Component
            showFooter={meta.footers.sideGames.value}
            gameType={gameType}
            calls={called.length}
            calledBalls={called}
            gameParts={parts as Part}
            currentPart={(gamePart + 1) as Part}
            togos={togos}
            showMessageBubble={
                !!$chat.rooms.value.length && orientation.isPortrait
            }
            mentions={$chatMeta.mentions.value}
            handleMentions={onMentions}
            onToggleFooter={onToggleFooter}
        />
    );
};
