import { chat, chatMeta } from '../../stores/chat';
import { ChatSocket } from '../../hooks/use-socket';
import { ChatCommon } from '../../types/bingo-events/in/chat/common';
import { ChatPayload } from './payload';

export const onDisconnect = (socket: ChatSocket, payload: ChatPayload) => {
    const callback = (response: ChatCommon) => {
        chatMeta.merge({
            isEjected: response.message.includes('ejected'),
            isBanned: response.message.includes('banned'),
            isDisabled:
                response.message.includes('disabled') ||
                response.message.includes('not authenticated'),
        });

        // ban and eject action from chat master has the same message
        if (response.message.includes('ejected')) {
            payload.leaveRoom(chat.currentRoomId.value);
        }
        if (chatMeta.usersToggle.value) {
            chatMeta.usersToggle.set(false);
        }
        if (chatMeta.roomsToggle.value) {
            chatMeta.roomsToggle.set(false);
        }
        if (chatMeta.chatToggle.value) {
            chatMeta.chatToggle.set(false);
        }
    };

    socket.on('disconnect:parlay', callback);

    return () => {
        socket.off('disconnect:parlay', callback);
    };
};
