import { State } from '@hookstate/core';

import { GameState } from '../stores/backend';

import { useStripSize } from './use-strip-size';

export const useCardCost = (game: State<GameState>, buying: number) => {
    const stripSize = useStripSize(game);

    const stripped = buying / stripSize;
    const ticketCost = game.game_ticket_cost.value;

    if (game.fixed_game.value) {
        return ticketCost;
    }

    if (game.game_strip_only.value) {
        return stripped * game.game_strip_cost.value;
    }

    if (game.game_strip_active.value) {
        return (
            Math.floor(stripped) * game.game_strip_cost.value +
            (buying % stripSize) * ticketCost
        );
    }

    return buying * ticketCost;
};
