import { State } from '@hookstate/core';
import { useFormatCurrency } from '../hooks/use-format-currency';

import { AccountState, GameState, MetaState } from '../stores/backend';

export const useAccount = (
    game: State<GameState>,
    meta: State<MetaState>,
    account: State<AccountState>
) => {
    const formatCurrency = useFormatCurrency(game, meta);
    const $cash = account.player_cash.value;
    const $bonus = account.player_bonus.value;

    return {
        cash: formatCurrency($cash || 0),
        bonus: formatCurrency($bonus || 0),
        totalAccount: formatCurrency($cash + $bonus),
        wagered: formatCurrency(
            account.wagered.value || 0,
            game.game_currency.value
        ),
    };
};
