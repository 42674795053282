import { BackendSocket } from '../../hooks/use-socket';
import { BackendPayload } from './payloads';
import { closeModal } from '../../stores/backend';
import { meta } from '../../stores/backend';

export const onConnect = (socket: BackendSocket, payload: BackendPayload) => {
    const callback = async () => {
        await payload.loadGame();
        await payload.loadGame(true);

        if (meta.modals.msgCommon.isOpen.value) {
            closeModal('msgCommon');
        }
    };

    socket.on('connect', callback);

    return () => {
        socket.off('connect', callback);
    };
};
