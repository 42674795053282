import { FC } from 'react';
import styled from 'styled-components';
import { useState } from '@hookstate/core';
import { SideGamesList } from '../SideGamesList';
import { Footer } from '../Footer';
import {
    FOOTER_HEIGHT_REM,
    PADDING_VERTICAL_REM,
} from '../../components/FooterSG';
import {
    current as currentState,
    meta as metaState,
} from '../../stores/backend';

const PADDING_HORIZONTAL = FOOTER_HEIGHT_REM + PADDING_VERTICAL_REM;

export const SideGamesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 1.5rem ${PADDING_HORIZONTAL}rem;
    flex: 1 1 auto;
    overflow: auto;
`;

export const SideGames: FC = () => {
    const current = useState(currentState);
    const meta = useState(metaState);

    if (!current.game_sid.value || !meta.loaded.screenConfig.value) {
        return null;
    }

    return (
        <SideGamesContainer>
            <SideGamesList />
            <Footer />
        </SideGamesContainer>
    );
};
