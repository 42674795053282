import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';

export type GameTypeNumbers = 90 | 80 | 75;

export type GameTypeProps = HTMLAttributes<HTMLImageElement> & {
    gameType: GameTypeNumbers;
};

const GameTypeContainer = styled.img`
    background: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 3.125rem;
    height: 3.125rem;
    border: none;
    padding: 0;
    image-rendering: -webkit-optimize-contrast;
`;
export const GameType: FC<GameTypeProps> = ({ gameType, ...props }) => {
    const theme = useTheme();
    const { ballType75, ballType80, ballType90 } = theme.images;
    const ballTypeImg = {
        75: ballType75,
        80: ballType80,
        90: ballType90,
    };

    return <GameTypeContainer src={ballTypeImg[gameType]} {...props} />;
};
