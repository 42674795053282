import { useState, useEffect, useCallback } from 'react';

export type IncrementOptions = {
    change?: number;
    min?: number;
    max?: number;
};

export const useIncrement = (
    defaultValue: number,
    options?: IncrementOptions
) => {
    const change = options?.change || 1;

    const [value, setValue] = useState(defaultValue);

    const increment = useCallback(() => {
        setValue((value) => {
            const result = value + change;
            return typeof options?.max === 'undefined'
                ? result
                : Math.min(options.max!, result);
        });
    }, [setValue, change, options?.max]);

    const decrement = useCallback(() => {
        setValue((value) => {
            const result = value - change;
            return typeof options?.min === 'undefined'
                ? result
                : Math.max(options.min!, result);
        });
    }, [setValue, change, options?.min]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return [value, increment, decrement, setValue] as const;
};
