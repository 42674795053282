export const calculateTogo = (
    gameType: 75 | 80 | 90,
    cardNumbers: number[],
    patterns: number[][],
    matches: number,
    called: number[],
    hasFreeSpace: boolean
) => {
    let togo = 0;
    let lastNumber: number[] = [];
    let partsToGo: number[] = [];
    let numbersToGo: number[][] = [];
    let patternHit = false;

    patterns.forEach((pc) => {
        let currPatToGo = pc.length;
        let notCalled: number[] = [];
        pc.forEach((pcLoc) => {
            const $patternHit = called.indexOf(cardNumbers[pcLoc - 1]) >= 0;
            if ($patternHit) {
                patternHit = true;
            }

            if (
                cardNumbers[pcLoc - 1] === 0 ||
                (hasFreeSpace && gameType === 75 && pcLoc === 13) ||
                $patternHit
            ) {
                currPatToGo--;
            } else {
                notCalled.push(cardNumbers[pcLoc - 1]);
            }
        });
        partsToGo.push(currPatToGo);
        numbersToGo.push(notCalled);
    });

    partsToGo.sort();

    for (let i = 0; i < matches; i++) {
        togo += partsToGo[i];
    }

    if (togo === 1) {
        lastNumber = numbersToGo
            .sort((a, b) => a.length - b.length)
            .filter((subArr) => subArr.length === 1)
            .map((subArr) => subArr[0]);
    }

    return { togo, lastNumber, patternHit };
};
