import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type CommonPanelContainerProps = {
    type: 'primary' | 'secondary';
};

export type CommonPanelProps = HTMLAttributes<HTMLDivElement> &
    CommonPanelContainerProps;

const CommonPanelContainer = styled.div<CommonPanelContainerProps>`
    height: 12.125rem;
    width: 100%;
    padding: 0.5rem 0.813rem 0.813rem;
    border-radius: 0.75rem;

    ${({ type, theme: { commonPanel } }) =>
        type === 'primary'
            ? `
            border: solid 0.5rem ${commonPanel.border.primary};
            background-image: linear-gradient(to bottom, ${commonPanel.background.primary.gradientTop} -16%,
                ${commonPanel.background.primary.gradientBottom} 126%);
        `
            : `
            box-shadow: inset 0 0.25rem 0 0 ${commonPanel.boxShadow.secondary};
            border: solid 0.5rem ${commonPanel.border.secondary};
            background-image: linear-gradient(to bottom, ${commonPanel.background.secondary.gradientTop} 2%,
                ${commonPanel.background.secondary.gradientBottom} 97%);
        `};
`;

export const CommonPanel: FC<CommonPanelProps> = (props) => (
    <CommonPanelContainer {...props} />
);
