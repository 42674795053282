import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type IsStrippedProps = {
    isStripped?: boolean;
};

type CardIdTextProps = HTMLAttributes<HTMLDivElement> & IsStrippedProps;

export const CardIdText = styled.div<IsStrippedProps>`
    font-family: Signika-Bold;
    font-size: 1.125rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* color: #393939; */
    color: ${(props) => props.theme.cardId.color};
`;

export const CardId: FC<CardIdTextProps> = (props) => <CardIdText {...props} />;
