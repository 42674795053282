import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type NotificationProps = HTMLAttributes<HTMLDivElement> & {
    value: number;
};

const NotificationContainer = styled.div`
    font-family: Signika-Bold;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -0.313rem;
    right: -0.625rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;

    ${({ theme }) => `
        color: ${theme.notification.color};
        background-color: ${theme.notification.background};
    `};
`;

export const Notification: FC<NotificationProps> = ({ value, ...props }) => {
    return <NotificationContainer {...props}>{value}</NotificationContainer>;
};
