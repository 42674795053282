import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type Part = 1 | 2 | 3 | 4 | 5;

export type GamePartProps = HTMLAttributes<HTMLDivElement> & {
    gameParts: Part;
    currentPart: Part;
};

const GamePartContainer = styled.div`
    display: flex;
`;

export const GamePartDot = styled.div<{ isActive: boolean }>`
    width: 0.813rem;
    height: 0.813rem;
    border-radius: 50%;
    margin-right: 0.25rem;

    ${({ isActive, theme: { gamePart } }) => `
        ${
            isActive
                ? `
                border: none;
                background-color: ${gamePart.background.active};
                box-shadow: inset 0 0.063rem 0 0  ${gamePart.boxShadow.first.active};
            `
                : `
                border: solid 0.031rem ${gamePart.border};
                background-color:  ${gamePart.background.inactive};
                box-shadow: 0 0.063rem 0 0 ${gamePart.boxShadow.first.inactive},
                inset 0 0.125rem 0.188rem 0 ${gamePart.boxShadow.second};
            `
        }
    `};
`;

export const GamePart: FC<GamePartProps> = ({
    gameParts,
    currentPart,
    ...props
}) => {
    const GAME_PARTS = Array.from({ length: gameParts }).map((_v, i) => i + 1);

    return (
        <GamePartContainer {...props}>
            {GAME_PARTS.map((part) => {
                const isActive = currentPart >= part;
                return <GamePartDot key={part} isActive={isActive} />;
            })}
        </GamePartContainer>
    );
};
