import { State } from '@hookstate/core';
import delay from 'delay';
import { createState } from '../../lib/create-state';

export type MessageModal = {
    name: string;
    isOpen: boolean;
};

export type MetaState = {
    currency: string;
    autoDaub: boolean;
    sound: boolean;
    dauberColor: string;
    dauberStyle: string;

    timer: {
        id?: NodeJS.Timer;
        date: number;
    };

    enabled: {
        buying: boolean;
    };

    loaders: {
        screenConfig: boolean;
        buying: boolean;
        accountStatus: boolean;
        recWinners: boolean;
        pickedCards: boolean;
    };

    loaded: {
        screenConfig: boolean;
        recWinners: boolean;
    };

    modals: {
        buy: boolean;
        autoBuy: boolean;
        gameCancelled: boolean;
        winner: boolean;
        settings: boolean;
        helpDesk: boolean;
        recWinners: boolean;
        resGaming: boolean;
        freeCards: boolean;
        partWinners: boolean;
        gameInfo: boolean;
        jackpot: boolean;
        pickCards: boolean;
        marketing: boolean;
        dauberStyle: boolean;
        dauberColor: boolean;
        errorBuy: MessageModal;
        msgCommon: MessageModal;
    };

    panels: {
        partWinner: boolean;
    };

    footers: {
        sideGames: boolean;
    };
};

export const DEFAULT_META_STATE: MetaState = {
    currency: '',
    autoDaub: (localStorage.getItem('autoDaub') || 'true') === 'true',
    sound: (localStorage.getItem('sound') || 'true') === 'true',
    dauberColor: localStorage.getItem('dauberColor') || '',
    dauberStyle: localStorage.getItem('dauberStyle') || '',

    timer: {
        date: new Date().getTime(),
    },

    enabled: {
        buying: false,
    },

    loaders: {
        screenConfig: false,
        buying: false,
        accountStatus: false,
        recWinners: false,
        pickedCards: false,
    },

    loaded: {
        screenConfig: false,
        recWinners: false,
    },

    modals: {
        buy: false,
        autoBuy: false,
        gameCancelled: false,
        winner: false,
        settings: false,
        helpDesk: false,
        recWinners: false,
        resGaming: false,
        freeCards: false,
        partWinners: false,
        gameInfo: false,
        jackpot: false,
        pickCards: false,
        marketing: false,
        dauberStyle: false,
        dauberColor: false,
        errorBuy: {
            name: '',
            isOpen: false,
        },
        msgCommon: {
            name: '',
            isOpen: false,
        },
    },

    panels: {
        partWinner: false,
    },

    footers: {
        sideGames: true,
    },
};

export type MetaStateModals = MetaState['modals'];

export const [meta, resetMeta] = createState<MetaState>(DEFAULT_META_STATE);

export type PartialModalValue<T> = T extends MessageModal
    ? Partial<MessageModal>
    : T;

export type ModalOptions = {
    autoCloseDelay?: number;
    autoClose?: boolean;
    abortController?: AbortController;
};

export async function setModalVisibility<
    Key extends keyof MetaStateModals,
    Value extends PartialModalValue<MetaStateModals[Key]>
>(key: Key, value: Value, options?: ModalOptions) {
    const $options = {
        autoCloseDelay: 15000,
        ...options,
    };

    let booleanState: State<boolean>;

    if (typeof value === 'object') {
        const state = meta.modals[key] as State<MessageModal>;
        booleanState = state.isOpen;
        state.merge({
            ...state.value,
            ...(value as MessageModal),
        });
    } else {
        const state = (booleanState = meta.modals[key] as State<boolean>);
        state.set(value);
    }

    if (typeof $options?.autoClose !== 'undefined' && booleanState.value) {
        try {
            await delay($options.autoCloseDelay, {
                signal: options?.abortController?.signal,
            });

            booleanState.set(false);
        } catch (error) {}
    }
}

export const openModal = ({
    key,
    name,
    ...options
}: {
    key: keyof MetaStateModals;
    name?: string;
} & ModalOptions) => {
    const value = name ? { name, isOpen: true } : true;
    return setModalVisibility(key, value, options);
};

export const closeModal = (key: keyof MetaStateModals) => {
    const state = meta.modals[key];

    if (typeof state.value === 'boolean') {
        (state as State<boolean>).set(false);
    } else {
        (state as State<MessageModal>).isOpen.set(false);
    }
};
