import { FC, memo, useEffect } from 'react';
import styled from 'styled-components';

import { CommonCard } from '../CommonCard';
import { CardId } from '../CardId';
import { CardToGo } from '../CardTogo';
import { SelectedText } from '../SelectedText';
import { CardNumber80, CardNumber80Color } from '../CardNumber80';
import { BingoCard75Props as BingoCard80Props } from '../BingoCard75';
import { useCalculateTogo } from '../../hooks/use-calculate-togo';
import { useSetGlowEffectsRx } from '../../hooks/use-set-glow-effects-rx';

const ARRAY = [...Array(4)];

export const BingoCard80Container = styled(CommonCard)<{
    picked: boolean;
    bought: boolean;
    pickCards: boolean;
}>`
    height: 19.375rem;
    padding: 1.438rem 1rem;

    ${({ theme: { pickedCards }, picked, bought, pickCards }) => `
        position: relative;
        ${pickCards ? `cursor: pointer;` : ''}
        ${
            bought
                ? `
                box-shadow: 0 0.25rem 0 0 ${pickedCards.boxShadow.bought.first}, inset 0 0.125rem 0 0 ${pickedCards.boxShadow.bought.second};
                background-color: ${pickedCards.background.bought};
            `
                : picked
                ? `
                box-shadow: 0 0.25rem 0 0 ${pickedCards.boxShadow.picked.first}, inset 0 0.125rem 0 0 ${pickedCards.boxShadow.picked.second};
                background-color: ${pickedCards.background.picked};
                `
                : ''
        }
    `};
`;

const CardNumberContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
`;

export const CardRow = styled.div`
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    justify-items: center;
    grid-row-gap: 0.813rem;
`;

export const CardFooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin: 0.75rem 0;
`;

export const BingoCard80: FC<BingoCard80Props> = memo(
    ({
        gameType,
        tiles,
        cardId,
        autoDaub,
        hasFreeSpace,
        cardIndex,
        stripIndex,
        isStripped,
        dauberColor,
        dauberStyle,
        matches = 0,
        currentPart = 0,
        called = [],
        patterns = [],
        picked = false,
        bought = false,
        pickCards = false,
        handleClick,
        ...props
    }) => {
        const setGlowEffectRx = useSetGlowEffectsRx();
        const RYBG: Array<CardNumber80Color> = [
            'red',
            'yellow',
            'blue',
            'gray',
        ];
        const { cardType, lastNumber } = useCalculateTogo(
            gameType,
            tiles,
            patterns,
            matches,
            called,
            currentPart,
            hasFreeSpace
        );

        useEffect(() => setGlowEffectRx(), [cardType, setGlowEffectRx]);

        return (
            <BingoCard80Container
                cardType={pickCards ? 'default' : cardType}
                picked={picked}
                bought={bought}
                pickCards={pickCards}
                onClick={
                    pickCards && typeof cardIndex === 'number' && handleClick
                        ? () => handleClick(cardIndex, stripIndex)
                        : undefined
                }
                {...props}
                className={`${props.className} glow-effects`}
            >
                {cardType === 'oneTG' && (
                    <svg className="glow-container">
                        <filter id="blur">
                            <feGaussianBlur
                                stdDeviation="6"
                                edgeMode="duplicate"
                            />
                        </filter>
                        <rect
                            className="glow-blur"
                            strokeLinecap="round"
                            filter='url("#blur")'
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-line"
                            strokeLinecap="round"
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-blur-2"
                            strokeLinecap="round"
                            filter='url("#blur")'
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-line-2"
                            strokeLinecap="round"
                            pathLength={1000}
                        ></rect>
                    </svg>
                )}
                <CardNumberContainer>
                    {ARRAY.map((_vRow, row) => (
                        <CardRow key={row}>
                            {ARRAY.map((_vCol, ci) => {
                                const index = row * ARRAY.length + ci;
                                const squareLayout = (
                                    <CardNumber80
                                        key={ci}
                                        color={RYBG[row]}
                                        autoDaub={autoDaub}
                                        dauberColor={dauberColor}
                                        dauberStyle={dauberStyle}
                                        isCalled={called.includes(tiles[index])}
                                        bounce={lastNumber.includes(
                                            tiles[index]
                                        )}
                                    >
                                        {tiles[index]}
                                    </CardNumber80>
                                );

                                return squareLayout;
                            })}
                        </CardRow>
                    ))}
                </CardNumberContainer>
                <CardFooterContainer>
                    {pickCards && (picked || bought) ? (
                        <SelectedText type={bought ? 'bought' : 'picked'} />
                    ) : (
                        <CardId isStripped={isStripped}>{cardId}</CardId>
                    )}
                    <CardToGo cardType={pickCards ? 'default' : cardType} />
                </CardFooterContainer>
            </BingoCard80Container>
        );
    }
);
