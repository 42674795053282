import { FC, HTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export const CHAT_HEADER_HEIGHT_REM = 5.625;

export type ChatHeaderProps = HTMLAttributes<HTMLDivElement> & {
    roomName: string;
    users: number;
    size: number;
    onChatroom: () => void;
    onUsers: () => void;
    onClose: () => void;
    handleToggle?: (size?: number) => void;
};

const fontStyleCommon = css`
    font-family: Signika-SemiBold;
    font-size: 2.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: ${({ theme }) => theme.color};
`;

const contentContainerCommon = css`
    ${({ theme: { chatHeader } }) => `
        box-shadow: inset 0 -0.25rem 0 0 ${chatHeader.boxShadow.content};
        background-image: linear-gradient(to bottom, ${chatHeader.backgroundGradient.content.upper}, ${chatHeader.backgroundGradient.content.lower} 99%);
    `};
`;

const ChatHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 16.5rem;
    width: 46.875rem;
    max-width: 100%;
    padding-left: 1.875rem;
    z-index: 10;
    height: ${CHAT_HEADER_HEIGHT_REM}rem;

    ${({ theme: { chatHeader } }) => `
        box-shadow: inset 0 0.188rem 0 0 ${chatHeader.boxShadow.container};
        background-image: linear-gradient(to bottom, ${chatHeader.backgroundGradient.container.upper}, ${chatHeader.backgroundGradient.container.lower} 99%);
    `};
`;

const CloseButton = styled.div`
    font-family: Signika-Bold;
    font-size: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 6.5rem;
    height: 5.625rem;
    cursor: pointer;

    ${({ theme: { chatHeader } }) => `
        box-shadow: inset 0 0.188rem 0 0 ${chatHeader.boxShadow.close};
        background-image: linear-gradient(to bottom, ${chatHeader.backgroundGradient.content.upper}, ${chatHeader.backgroundGradient.content.lower} 99%);
    `};
`;

const Chatroom = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 2.188rem;
    position: absolute;
    left: 1rem;
    top: 0.75rem;
`;

const ArrowDown = styled.img`
    width: 1.75rem;
    height: 1.25rem;
    margin-left: 0.938rem;
    position: absolute;
    right: 1.25rem;
    top: 1.438rem;
`;

const RoomNameContainer = styled.div`
    flex: 1;
    height: 4.25rem;
    border-radius: 0.938rem;
    padding: 0.5rem 3.125rem 0 5.625rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    border: solid 0.125rem ${({ theme }) => theme.chatHeader.border};

    ${fontStyleCommon};
    ${contentContainerCommon};
`;

const UsersContainer = styled.div`
    font-size: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 7.813rem;
    height: 4.25rem;
    margin: 0 1.563rem;
    border-radius: 0.938rem;
    cursor: pointer;

    border: solid 0.125rem ${({ theme }) => theme.chatHeader.border};

    ${fontStyleCommon};
    ${contentContainerCommon};
`;

const UsersIcon = styled.img`
    width: 1.75rem;
    height: 1.875rem;
    margin-right: 0.563rem;
`;

export const ChatHeaderMobile: FC<ChatHeaderProps> = ({
    roomName,
    users,
    onChatroom,
    onUsers,
    onClose,
    ...props
}) => {
    const theme = useTheme();
    const { arrowDown, chatroom, users: usersIcon } = theme.images;

    const $onChatroom = useSoundHandler(onChatroom, 'click');
    const $onUsers = useSoundHandler(onUsers, 'click');
    const $onClose = useSoundHandler(onClose, 'click');

    return (
        <ChatHeaderContainer {...props}>
            <RoomNameContainer onClick={$onChatroom}>
                <Chatroom src={chatroom} alt="Chatroom Image" />
                {roomName}
                <ArrowDown src={arrowDown} alt="Arrow Down Image" />
            </RoomNameContainer>
            <UsersContainer onClick={$onUsers}>
                <UsersIcon src={usersIcon} alt="Users Icon" />
                {users}
            </UsersContainer>
            <CloseButton onClick={$onClose}>x</CloseButton>
        </ChatHeaderContainer>
    );
};
