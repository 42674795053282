import { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Notification } from '../Notification';
import { BuyButtonProps, WithTotalProps } from '.';

export const BuyButtonContainer = styled.button<
    WithTotalProps & { disabled: boolean }
>`
    padding: 0;
    border-radius: 1.125rem;
    opacity: 1;

    ${({
        withTotal,
        disabled: buttonDisabled,
        theme: {
            buyButton: { enabled, disabled },
        },
    }) => {
        return css`
            cursor: ${buttonDisabled ? 'not-allowed' : 'pointer'};
            width: ${withTotal ? '26rem' : '10.688rem'};
            height: ${withTotal ? '6.25rem' : '5rem'};
            border: solid 0.113rem
                ${buttonDisabled ? disabled.border : enabled.border};
            background-image: linear-gradient(
                to bottom,
                ${buttonDisabled
                        ? disabled.backgroundGradient.upper
                        : enabled.backgroundGradient.upper}
                    1%,
                ${buttonDisabled
                        ? disabled.backgroundGradient.lower
                        : enabled.backgroundGradient.lower}
                    99%
            );
            box-shadow: ${withTotal
                        ? `0 0.375rem 0 0 ${enabled.boxShadow.first},`
                        : ''}
                    inset 0 0.313rem 0 0
                    ${buttonDisabled
                        ? disabled.boxShadow.second
                        : enabled.boxShadow.second},
                inset 0 -0.313rem 0 0 ${buttonDisabled ? disabled.boxShadow.third : enabled.boxShadow.third};
        `;
    }};
`;

export const FlexWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageTextWrapper = styled.div<WithTotalProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ withTotal }) => `
        padding: ${withTotal ? '0.5rem 1.875rem' : '0.438rem 1.313rem'};
        flex-basis: ${withTotal ? '60%' : '100%'};
    `};
`;

export const ImageWrapper = styled.div`
    position: relative;
`;

export const CartImage = styled.img<WithTotalProps>`
    ${({ withTotal }) => `
        width: ${withTotal ? '2.875rem' : '2.25rem'};
        height ${withTotal ? '3.438rem' : '2.625rem'};
    `};
`;

export const BuyButtonText = styled.div<
    WithTotalProps & { size: number; sizeWithTotal: number }
>`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    ${({
        withTotal,
        size,
        sizeWithTotal,
        theme: {
            buyButton: { color },
        },
    }) => `
        font-size: ${withTotal ? `${sizeWithTotal}rem` : `${size}rem`};
        margin-top: ${withTotal ? '0.438rem' : '0.313rem'};
        margin-left: ${withTotal ? '0.5rem' : ''};
        color: ${color.default}
    `};
`;

export const commonDivider = styled.div`
    width: 0.063rem;
    height: 6rem;
`;

export const Divider1 = styled(commonDivider)<{ disabled: boolean }>`
    margin: 0 0.063rem 0 0;
    background-color: ${({
        disabled: disabledButton,
        theme: {
            buyButton: { enabled, disabled },
        },
    }) =>
        disabledButton
            ? disabled.background.divider1
            : enabled.background.divider1};
`;

export const Divider2 = styled(commonDivider)<{ disabled: boolean }>`
    margin: 0 0 0 0.063rem;
    opacity: 0.58;
    background-color: ${({
        disabled: disabledButton,
        theme: {
            buyButton: { enabled, disabled },
        },
    }) =>
        disabledButton
            ? disabled.background.divider2
            : enabled.background.divider2};
`;

export const LabelValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    font-family: Signika-Bold;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
`;

export const TotalLabel = styled.div`
    color: ${({
        theme: {
            buyButton: { color },
        },
    }) => color.default};
`;

export const TotalValue = styled.div`
    color: ${({
        theme: {
            buyButton: { color },
        },
    }) => color.totalValue};
`;

export const BuyButtonMobile: FC<BuyButtonProps> = ({
    withTotal,
    total,
    tickets,
    isDisabled,
    handleClick,
    ...props
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { cart } = theme.images;

    return (
        <BuyButtonContainer
            {...props}
            withTotal={withTotal}
            onClick={handleClick}
            disabled={isDisabled}
        >
            <FlexWrapper>
                <ImageTextWrapper withTotal={withTotal}>
                    <ImageWrapper>
                        <CartImage
                            withTotal={withTotal}
                            src={cart}
                            alt="Cart Image"
                        />
                        {typeof tickets === 'number' && (
                            <Notification value={tickets} />
                        )}
                    </ImageWrapper>
                    <BuyButtonText
                        withTotal={withTotal}
                        size={t('buyBtn.buy.mobile.size')}
                        sizeWithTotal={t('buyBtn.buy.mobile.sizeWithTotal')}
                    >
                        {t('buyBtn.buy.msg')}
                    </BuyButtonText>
                </ImageTextWrapper>
                {withTotal && (
                    <>
                        <Divider1 disabled={isDisabled} />
                        <Divider2 disabled={isDisabled} />
                        <LabelValueWrapper>
                            <TotalLabel>{t('buyBtn.total')}</TotalLabel>
                            <TotalValue>{total}</TotalValue>
                        </LabelValueWrapper>
                    </>
                )}
            </FlexWrapper>
        </BuyButtonContainer>
    );
};
