import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

type CloseButtonProps = HTMLAttributes<HTMLButtonElement> & {
    handleClick: () => void;
};

export const CloseButtonContainer = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.75rem;
    height: 3.75rem;
    padding: 0.938rem;
    border-radius: 0.75rem;
    cursor: pointer;

    ${({ theme: { closeButtonModal } }) => `
        box-shadow: inset 0 0.375rem 0 0 ${closeButtonModal.boxShadow.first},  inset 0 -0.375rem 0 0 ${closeButtonModal.boxShadow.second};
        border: solid 0.125rem ${closeButtonModal.border};
        background-image: linear-gradient(to bottom, ${closeButtonModal.backgroundGradient.upper}, ${closeButtonModal.backgroundGradient.lower} 99%)
    `};
`;

export const CloseSymbol = styled.div`
    font-family: Signika-Bold;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.closeButtonModal.color};
`;

export const CloseButton: FC<CloseButtonProps> = ({
    handleClick,
    ...props
}) => {
    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <CloseButtonContainer onClick={onClick} {...props}>
            <CloseSymbol>X</CloseSymbol>
        </CloseButtonContainer>
    );
};
