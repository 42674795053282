import { BackendSocket } from '../../hooks/use-socket';
import { BackendPayload } from './payloads';
import { meta } from '../../stores/backend';

export const onDisconnect = (
    socket: BackendSocket,
    payload: BackendPayload
) => {
    const callback = async () => {
        meta.modals.msgCommon.set({
            name: 'error.socketConnectionInterrupted',
            isOpen: true,
        });
    };

    socket.on('disconnect', callback);

    return () => {
        socket.off('disconnect', callback);
    };
};
