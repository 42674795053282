import { State } from '@hookstate/core';
import { useEffect, useMemo, useRef } from 'react';
import { useGameType } from '../hooks/use-game-type';
import { useSounds } from '../hooks/use-sound-handler';
import { createTimer } from '../lib/create-timer';
import { GameState, MetaState } from '../stores/backend';

export const useBackgroundSounds = (
    game: State<GameState>,
    calls: State<number[]>,
    meta: State<MetaState>
) => {
    const announcerRef = useRef({
        lastcall: false,
        nosales: false,
        starting: false,
    });

    const { play, initialized } = useSounds();
    const type = useGameType(game);
    const last = calls.value[calls.value.length - 1];
    const hasCards = Object.keys(game.player_cards.value || {}).length > 0;

    const winner = meta.modals.winner.value;
    const timer = meta.timer.date.value;

    const currentPart = game.current_part.value + 1;
    const gameParts = game.game_parts.value;
    const parts = useMemo(() => Object.keys(gameParts).length, [gameParts]);

    useEffect(() => {
        if (
            !initialized ||
            !meta.sound.value ||
            typeof last === 'undefined' ||
            !hasCards
        ) {
            return;
        }

        const id = last.toString().padStart(3, '0');

        if (last === Number(id)) {
            play(`ball${type}`, id);
        }
    }, [initialized, play, last, meta.sound.value, type, hasCards]);

    useEffect(() => {
        if (!winner || !hasCards) {
            return;
        }

        async function playWinner() {
            if (currentPart === parts) {
                await play('announcer', 'bingo');
                await play('ui', 'winnerbox');
            } else {
                await play('ui', 'partchange');
            }
        }

        playWinner();
    }, [winner, play, currentPart, parts, hasCards]);

    useEffect(() => {
        const { end } = createTimer(timer, (seconds) => {
            if (seconds <= 10 && !announcerRef.current.starting) {
                play('announcer', 'starting');
                announcerRef.current.starting = true;
            } else if (seconds <= 20 && !announcerRef.current.nosales) {
                play('announcer', 'nosales');
                announcerRef.current.nosales = true;
            } else if (seconds <= 30 && !announcerRef.current.lastcall) {
                play('announcer', 'lastcall');
                announcerRef.current.lastcall = true;
            }
        });

        return () => {
            announcerRef.current = {
                starting: false,
                nosales: false,
                lastcall: false,
            };
            end();
        };
    }, [play, timer]);
};
