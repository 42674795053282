import { FC } from 'react';
import styled from 'styled-components';
import Modal, { Props as ModalProps } from 'react-modal';
import { CUSTOM_STYLES } from '../CommonModal';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type DauberCommonModalProps = ModalProps & {
    onClose: () => void;
    Container?: typeof DauberCommonModalContainer;
};

export const DauberCommonModalContainer = styled.div`
    position: relative;
    padding: 1.875rem 1.75rem 1.875rem 2.5rem;
    border-radius: 1.25rem;
    background-color: ${({ theme: { dauberCommonModal } }) =>
        dauberCommonModal.background};
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1.75rem;
    right: 1.875rem;
`;

export const CloseButtonSymbol = styled.div`
    font-family: Signika-Bold;
    font-size: 2.125rem;
    transform: scale(1.2, 1);
    cursor: pointer;

    color: ${({ theme: { dauberCommonModal } }) =>
        dauberCommonModal.closeButton.color};
`;

const CUSTOM_STYLES_CONTENT = {
    overflow: 'hidden',
    top: '12rem',
};

export const DauberCommonModal: FC<DauberCommonModalProps> = ({
    children,
    onClose,
    Container = DauberCommonModalContainer,
    ...props
}) => {
    const onClick = useSoundHandler(onClose, 'click');

    return (
        <Modal
            {...props}
            style={{
                overlay: { ...CUSTOM_STYLES.overlay },
                content: { ...CUSTOM_STYLES.content, ...CUSTOM_STYLES_CONTENT },
            }}
        >
            <Container>
                <CloseButtonContainer>
                    <CloseButtonSymbol onClick={onClick}>X</CloseButtonSymbol>
                </CloseButtonContainer>
                {children}
            </Container>
        </Modal>
    );
};
