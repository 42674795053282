import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type PrebuyButtonProps = HTMLAttributes<HTMLButtonElement> &
    HTMLAttributes<HTMLImageElement> & {
        handleClick: () => void;
    };

const PrebuyButtonContainer = styled.button`
    background: transparent;
    width: 4.375rem;
    height: 4.375rem;
    border: none;
    padding: 0;
    cursor: pointer;
`;

const PrebuyButtonImage = styled.img`
    width: 4.375rem;
    height: 4.375rem;
    image-rendering: -webkit-optimize-contrast;
`;

export const PrebuyButton: FC<PrebuyButtonProps> = ({
    handleClick,
    ...props
}) => {
    const theme = useTheme();
    const { prebuyButton } = theme.images;
    const onClick = useSoundHandler(handleClick, 'click');
    return (
        <PrebuyButtonContainer {...props} onClick={onClick}>
            <PrebuyButtonImage src={prebuyButton} />{' '}
        </PrebuyButtonContainer>
    );
};
