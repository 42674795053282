import { FC, useState, useEffect } from 'react';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { SideGamesList } from '../SideGamesList';
import { GamePanel } from '../GamePanel';
import { BallCallsSlider } from '../BallCallsSlider';
import { Togo } from '../Togo';
import { BingoCardList } from '../BingoCardList';
import { GameStatePanel } from '../GameStatePanel';
import { Footer } from '../Footer';
import { useSideGames } from '../../hooks/use-side-games';
import {
    chat as chatState,
    chatMeta as chatMetaState,
} from '../../stores/chat';

export const BingoClassicContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 auto;
    padding: 0 1rem 1rem;
`;

export const FlexColContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const LeftContainer = styled(FlexColContainer)`
    justify-content: space-between;
    position: relative;
    width: 33.5%;
`;

export const RightContainer = styled(FlexColContainer)`
    width: 65.75%;
`;

export const SIZES = {
    1: 23,
    2: 11,
    3: 0,
};

export const BingoClassicDesktop: FC = () => {
    const $sideGames = useSideGames();
    const [size, setSize] = useState<keyof typeof SIZES>(
        $sideGames.length < 1 ? 3 : 1
    );
    const chat = useHookstate(chatState);
    const chatMeta = useHookstate(chatMetaState);

    const handleToggle = (size?: number) => {
        if (typeof size === 'number') {
            setSize(size as keyof typeof SIZES);
            return;
        }

        setSize((prev) => ((prev % 3) + 1) as keyof typeof SIZES);
    };

    useEffect(() => {
        if (!isMobile && chat.autoOpen.value) {
            chatMeta.chatToggle.set(true);
        }
    }, [chat.autoOpen, chatMeta.chatToggle]);

    return (
        <BingoClassicContainer>
            <LeftContainer>
                <SideGamesList size={size} onToggleChat={handleToggle} />
                <Footer size={size} onToggleChat={handleToggle} />
            </LeftContainer>
            <RightContainer>
                <GamePanel />
                <GameStatePanel />
                <BallCallsSlider />
                <Togo />
                <BingoCardList />
            </RightContainer>
        </BingoClassicContainer>
    );
};
