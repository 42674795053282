import { FC, useEffect, useCallback, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BuyButton, BuyButtonRawProps } from '../BuyButton';
import { FlexColWrapper } from '../BuyButton/desktop';
import { CardIdText } from '../CardId';
import { CardNumber75Container, CardNumber75Text } from '../CardNumber75';
import { CountdownTimer } from '../CountdownTimer';
import { GameTypeNumbers } from '../GameType';
import { ModalLabel } from '../ModalLabel';
import { ModalTitle } from '../ModalTitle';
import { SpecialBanner } from '../SpecialBanner';
import { CardNumber90Container, CardNumber90Text } from '../CardNumber90';
import {
    BingoCard80,
    CardFooterContainer,
    CardRow as CardRow80,
} from '../BingoCard80';
import {
    BingoCard75,
    CardNumber75Middle,
    CardRow as CardRow75,
    CardTextContainer,
    CardText,
} from '../BingoCard75';
import {
    BingoCard90,
    CardRow as CardRow90,
    CardTogoContainer,
} from '../BingoCard90';
import {
    CardNumber80Container,
    CardNumber80Text,
    ContainerStick,
} from '../CardNumber80';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
} from '../CommonModal';
import {
    StripContainer,
    CardsContainer,
    StripId,
} from '../BingoCardList/desktop';
import {
    SelectedTextContainer,
    CheckContainer,
    CheckSymbol,
} from '../SelectedText';
import { useSoundHandler } from '../../hooks/use-sound-handler';

type ButtonProps = Omit<BuyButtonRawProps, 'handleClick' | 'total'> & {
    handleBuy: (props: { tickets: number }) => void;
};

type CardsItem = {
    cardId: string;
    cardNumbers: number[];
};

type StrippedCard = {
    type: 'stripped';
    list: Array<{ stripId: string; cards: CardsItem[] }>;
};

type RegularCard = {
    type: 'regular';
    list: CardsItem[];
};

type PickCardsModalProps = CommonModalProps &
    ButtonProps & {
        cards: RegularCard | StrippedCard;
        bought: string[];
        selected: string[];
        gameType: GameTypeNumbers;
        stripActive: boolean;
        stripsOnly: boolean;
        hasFreeSpace: boolean;
        hasNext?: boolean;
        special: string;
        gameCurrency: string;
        ticketPrice: number;
        gameCardsMax: number;
        freeCards: number;
        fixedGame: number | undefined;
        countdownDate: Date;
        handleNewCards: () => void;
        handleSelected: (map: boolean[] | boolean[][]) => void;
        formatCurrency: (value: number, to?: string) => string;
    };

const CARD_STYLES = {
    75: css`
        width: 13rem;
        height: 14.125rem;
        padding: 0.688rem 0.313rem 0.438rem;
    `,
    80: css`
        width: 13rem;
        height: 12.188rem;
        padding: 1.125rem 0.625rem 0.188rem;
    `,
    90: css`
        width: 22.5rem;
        height: 7.25rem;
        padding: 0.75rem 0.625rem 0.5rem;
    `,
};

const PickCardsModalContainer = styled(CommonModalContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64.5rem;
    height: 48rem;
    left: 16.875rem;
    margin-top: 6rem;
    padding: 1.875rem 0.438rem 1.875rem 1.563rem;
`;

const PickCardsHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
    padding-right: 4rem;
`;

const PickCardsTitleBannerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 55%;
`;

const SpecialBannerWrapper = styled(SpecialBanner)`
    min-width: 65%;
`;

const PickCardsButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
`;

const PickCardsTitle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
`;

const ModalTitleWrapper = styled(ModalTitle)`
    font-size: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    margin-right: 0.313rem;
`;

const ModalLabelWrapper = styled.div`
    display: flex;
    align-self: flex-start;
    align-items: center;
    margin-bottom: 1.188rem;
`;

const SelectLabel = styled(ModalLabel)`
    font-size: 1.75rem;
    margin-right: 1rem;
`;

const MaxCardWrapper = styled.div`
    font-family: Signika-Medium;
    font-size: 1.25rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: ${({ theme }) => theme.pickCardsModal.color};
`;

const TimerWrapper = styled(CountdownTimer)`
    font-size: 2.5rem;
    line-height: 2rem;
    margin-top: 0.188rem;
`;

const BingoCardListContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 1.25rem;
    column-gap: 1.125rem;
    width: 100%;
    height: 31rem;
    overflow-x: auto;
    padding-bottom: 0.75rem;

    &::-webkit-scrollbar-track {
        border-radius: 0.438rem;
        background-color: ${({ theme: { pickCardsModal } }) =>
            pickCardsModal.scroll.track};
    }

    &::-webkit-scrollbar {
        width: 0.625rem;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.75rem;
        background-color: ${({ theme: { pickCardsModal } }) =>
            pickCardsModal.scroll.thumb};
    }
`;

const BingoCardListItem = styled.div<{ gameType: number }>`
    ${({ gameType }) => CARD_STYLES[gameType as keyof typeof CARD_STYLES]};

    /* 75BALL overrides */
    ${CardRow75} {
        grid-row-gap: 0.188rem;
    }

    ${CardTextContainer} {
        margin-bottom: 0.5rem;
    }

    ${CardText} {
        font-size: 1.5rem;
    }

    ${CardNumber75Middle} {
        width: 2rem;
        height: 1.875rem;
    }

    ${CardNumber75Container} {
        width: 2.375rem;
        height: 1.875rem;
    }

    ${CardNumber75Text} {
        font-size: 1.313rem;
    }

    /* 80BALL overrides */
    ${CardRow80} {
        grid-row-gap: 0.5rem;
    }

    ${CardNumber80Container} {
        width: 2.563rem;
        height: 2rem;
    }

    ${ContainerStick} {
        width: 0.5rem;
        height: 2.063rem;
        margin-right: -0.125rem;
    }

    ${CardNumber80Text} {
        font-size: 1.375rem;
    }

    ${CardFooterContainer} {
        margin: 0.25rem 0px;
    }

    /* 90BALL overrides */
    ${CardRow90} {
        grid-gap: 0.375rem;
    }

    ${CardNumber90Container} {
        width: 1.813rem;
        height: 1.813rem;
    }

    ${CardNumber90Text} {
        font-size: 1.313rem;
    }

    ${CardTogoContainer} {
        flex-basis: 5%;
    }

    /* Shared overrides */
    ${CardIdText} {
        font-size: ${({ gameType }) =>
            gameType === 90 ? '0.75rem' : '0.875rem'};
    }

    ${SelectedTextContainer} {
        font-size: ${({ gameType }) => (gameType === 90 ? '1rem' : '1.25rem')};
    }

    ${CheckContainer} {
        width: 1rem;
        height: 1rem;
    }

    ${CheckSymbol} {
        width: 1rem;
        height: 1rem;
    }
`;

const NewCardsButton = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 7.125rem;
    height: 6.5rem;
    border-radius: 1.438rem;
    cursor: pointer;

    ${({ disabled, theme: { pickCardsModal } }) => `
        border: solid 0.113rem ${pickCardsModal.border};
        background-image: linear-gradient(to bottom, ${
            pickCardsModal.backgroundGradient.upper
        }, ${pickCardsModal.backgroundGradient.lower} 99%);
        box-shadow: 0 0.375rem 0 0 ${
            pickCardsModal.boxShadow.first
        }, inset 0 0.313rem 0 0 ${pickCardsModal.boxShadow.second},
            inset 0 -0.313rem 0 0 ${pickCardsModal.boxShadow.third};
        opacity: ${disabled ? 0.5 : 1};
        pointer-events: ${disabled ? 'none' : 'auto'};

    `};
`;

const NewCardsIcon = styled.img`
    width: 2.813rem;
    height: 3.125rem;
`;

const NewCardsText = styled.div`
    font-family: Signika-Bold;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

const BuyButtonWrapper = styled(BuyButton)`
    width: 17rem;
    height: 6.5rem;

    ${FlexColWrapper} {
        height: 6rem;
    }
`;

const BINGO_CARD_COMPONENT = {
    75: BingoCard75,
    80: BingoCard80,
    90: BingoCard90,
};

const allTrue = (arr: boolean[]) => arr.every((v) => v === true);

export const PickCardsModalDesktop: FC<PickCardsModalProps> = ({
    cards,
    bought,
    selected,
    gameType,
    stripActive,
    stripsOnly,
    fixedGame,
    hasFreeSpace,
    hasNext,
    special,
    countdownDate,
    isDisabled,
    ticketPrice,
    gameCardsMax,
    gameCurrency,
    freeCards,
    handleBuy,
    handleSelected,
    handleNewCards,
    formatCurrency,
    ...props
}) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { newCards } = theme.images;
    const BingoCard = BINGO_CARD_COMPONENT[gameType];
    const boughtTickets = bought.length - freeCards;

    const initialcardsMap = useMemo(() => {
        const toggles =
            cards.type === 'regular'
                ? cards.list.map(
                      (card) =>
                          bought.includes(card.cardId) ||
                          selected.includes(card.cardId)
                  )
                : cards.list.map((item) => {
                      return item.cards.map(
                          (card) =>
                              bought.includes(
                                  item.stripId + '-' + card.cardId
                              ) ||
                              selected.includes(
                                  item.stripId + '-' + card.cardId
                              )
                      );
                  });

        const tickets = selected.length;
        let total = formatCurrency(tickets * ticketPrice, gameCurrency);

        if (fixedGame) {
            total = formatCurrency(ticketPrice, gameCurrency);
        }

        return {
            toggles,
            tickets,
            total,
            max: gameCardsMax,
        };
    }, [
        bought,
        selected,
        cards,
        gameCardsMax,
        ticketPrice,
        gameCurrency,
        fixedGame,
        formatCurrency,
    ]);

    const initialStripsMap = useMemo(
        () => initialcardsMap.toggles.map(() => true),
        [initialcardsMap.toggles]
    );

    const [map, setMap] = useState(initialcardsMap);
    const [stripMap, setStripMap] = useState(initialStripsMap);

    const $disabled =
        isDisabled || fixedGame ? map.tickets < gameCardsMax : false;

    const handleSubmission = useCallback(() => {
        handleBuy({
            tickets: map.tickets,
        });
    }, [handleBuy, map.tickets]);

    const checkStrip = (cardsToggle: boolean[][]) => {
        const activeStrip = cardsToggle.find((card: boolean[]) => {
            if (Array.isArray(card)) {
                return card.includes(true) && !allTrue(card);
            }

            return [];
        });

        const activeStripIndex = cardsToggle.indexOf(activeStrip || []);

        const toggles = cardsToggle.map((_card: boolean[], index: number) => {
            if (!activeStrip) {
                return true;
            }

            if (index === activeStripIndex) {
                return true;
            }

            return false;
        });

        if (activeStrip) {
            toggles[activeStripIndex] = true;
        }

        setStripMap(toggles);
    };

    const handleClick = useCallback(
        (index: number, stripIndex?: number) => {
            setMap((prevMap) => {
                const condition =
                    typeof stripIndex === 'number'
                        ? !(prevMap.toggles[stripIndex] as boolean[])[index]
                        : !prevMap.toggles[index];

                // prevents player from selecting cards if max tickets have been selected.
                // "condition" is needed so that the player can unselect a card when max tickets have been selected.
                if (
                    prevMap.tickets + boughtTickets === prevMap.max &&
                    condition
                ) {
                    return prevMap;
                }

                // select/unselect logic
                const sliced = prevMap.toggles.slice(0);

                if (typeof stripIndex === 'number' && stripsOnly) {
                    const strip = (sliced[stripIndex] as boolean[]).map(
                        (item) => !item
                    );
                    (sliced[stripIndex] as boolean[]) = strip;
                } else if (typeof stripIndex === 'number') {
                    (sliced[stripIndex] as boolean[])[index] = !(
                        sliced[stripIndex] as boolean[]
                    )[index];
                } else {
                    sliced[index] = !sliced[index];
                }

                // logic for adding and subtracting "number of tickets"
                let add: number = 0;

                if (typeof stripIndex === 'number' && stripsOnly) {
                    (sliced[stripIndex] as boolean[])[index]
                        ? (add = gameType === 90 ? 6 : 3)
                        : (add = gameType === 90 ? -6 : -3);
                } else if (typeof stripIndex === 'number') {
                    (sliced[stripIndex] as boolean[])[index]
                        ? (add = 1)
                        : (add = -1);
                } else {
                    sliced[index] ? (add = 1) : (add = -1);
                }

                const tickets = prevMap.tickets + add;
                let total = formatCurrency(tickets * ticketPrice, gameCurrency);

                if (fixedGame) {
                    total = formatCurrency(ticketPrice, gameCurrency);
                }

                handleSelected(sliced);

                return {
                    ...prevMap,
                    toggles: sliced,
                    tickets,
                    total,
                };
            });
        },
        [
            ticketPrice,
            stripsOnly,
            fixedGame,
            gameType,
            gameCurrency,
            boughtTickets,
            setMap,
            handleSelected,
            formatCurrency,
        ]
    );

    useEffect(() => {
        setMap(initialcardsMap);
    }, [initialcardsMap]);

    useEffect(() => {
        // check if a strip of cards should be clickable or not
        // only for strip_active: true and strip_only: false
        if (stripActive) {
            checkStrip(map.toggles as boolean[][]);
        }
    }, [stripActive, map.toggles]);

    const $handleNewCards = useSoundHandler(handleNewCards, 'click');
    const $handleSubmission = useSoundHandler(handleSubmission, 'buy');

    return (
        <CommonModal {...props} Container={PickCardsModalContainer}>
            <PickCardsHeaderContainer>
                <PickCardsTitleBannerContainer>
                    <PickCardsTitle>
                        <ModalTitleWrapper
                            title={t('modalPickCards.nextGame')}
                        />
                        <TimerWrapper to={countdownDate} />
                    </PickCardsTitle>
                    {special && <SpecialBannerWrapper text={special} />}
                </PickCardsTitleBannerContainer>
                <PickCardsButtonContainer>
                    <NewCardsButton
                        onClick={$handleNewCards}
                        disabled={hasNext === false}
                    >
                        <NewCardsIcon src={newCards} alt="New Cards Image" />
                        <NewCardsText>
                            {t('modalPickCards.newCards')}
                        </NewCardsText>
                    </NewCardsButton>

                    <BuyButtonWrapper
                        isDisabled={$disabled}
                        withTotal={true}
                        tickets={map.tickets}
                        total={map.total}
                        handleClick={$handleSubmission}
                    />
                </PickCardsButtonContainer>
            </PickCardsHeaderContainer>
            <ModalLabelWrapper>
                <SelectLabel label={t('modalPickCards.selectCards')} />
                {map.tickets + boughtTickets === map.max && (
                    <MaxCardWrapper>
                        {t('modalPickCards.maxCards')}
                    </MaxCardWrapper>
                )}
            </ModalLabelWrapper>

            <BingoCardListContainer>
                {cards.type === 'regular' && map.toggles.length > 0
                    ? cards.list.map(({ cardId, cardNumbers }, index) => {
                          const isBought = bought.includes(cardId);
                          return (
                              <BingoCardListItem
                                  as={BingoCard}
                                  key={cardId}
                                  cardId={cardId}
                                  tiles={cardNumbers}
                                  gameType={gameType}
                                  hasFreeSpace={hasFreeSpace}
                                  autoDaub={true}
                                  pickCards={true}
                                  picked={map.toggles[index] as boolean}
                                  bought={isBought}
                                  cardIndex={index}
                                  handleClick={
                                      isBought ? undefined : handleClick
                                  }
                              />
                          );
                      })
                    : null}
                {cards.type === 'stripped' && map.toggles.length > 0
                    ? cards.list.map((item, stripIndex) => (
                          <StripContainer
                              key={stripIndex}
                              active={stripMap[stripIndex]}
                          >
                              <StripId>{`${t('modalPurchase.cardTypeStrips')} ${
                                  item.stripId
                              }`}</StripId>
                              <CardsContainer>
                                  {item.cards.map(
                                      ({ cardId, cardNumbers }, index) => {
                                          const isBought = bought.includes(
                                              item.stripId + '-' + cardId
                                          );

                                          const picked =
                                              map.toggles[stripIndex] ||
                                              map.toggles[0];

                                          return (
                                              <BingoCardListItem
                                                  as={BingoCard}
                                                  key={cardId}
                                                  cardId={cardId}
                                                  tiles={cardNumbers}
                                                  gameType={gameType}
                                                  hasFreeSpace={hasFreeSpace}
                                                  autoDaub={true}
                                                  pickCards={true}
                                                  picked={
                                                      (picked as boolean[])[
                                                          index
                                                      ]
                                                  }
                                                  bought={isBought}
                                                  cardIndex={index}
                                                  stripIndex={stripIndex}
                                                  handleClick={
                                                      isBought
                                                          ? undefined
                                                          : handleClick
                                                  }
                                              />
                                          );
                                      }
                                  )}
                              </CardsContainer>
                          </StripContainer>
                      ))
                    : null}
            </BingoCardListContainer>
        </CommonModal>
    );
};
