import { account, error } from '../../stores/backend';
import { BackendSocket } from '../../hooks/use-socket';
import { BingoAccountStatus } from '../../types/bingo-events/in/backend/account-status';

export const onAccountStatus = (socket: BackendSocket) => {
    const callback = (response: BingoAccountStatus) => {
        if (response.success) {
            account.merge(response.data);
        } else {
            error.set({
                type: response.msg,
            });
        }
    };

    socket.on('bingo:accountStatus', callback);

    return () => {
        socket.off('bingo:accountStatus', callback);
    };
};
