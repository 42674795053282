import { BackendSocket } from '../../hooks/use-socket';
import { BingoJoinGame } from '../../types/bingo-events/in/backend/join-game';
import { next } from '../../stores/backend';

export const onJoinGame = (socket: BackendSocket) => {
    const callback = (data: BingoJoinGame) => {
        const prize = data.jackpot?.prize;
        if (prize) {
            next.jackpot.merge({
                value: prize,
            });
        }

        for (let i = 0; i < data.parts.length; i++) {
            const part = next.game_parts.nested(i.toString());
            part.prize.set(data.parts[i]);
        }

        next.merge({
            game_cards: data.numberOfTickets,
            game_players: data.numberOfPlayers,
        });
    };

    socket.on('bingo:joinGame', callback);

    return () => {
        socket.off('bingo:joinGame', callback);
    };
};
