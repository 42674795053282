import { State } from '@hookstate/core';
import { GameState, MetaState } from '../stores/backend';
import { useCards } from './use-cards';

export const useTogoMarkers = (
    game: State<GameState>,
    calls: State<number[]>,
    meta: State<MetaState>
) => {
    const cards = useCards(game, meta, calls);

    return cards.reduce(
        (result, card) => {
            const index = Math.min(card.togo, 4) - 1;
            if (card.patternHit && index >= 0) {
                result[index]++;
            }
            return result;
        },
        [0, 0, 0, 0]
    );
};
