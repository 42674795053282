import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BingoMarketingModal from '@parlaygames/bingomarketingmodal';
import { CreditsCard } from '../CreditsCard';
import { GameId } from '../GameId';
import { MoreButton } from '../MoreButton';
import { MaxMinButton } from '../MaxMinButton';
import { PrebuyButton } from '../PrebuyButton';
import { CurrencyToggle } from '../CurrencyToggle';
import { HeaderProps } from './mobile';

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem 0.5rem;
`;

const CreditsCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 43.5%;
    margin-right: 1rem;
`;

const UsernameContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17rem;
    height: 4.5rem;
    padding: 1.25rem;
    border-radius: 0.969rem;

    ${({ theme }) => `
        color: ${theme.username.color};
        background-color: ${theme.username.background};
    `};
`;

const UsernameText = styled.div`
    font-family: Signika-SemiBold;
    font-weight: 600;
    font-size: 1.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HeaderDesktop: FC<HeaderProps> = ({
    isOpen,
    isMax,
    gameId,
    cash,
    bonus,
    total,
    currency,
    username,
    hideCurrencyToggle,
    marketingModalConfig,
    onMarketingModalToggle,
    onSettingsOpen,
    onToggleCurrency,
    onToggleScreen,
}) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    return (
        <>
            {location.pathname !== '/side' && (
                <>
                    <GameId gameId={gameId} />
                    <HeaderContainer>
                        <ButtonsContainer>
                            <MoreButton handleClick={onSettingsOpen} />
                            <MaxMinButton
                                isMax={isMax}
                                handleClick={onToggleScreen}
                            />
                            <PrebuyButton
                                handleClick={onMarketingModalToggle}
                            />
                            <UsernameContainer>
                                <UsernameText>{username}</UsernameText>
                            </UsernameContainer>
                        </ButtonsContainer>
                        <CreditsCardContainer>
                            {!hideCurrencyToggle && (
                                <CurrencyToggle
                                    currency={currency}
                                    onClick={onToggleCurrency}
                                />
                            )}
                            <CreditsCard
                                type="CASH"
                                title={t('nav.cash')}
                                body={cash}
                                hideCurrencyToggle={hideCurrencyToggle}
                            />
                            <CreditsCard
                                type="BONUS"
                                title={t('nav.bonus')}
                                body={bonus}
                                hideCurrencyToggle={hideCurrencyToggle}
                            />
                            <CreditsCard
                                type="TOTAL"
                                title={t('nav.total')}
                                body={total}
                                hideCurrencyToggle={hideCurrencyToggle}
                            />
                        </CreditsCardContainer>
                    </HeaderContainer>
                    <BingoMarketingModal
                        {...marketingModalConfig}
                        locales={i18n.languages as string[]}
                        isOpen={isOpen}
                        onClose={onMarketingModalToggle}
                    />
                </>
            )}
        </>
    );
};
