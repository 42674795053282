import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    HeaderContainer,
    WinnerModalHeader as JackpotModalHeader,
} from '../WinnerModal';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
    CloseButtonContainer,
} from '../CommonModal';

type JackpotModalProps = CommonModalProps & {
    name: string;
    amount: string;
};

const fontStyleCommon = css`
    font-family: Signika-Bold;
    font-size: 2.813rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
`;

const JackpotModalContainer = styled(CommonModalContainer)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 38.75rem;
    height: 29.25rem;
    padding: 4.875rem 0 2.5rem 0;
    z-index: -1;

    ${CloseButtonContainer} {
        z-index: 2;
    }

    ${isMobile
        ? ''
        : `
        left: 14rem;
        margin-top: 5.25rem;
   `};
`;

export const JackpotModaTitle = styled.div`
    font-family: Signika-Bold;
    font-size: 3.938rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    width: 90%;

    background-image: repeating-linear-gradient(
        to bottom,
        #ffde63,
        #ffc900,
        #ff9003 50%
    );

    &:after {
        background: none;
        content: attr(data-text);
        left: 0;
        top: 0;
        z-index: -1;
        position: absolute;
        text-shadow: 0 -0.125rem 0 #fff, 0 0.313rem 0 #250c61,
            0 -0.438rem 0 #250c61;
    }
`;

const StarBurst = styled.img`
    position: absolute;
    bottom: 1.5rem;
    height: 15rem;
    image-rendering: -webkit-optimize-contrast;
`;

const StartBurstLeft = styled(StarBurst)`
    left: -2rem;
    transform: rotate(-54deg);
`;

const StartBurstRight = styled(StarBurst)`
    right: -2rem;
    transform: scaleX(-1) rotate(-54deg);
`;

const NameAmountContainer = styled.div`
    width: 22.5rem;
    height: 8.063rem;
    padding: 1rem;
    border-radius: 0.75rem;
    z-index: 1;
    background-color: ${({ theme }) => theme.jackpotModal.background};
`;

const NameText = styled.div`
    ${fontStyleCommon};

    color: ${(props) => props.theme.color};
`;

const AmountText = styled.div`
    ${fontStyleCommon};

    color: ${({ theme }) => theme.jackpotModal.color};
`;

export const JackpotModal: FC<JackpotModalProps> = ({
    name,
    amount,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { starBurstJackpot, headerBingo } = theme.images;

    return (
        <CommonModal
            {...props}
            Container={JackpotModalContainer}
            className="ReactModal__Content--after-open-override"
        >
            <HeaderContainer>
                <JackpotModalHeader
                    src={headerBingo}
                    alt="Header Bingo Image"
                />
            </HeaderContainer>
            <JackpotModaTitle data-text={t('jackpotModal.hasBeenWon')}>
                {t('jackpotModal.hasBeenWon')}
            </JackpotModaTitle>
            <StartBurstLeft
                src={starBurstJackpot}
                alt="Start Burst Jackpot Left Image"
            />
            <StartBurstRight
                src={starBurstJackpot}
                alt="Start Burst Jackpot Right Image"
            />
            <NameAmountContainer>
                <NameText>{name}</NameText>
                <AmountText>{amount}</AmountText>
            </NameAmountContainer>
        </CommonModal>
    );
};
