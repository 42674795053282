import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export type BallSize = 'big' | 'small' | 'xsmall' | 'xxsmall';

export type CommonBallContainerProps = {
    size: BallSize;
};

export type CommonBallProps = HTMLAttributes<HTMLDivElement> &
    CommonBallContainerProps;

const BALL_SIZE = {
    big: css`
        ${({ theme }) => `
            width: 8.938rem;
            height: 8.938rem;
            box-shadow: 0 0.625rem 0.188rem 0 ${theme.commonBall.boxShadow};
        `};
    `,
    small: css`
        ${({ theme }) => `
            width: 6.188rem;
            height: 6.188rem;
            box-shadow: 0 0.5rem 0.188rem 0 ${theme.commonBall.boxShadow};
        `};
    `,
    xsmall: css`
        ${({ theme }) => `
            width: 4.25rem;
            height: 4.25rem;
            box-shadow: 0 0.375rem 0.188rem 0 ${theme.commonBall.boxShadow};
        `};
    `,
    xxsmall: css`
        ${({ theme }) => `
        width: 2.75rem;
        height: 2.75rem;
        box-shadow: 0 0.25rem 0.188rem 0 ${theme.commonBall.boxShadow};
    `};
    `,
};

export const CommonBallContainer = styled.div<CommonBallContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0.156rem;
    border-radius: 50%;

    ${({ size }) => BALL_SIZE[size]};
`;

export const CommonBall: FC<CommonBallProps> = ({ size, ...props }) => {
    return (
        <CommonBallContainer size={size} {...props}>
            {props.children}
        </CommonBallContainer>
    );
};
