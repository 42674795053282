import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const SalesClosedModalOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
`;

const SalesClosedModalContainer = styled.div`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 2rem 3.125rem;
    border-radius: 50%;

    ${({ theme: { salesClosedModal } }) => `
        border: solid 0.625rem ${salesClosedModal.border};
        box-shadow: 0 1.25rem 0 0 ${salesClosedModal.boxShadow.first},
            inset 0 0.25rem 0 0  ${salesClosedModal.boxShadow.second};
        background-image: linear-gradient(to bottom, ${salesClosedModal.backgroundGradient.upper} 2%,
            ${salesClosedModal.backgroundGradient.lower} 97%);
    `};

    ${isMobile
        ? `
        width: 31.25rem;
        height: 31.25rem;
        margin-top: 20rem;
    `
        : `
        width: 27.25rem;
        height: 27.25rem;
        margin-top: 27rem;
        margin-left: 23.5rem;
    `};
`;

const SalesClosedImage = styled.img`
    padding: 0;

    margin: 0 0 1rem 1.25rem;

    ${isMobile
        ? `
        width: 13.25rem;
        height: 11.688rem;
    `
        : `
        width: 12.25rem;
        height: 10.688rem;
    `};
`;

const SalesClosedModalHeading = styled.div`
    font-family: Signika-Bold;
    line-height: 0.91;

    font-size: ${isMobile ? '2.5rem' : '2rem'};
    color: ${({ theme: { salesClosedModal } }) =>
        salesClosedModal.color.heading};
`;

const SalesClosedModalSubHeading = styled.div`
    font-family: Signika-SemiBold;
    line-height: 1;
    margin-top: 1rem;

    font-size: ${isMobile ? '1.75rem' : '1.5rem'};
    color: ${({ theme: { salesClosedModal } }) =>
        `${salesClosedModal.color.subheading}`};
`;

export const SalesClosedModal: FC = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { salesClosed } = theme.images;

    return (
        <SalesClosedModalOverlay>
            <SalesClosedModalContainer {...props}>
                <SalesClosedImage src={salesClosed} alt="Sales Closed Image" />
                <SalesClosedModalHeading>
                    {t('modalTicketSalesClosed.salesClosed')}
                </SalesClosedModalHeading>
                <SalesClosedModalSubHeading>
                    {t('modalTicketSalesClosed.pleaseWait')}
                </SalesClosedModalSubHeading>
            </SalesClosedModalContainer>
        </SalesClosedModalOverlay>
    );
};
