import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FooterSG } from '../FooterSG';
import { FooterTitleSG } from '../FooterTitleSG';
import { GamePart, GamePartProps, GamePartDot } from '../GamePart';
import { InfoButton } from '../InfoButton';
import { Winners as Winner } from '../CongratulationsPanel';

type PartWinnerPreviewSGProps = HTMLAttributes<HTMLDivElement> &
    GamePartProps & {
        winner: Winner;
        handleClick: () => void;
    };

const PartWinnerPreviewSGContainer = styled(FooterSG)`
    align-items: center;
    justify-content: space-around;
    padding: 0.25rem 3.75rem 0.25rem 8.75rem;
    width: 100%;
    left: 0;
    right: 0;
`;

const StarImage = styled.img`
    position: absolute;
    left: 3.75rem;
    top: -0.75rem;
    width: 4.5rem;
    height: 3.5rem;
`;

const PartWinnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const BingoWinnerLabel = styled(FooterTitleSG)`
    font-size: 1.625rem;
`;

const GamePartWrapper = styled(GamePart)`
    ${GamePartDot} {
        width: 0.75rem;
        height: 0.75rem;
    }
`;

const NameAmountContainer = styled.div`
    flex: 1;
    font-family: Signika-Bold;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin: 0.5rem 1rem 0;
    color: ${({ theme }) => theme.color};
`;

const InfoButtonWrapper = styled(InfoButton)`
    width: 2.5rem;
    height: 2.5rem;
`;

export const PartWinnerPreviewSGLandscape: FC<PartWinnerPreviewSGProps> = ({
    gameParts,
    currentPart,
    winner,
    handleClick,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { starImage3 } = theme.images;
    return (
        <PartWinnerPreviewSGContainer showFooter={true} {...props}>
            <StarImage src={starImage3} alt="Star Image" />
            <PartWinnerContainer>
                <GamePartWrapper
                    gameParts={gameParts}
                    currentPart={currentPart}
                />
                <BingoWinnerLabel text={t('Bingo Winner!')} />
            </PartWinnerContainer>
            <NameAmountContainer>
                {winner?.name} {winner?.amount}
            </NameAmountContainer>
            <InfoButtonWrapper handleClick={handleClick} />
        </PartWinnerPreviewSGContainer>
    );
};
