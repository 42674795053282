import { FC, useEffect, useState } from 'react';
import { useHookstate } from '@hookstate/core';
import styled, { useTheme } from 'styled-components';
import { GameStatePanel } from '../GameStatePanel';
import { BallCallsSlider } from '../BallCallsSlider';
import { Togo } from '../Togo';
import { BingoCardList } from '../BingoCardList';
import { GamePanelsSlider } from '../GamePanelsSlider';
import { Footer } from '../Footer';
import { FOOTER_HEIGHT_REM } from '../../components/Footer';
import { chatMeta } from '../../stores/chat';
import { GamePanel } from '../GamePanel';

export const BingoClassicContainer = styled.div<{ chatOpen: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    /* padding: 1.6875rem 1.25rem ${FOOTER_HEIGHT_REM}rem; */

    padding: ${({ chatOpen }) =>
        chatOpen
            ? `0.5rem 1.25rem ${FOOTER_HEIGHT_REM}rem`
            : `1.6875rem 1.25rem ${FOOTER_HEIGHT_REM}rem`};
`;

export const RotateScreenContainer = styled.div`
    display: none; /* Hide the image by default */
    position: fixed;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;

    @media only screen and (orientation: landscape) {
        display: block; /* Show the image in landscape mode */
    }
`;

export const RotateScreenImage = styled.img``;

export const BingoClassicMobile: FC = () => {
    const $chatMeta = useHookstate(chatMeta);
    const theme = useTheme();

    const { rotateScreen } = theme.images;

    const [isLandscape, setIsLandscape] = useState(
        window.innerHeight < window.innerWidth
    );

    useEffect(() => {
        const handleResize = () => {
            setIsLandscape(window.innerHeight < window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isLandscape ? (
        <RotateScreenContainer>
            <RotateScreenImage
                src={rotateScreen}
                alt="Please rotate your phone"
            />
        </RotateScreenContainer>
    ) : (
        <BingoClassicContainer chatOpen={$chatMeta.chatToggle.value}>
            {!$chatMeta.chatToggle.value ? (
                <>
                    <GamePanel />
                    <GameStatePanel />
                    <BallCallsSlider />
                    <Togo />
                    <BingoCardList />
                </>
            ) : (
                <GamePanelsSlider />
            )}
            <Footer />
        </BingoClassicContainer>
    );
};
