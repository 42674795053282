import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    CommonModal,
    CommonModalContainer,
    CommonModalProps,
} from '../CommonModal';
import { CommonToggle } from '../CommonToggle';
import { CurrencyToggle, CurrencyToggleProps } from '../CurrencyToggle';
import { ModalLabel } from '../ModalLabel';
import { ModalTitle } from '../ModalTitle';
import { SettingsMenuButton } from '../SettingsMenuButton';
import { DauberSelect } from '../DauberSelect';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type SettingsModalProps = CommonModalProps &
    CurrencyToggleProps & {
        currency: string;
        version: string;
        sound: boolean;
        chat: boolean;
        autoDaub: boolean;
        hideChat: boolean;
        hideCurrencyToggle: boolean;
        onHelpDesk: () => void;
        onRecentWinners: () => void;
        onResponsibleGaming: () => void;
        onToggleCurrency: () => void;
        onToggleSound: () => void;
        onToggleChat: () => void;
        onToggleDaub: () => void;
        onDauberStyle: () => void;
        onDauberColor: () => void;
    };

export const SettingsModalContainer = styled(CommonModalContainer)`
    padding: 0 0 3.563rem;
    margin-bottom: 8rem;
`;

export const MarginWrapper = styled.div`
    margin: 2.438rem 4.813rem 0.688rem;
`;

export const ModalTitleWrapper = styled(ModalTitle)`
    margin-bottom: 3rem;
`;

export const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
`;

export const LabelWrapper = styled(ModalLabel)`
    font-size: 2.5rem;
    letter-spacing: normal;
    color: ${({ theme }) => theme.settingsModal.color.label};
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.688rem;
`;

export const SettingsModalVersion = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    font-family: Signika-SemiBold;
    font-size: 1.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 100%;
    height: 3.563rem;

    ${({ theme: { settingsModal } }) => `
        color: ${settingsModal.color.version};
        background-image: linear-gradient(to bottom, ${settingsModal.backgroundGradient.upper} 2%,
            ${settingsModal.backgroundGradient.lower} 97%);
    `};
`;

export const SettingsModalMobile: FC<SettingsModalProps> = ({
    currency,
    version,
    sound,
    chat,
    hideChat,
    hideCurrencyToggle,
    onHelpDesk,
    onRecentWinners,
    onResponsibleGaming,
    onToggleSound,
    onToggleCurrency,
    onToggleChat,
    onDauberStyle,
    onDauberColor,
    ...props
}) => {
    const { t } = useTranslation();

    const $onToggleCurrency = useSoundHandler(onToggleCurrency, 'click');

    return (
        <CommonModal {...props} Container={SettingsModalContainer}>
            <MarginWrapper>
                <ModalTitleWrapper title={t('modalOptions.settings')} />
                <FlexWrapper>
                    {!hideCurrencyToggle && (
                        <>
                            <LabelWrapper label={t('modalOptions.currency')} />
                            <CurrencyToggle
                                currency={currency}
                                onClick={$onToggleCurrency}
                            />
                        </>
                    )}
                </FlexWrapper>
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.sound')} />
                    <CommonToggle checked={sound} onChange={onToggleSound} />
                </FlexWrapper>
                {!hideChat && (
                    <FlexWrapper>
                        <LabelWrapper label={t('modalOptions.chat')} />
                        <CommonToggle checked={chat} onChange={onToggleChat} />
                    </FlexWrapper>
                )}
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.dauberStyle')} />
                    <DauberSelect type="STYLE" onClick={onDauberStyle} />
                </FlexWrapper>
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.dauberColour')} />
                    <DauberSelect type="COLOR" onClick={onDauberColor} />
                </FlexWrapper>
                <ButtonsWrapper>
                    <SettingsMenuButton onClick={onHelpDesk}>
                        {t('modalOptions.helpLink')}
                    </SettingsMenuButton>
                    <SettingsMenuButton onClick={onRecentWinners}>
                        {t('modalOptions.recentWinners')}
                    </SettingsMenuButton>
                    <SettingsMenuButton onClick={onResponsibleGaming}>
                        {t('modalOptions.responsibleGaming')}
                    </SettingsMenuButton>
                </ButtonsWrapper>
            </MarginWrapper>
            <SettingsModalVersion>{version}</SettingsModalVersion>
        </CommonModal>
    );
};
