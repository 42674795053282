import { FC, useCallback } from 'react';
import { useHookstate } from '@hookstate/core';
import { PartWinnerModalSG as PartWinnerModalComponent } from '../../components/PartWinnerModalSG';
import { Part } from '../../components/GamePart';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { useWinners } from '../../hooks/use-winners';
import { current, meta, winners as winnersState } from '../../stores/backend';

export const PartWinnerModalSG: FC = () => {
    const $current = useHookstate(current);
    const $meta = useHookstate(meta);
    const $winners = useHookstate(winnersState);

    const currentPart = $current.current_part.value || 0;
    const formatCurrency = useFormatCurrency($current, $meta);
    const winners = useWinners($winners, currentPart, formatCurrency);

    const onClose = useCallback(() => {
        $meta.modals.partWinners.set(false);
    }, [$meta.modals.partWinners]);

    return (
        <PartWinnerModalComponent
            isOpen={$meta.modals.partWinners.value}
            onClose={onClose}
            part={(currentPart + 1) as Part}
            winners={winners}
        />
    );
};
