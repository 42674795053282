import { FC } from 'react';
import styled from 'styled-components';
import { createTiles } from '@parlaygames/bingo-utility';
import { CommonPanel } from '../CommonPanel';
import { CallBoardNumber } from '../CallBoardNumber';
import { CallBoard75Props as CallBoard90Props } from '../CallBoard75';

export const CallBoard90Container = styled(CommonPanel)`
    box-shadow: none;
    background-image: none;
    overflow: hidden;
    caret-color: transparent;
    background-color: ${(props) => props.theme.callBoard.board90.background};
`;

const CallBoard90Number = styled(CallBoardNumber)`
    line-height: 1.11;
    margin: 0;
    width: 2.375rem;
    height: 1.75rem;
    color: ${(props) => props.theme.callBoard.board90.color};
`;

export const SquareContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TILES = createTiles(1, 90, 15, false);

export const CallBoard90: FC<CallBoard90Props> = ({ called, ...props }) => (
    <CallBoard90Container type="secondary" {...props}>
        {TILES.map((tiles, index) => {
            return (
                <SquareContainer key={index}>
                    {tiles.map((tile) => (
                        <CallBoard90Number
                            number={tile}
                            isCalled={called.includes(tile)}
                            key={tile}
                        >
                            {tile}
                        </CallBoard90Number>
                    ))}
                </SquareContainer>
            );
        })}
    </CallBoard90Container>
);
