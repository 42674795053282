import { createState } from '../../lib/create-state';

export type AutobuyState = {
    balance: number;
    enableMaxLoss: boolean;
    games: number;
    gamesLimit: number;
    tickets: number;
    maxTicketPrice: number;
    maxLoss: number;
    maxLossLimit: number;
    anyPrice: boolean;
    activation: boolean;
    fixedPrice: boolean;
    enableUnlimited: boolean;
    enableMaxCards: boolean;
};

export const DEFAULT_AUTOBUY_STATE: AutobuyState = {
    balance: 0,
    games: 5,
    gamesLimit: 10,
    tickets: 5,
    maxTicketPrice: 0.5,
    maxLoss: 5,
    maxLossLimit: 100.0,
    anyPrice: true,
    activation: false,
    enableMaxLoss: false,
    fixedPrice: true,
    enableUnlimited: true,
    enableMaxCards: true,
};

export const [autoBuy, resetAutoBuy] = createState<AutobuyState>(
    DEFAULT_AUTOBUY_STATE
);
