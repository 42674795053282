import { BingoLoadGameResponse } from '../../types/bingo-events/in/backend/load-game-response';

import { DEFAULT_GAME_STATE, current, next } from './game';
import { meta } from './meta';
import { error } from './error';
import { calls } from './calls';
import { account } from './account';
import { createTimeRemainingLoop } from './timer';
import { resetPickedCards } from './picked-cards';

export const initialize = (
    response: BingoLoadGameResponse,
    attemptAutoBuy: () => void
) => {
    if (response.success) {
        const {
            data: {
                current_calls,
                wagered,
                player_cash,
                player_bonus,
                ...data
            },
            nextGame,
        } = response;

        const newCurrent = {
            ...DEFAULT_GAME_STATE,
            ...data,
        };

        if (nextGame) {
            next.set(newCurrent);

            meta.enabled.buying.set(true);

            clearInterval(meta.timer.id.value!);

            if (newCurrent.game_mode_time_remaining) {
                createTimeRemainingLoop(newCurrent.game_mode_time_remaining);
            }

            attemptAutoBuy();
            resetPickedCards();
        } else {
            current.set({
                ...newCurrent,
                current_part: newCurrent.current_part || 0,
            });

            calls.set((values) => [
                ...new Set(values.concat((current_calls || []).map(Number))),
            ]);

            if (!meta.currency.value) {
                meta.currency.set(data.player_currency);
            }
        }

        account.set({ wagered, player_cash, player_bonus });
    } else {
        error.set({
            type: response.msg,
        });
        meta.modals.msgCommon.set({
            name: 'error.game.running',
            isOpen: true,
        });
    }
};
