import { chat } from '../../stores/chat';
import { ChatSocket } from '../../hooks/use-socket';

export const onUnmute = (socket: ChatSocket) => {
    const callback = () => {
        chat.messages.merge([
            {
                message: 'You are unmuted.',
                isSystemMsg: true,
            },
        ]);
    };

    socket.on('unmute:parlay', callback);

    return () => {
        socket.off('unmute:parlay', callback);
    };
};
