import { FC, memo, useEffect } from 'react';
import styled from 'styled-components';

import { CommonCard } from '../CommonCard';
import { CardNumber90 } from '../CardNumber90';
import { CardId } from '../CardId';
import { CardToGo } from '../CardTogo';
import { SelectedText, CheckContainer, CheckSymbol } from '../SelectedText';
import { BingoCard75Props as BingoCard90Props } from '../BingoCard75';
import { useCalculateTogo } from '../../hooks/use-calculate-togo';
import { useSetGlowEffectsRx } from '../../hooks/use-set-glow-effects-rx';

const ARRAY_ROW = [...Array(9)];
const ARRAY_COL = [...Array(3)];

const BingoCard90Container = styled(CommonCard)<{
    picked: boolean;
    bought: boolean;
    pickCards: boolean;
}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.313rem 0.375rem 1.313rem 0.938rem;
    width: 43.125rem;
    height: 14rem;

    ${({ theme: { pickedCards }, picked, bought, pickCards }) => `
        position: relative;
        ${pickCards ? `cursor: pointer;` : ''}
        ${
            bought
                ? `
                box-shadow: 0 0.25rem 0 0 ${pickedCards.boxShadow.bought.first}, inset 0 0.125rem 0 0 ${pickedCards.boxShadow.bought.second};
                background-color: ${pickedCards.background.bought};
            `
                : picked
                ? `
                box-shadow: 0 0.25rem 0 0 ${pickedCards.boxShadow.picked.first}, inset 0 0.125rem 0 0 ${pickedCards.boxShadow.picked.second};
                background-color: ${pickedCards.background.picked};
                `
                : ''
        }
    `};
`;

const CardNumberContainer = styled.div`
    flex-basis: 90%;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    justify-items: center;
`;

const CardIdContainer = styled.div`
    writing-mode: vertical-rl;
    text-orientation: mixed;
`;

export const CardTogoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 6%;
    writing-mode: vertical-rl;
    text-orientation: mixed;
`;

export const CardRow = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    grid-gap: 0.625rem;
`;

const SelectedTextWrapper = styled(SelectedText)`
    font-size: 1.313rem;

    ${CheckContainer} {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

    ${CheckSymbol} {
        transform: rotate(90deg);
    }
`;

export const BingoCard90: FC<BingoCard90Props> = memo(
    ({
        gameType,
        tiles,
        cardId,
        autoDaub,
        hasFreeSpace,
        cardIndex,
        stripIndex,
        isStripped,
        dauberColor,
        dauberStyle,
        matches = 0,
        currentPart = 0,
        called = [],
        patterns = [],
        picked = false,
        bought = false,
        pickCards = false,
        handleClick,
        ...props
    }) => {
        const setGlowEffectRx = useSetGlowEffectsRx();
        const { cardType, lastNumber } = useCalculateTogo(
            gameType,
            tiles,
            patterns,
            matches,
            called,
            currentPart,
            hasFreeSpace
        );

        useEffect(() => setGlowEffectRx(), [cardType, setGlowEffectRx]);

        return (
            <BingoCard90Container
                cardType={pickCards ? 'default' : cardType}
                picked={picked}
                bought={bought}
                pickCards={pickCards}
                onClick={
                    pickCards && typeof cardIndex === 'number' && handleClick
                        ? () => handleClick(cardIndex, stripIndex)
                        : undefined
                }
                {...props}
                className={`${props.className} glow-effects`}
            >
                {cardType === 'oneTG' && (
                    <svg className="glow-container">
                        <filter id="blur">
                            <feGaussianBlur
                                stdDeviation="6"
                                edgeMode="duplicate"
                            />
                        </filter>
                        <rect
                            className="glow-blur glow-90-ball"
                            strokeLinecap="round"
                            filter='url("#blur")'
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-line glow-90-ball"
                            strokeLinecap="round"
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-blur-2 glow-90-ball"
                            strokeLinecap="round"
                            filter='url("#blur")'
                            pathLength={1000}
                        ></rect>
                        <rect
                            className="glow-line-2 glow-90-ball"
                            strokeLinecap="round"
                            pathLength={1000}
                        ></rect>
                    </svg>
                )}
                <CardIdContainer>
                    <CardId>{cardId}</CardId>
                </CardIdContainer>
                <CardNumberContainer>
                    {ARRAY_ROW.map((_vRow, row) => (
                        <CardRow key={row}>
                            {ARRAY_COL.map((_vColumn, column) => {
                                const index = row * ARRAY_COL.length + column;
                                return (
                                    <CardNumber90
                                        key={column}
                                        autoDaub={autoDaub}
                                        dauberColor={dauberColor}
                                        dauberStyle={dauberStyle}
                                        isCalled={called.includes(tiles[index])}
                                        bounce={lastNumber.includes(
                                            tiles[index]
                                        )}
                                    >
                                        {tiles[index]}
                                    </CardNumber90>
                                );
                            })}
                        </CardRow>
                    ))}
                </CardNumberContainer>
                <CardTogoContainer>
                    {pickCards && (picked || bought) ? (
                        <SelectedTextWrapper
                            type={bought ? 'bought' : 'picked'}
                        />
                    ) : null}
                    <CardToGo
                        cardType={pickCards ? 'default' : cardType}
                        is90Ball={true}
                    />
                </CardTogoContainer>
            </BingoCard90Container>
        );
    }
);
