import { FC, useCallback, useMemo } from 'react';
import { useHookstate } from '@hookstate/core';
import { GameInfoModal as GameInfoModalComponent } from '../../components/GameInfoModal';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { useJackpot } from '../../hooks/use-jackpot';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useGamePart } from '../../hooks/use-game-part';
import { useCycleGameParts } from '../../hooks/use-cycle-game-parts';
import {
    current as currentState,
    next as nexState,
    meta as metaState,
    calls as callsState,
} from '../../stores/backend';

export const GameInfoModal: FC = () => {
    const meta = useHookstate(metaState);
    const next = useHookstate(nexState);
    const current = useHookstate(currentState);
    const calls = useHookstate(callsState);

    const bought = useBoughtTickets(current);

    let game = next;

    if (meta.modals.buy.value) {
        game = next;
    } else if (bought) {
        game = current;
    }

    const jackpot = useJackpot(game, meta);
    const { allGameParts } = useGamePart(game, meta);
    const formatCurrency = useFormatCurrency(game, meta);

    const parts = Object.values(game.game_parts.value || {}).map((part) => ({
        description: part.desc,
        prize: formatCurrency(part.prize),
        hasJackpot: part.has_jackpot,
    }));

    const size = useMemo(
        () => Object.keys(allGameParts).length,
        [allGameParts]
    );

    const index = useCycleGameParts(size);
    const prizeString = allGameParts[index]?.prizeString;
    const gamePrize =
        prizeString && prizeString.length > 1
            ? prizeString[bought ? calls.length - 1 : 0]
            : allGameParts[index]?.prize;

    const onClose = useCallback(() => {
        meta.modals.gameInfo.set(false);
    }, [meta.modals.gameInfo]);

    return (
        <GameInfoModalComponent
            {...jackpot}
            isOpen={meta.modals.gameInfo.value}
            gameId={game.game_sid.value}
            gameParts={parts}
            gamePrize={formatCurrency(gamePrize, game.game_currency.value)}
            gameContinued={game.game_continued?.value}
            onClose={onClose}
        />
    );
};
