import { FC } from 'react';
import styled from 'styled-components';
import F from '../../assets/images/daubers/F.svg';
import H from '../../assets/images/daubers/H.svg';
import E from '../../assets/images/daubers/E.svg';

export type DauberSelectProps = {
    type: 'COLOR' | 'STYLE';
    onClick: () => void;
};

export const DauberSelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 9.625rem;
    height: 4.625rem;
    border-radius: 0.625rem;
    padding: 1rem 0.75rem;
    cursor: pointer;
`;

export const DauberSelectItem = styled.div<{ bgColor?: string }>`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.313rem;
    cursor: pointer;

    background-color: ${({ bgColor }) => bgColor};
`;

export const DauberStyleItem = styled.svg`
    fill: #3b02fe;
    width: 2.5rem !important;
`;

const STYLE_PREVIEW: Record<string, string> = {
    A: F,
    B: H,
    C: E,
};

const COLOR_PREVIEW: Record<string, string> = {
    A: '#3b02fe',
    B: '#b902fe',
    C: '#fe02c4',
};

export const DauberSelect: FC<DauberSelectProps> = ({ type, ...props }) => {
    return (
        <DauberSelectContainer {...props}>
            {type === 'STYLE'
                ? Object.keys(STYLE_PREVIEW).map((item) => {
                      const Component = STYLE_PREVIEW[item];
                      return (
                          <DauberStyleItem as={Component as any} key={item} />
                      );
                  })
                : Object.keys(COLOR_PREVIEW).map((color) => {
                      return (
                          <DauberSelectItem
                              key={color}
                              bgColor={COLOR_PREVIEW[color]}
                          />
                      );
                  })}
        </DauberSelectContainer>
    );
};
