import {
    FC,
    HTMLAttributes,
    ChangeEvent,
    KeyboardEvent,
    RefObject,
} from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ChatSendButton } from '../ChatSendButton';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type ChatTextFieldProps = HTMLAttributes<HTMLDivElement> & {
    message: string;
    focused: boolean;
    disabled: boolean;
    mentions: number;
    disabledButtons: boolean;
    placeholder: string;
    inputRef: RefObject<HTMLInputElement>;
    onEmoticon: () => void;
    onSend: () => void;
    onFocus: () => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onEnter: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const buttonDimension = css`
    width: 4.375rem;
    height: 4.375rem;
`;

const ChatTextFieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
`;

const TextField = styled.input<{ focused: boolean }>`
    font-family: Signika-Medium;
    font-size: 2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 100%;
    height: 5rem;
    border: none;
    border-radius: 2.5rem;

    &:focus {
        outline: none;
    }

    ${({ focused, theme, disabled }) => `
        margin-right: ${focused ? '6rem' : 0};
        padding: 1.25rem ${focused ? '5.25rem' : '10.125rem'}
        1.25rem 2.188rem;
        cursor: ${disabled ? 'not-allowed' : 'auto'};
        opacity: ${disabled ? 0.5 : 1};
        background-color: ${theme.color};
    `};
`;

const EmoticonWrapper = styled.div<{ focused: boolean; disabled: boolean }>`
    ${buttonDimension};

    position: absolute;
    right: ${({ focused }) => (focused ? '6.375rem' : '5.125rem')};

    ${({ disabled }) => `
        ${
            disabled
                ? `
            cursor: not-allowed;
            opacity: 0.5;
        `
                : `
        cursor: pointer;
        `
        }
    `};
`;

const EmoticonButton = styled.img<{ focused: boolean; disabled: boolean }>`
    ${buttonDimension};

    ${({ disabled }) => `
        ${
            disabled
                ? `
            pointer-events: none;
        `
                : ''
        }
    `};
`;

const ButtonMask = styled.div`
    ${buttonDimension};
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    border-radius: 50%;
    background-color: #000;
`;

export const ChatTextFieldMobile: FC<ChatTextFieldProps> = ({
    focused,
    message,
    disabled,
    mentions,
    inputRef,
    placeholder,
    disabledButtons,
    onEmoticon,
    onSend,
    onFocus,
    onChange,
    onEnter,
    ...props
}) => {
    const theme = useTheme();
    const { emoticon } = theme.images;

    const $disabled = disabled || disabledButtons;
    const $onEmoticon = useSoundHandler(onEmoticon, 'click');

    return (
        <ChatTextFieldContainer {...props}>
            <TextField
                type="text"
                value={message}
                focused={focused}
                disabled={disabled}
                ref={inputRef}
                placeholder={placeholder}
                onFocus={onFocus}
                onChange={onChange}
                onKeyDown={onEnter}
            />
            <EmoticonWrapper disabled={$disabled} focused={focused}>
                <EmoticonButton
                    src={emoticon}
                    alt="Emoticon Button Image"
                    focused={focused}
                    onClick={$onEmoticon}
                    disabled={$disabled}
                />
                {$disabled && <ButtonMask />}
            </EmoticonWrapper>
            <ChatSendButton
                disabled={$disabled}
                handleClick={onSend}
                mentions={mentions}
            />
        </ChatTextFieldContainer>
    );
};
