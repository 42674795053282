import { FC, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css, useTheme } from 'styled-components';
import { Notification } from '../Notification';

type ChatSendButtonProps = HTMLAttributes<HTMLDivElement> & {
    disabled: boolean;
    mentions: number;
    handleClick: () => void;
};

export const buttonDimension = css`
    width: ${isMobile ? '4.375rem' : '3.5rem'};
    height: ${isMobile ? '4.375rem' : '3.5rem'};
`;

export const ChatSendButtonConainer = styled.div<{
    disabled: boolean;
}>`
    ${buttonDimension};

    position: absolute;
    right: 0.375rem;
    border-radius: 50%;

    ${({ theme: { chatSendButton }, disabled }) => `
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        opacity: ${disabled ? 0.5 : 1};
        border: solid 0.094rem ${chatSendButton.border};
        background-image: linear-gradient(to bottom, ${
            chatSendButton.backgroundGradient.upper
        }, ${chatSendButton.backgroundGradient.lower} 99%);
        box-shadow: inset 0 0.25rem 0 0 ${chatSendButton.boxShadow.first},
        inset 0 -0.25rem 0 0 ${chatSendButton.boxShadow.second};
    `};
`;

const ButtonMask = styled.div`
    ${buttonDimension};
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    border-radius: 50%;
    background-color: #000;
`;

const Wrapper = styled.div`
    position: relative;
`;

export const SendButton = styled.img<{ disabled: boolean }>`
    width: ${isMobile ? '3.125rem' : '2.25rem'};
    height: ${isMobile ? '3.125rem' : '2.25rem'};

    margin: 0.625rem 0 0 0.375rem;

    ${({ disabled }) => `
        ${
            disabled
                ? `
            pointer-events: none;
        `
                : ''
        }
    `};
`;

export const ChatSendButton: FC<ChatSendButtonProps> = ({
    disabled,
    mentions,
    handleClick,
    ...props
}) => {
    const theme = useTheme();
    const { send } = theme.images;

    return (
        <ChatSendButtonConainer disabled={disabled} {...props}>
            <Wrapper>
                <SendButton
                    src={send}
                    alt="Send Button Image"
                    onClick={handleClick}
                    disabled={disabled}
                />
                {mentions > 0 && <Notification value={mentions} />}
                {disabled && <ButtonMask />}
            </Wrapper>
        </ChatSendButtonConainer>
    );
};
