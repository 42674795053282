import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FooterSG } from '../FooterSG';
import { FooterTitleSG } from '../FooterTitleSG';
import { CountdownTimer } from '../CountdownTimer';
import { BuyButton } from '../BuyButton';
import { MessageBubble } from '../MessageBubble';
import { GamePart, GamePartProps, GamePartDot } from '../GamePart';
import { useSoundHandler } from '../../hooks/use-sound-handler';

type NextGameSGProps = HTMLAttributes<HTMLDivElement> &
    GamePartProps & {
        countdownDate: Date;
        prize: string;
        mentions: number;
        isDisabled: boolean;
        showMessageBubble: boolean;
        handleClick: () => void;
        handleMentions: () => void;
    };

const NextGameSGContainer = styled(FooterSG)`
    justify-content: space-between;
    align-items: center;
`;

const TimerWrapper = styled(CountdownTimer)`
    font-size: 3.625rem;
`;

const BonusPartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 10rem;
    height: 4.75rem;
    padding: 0.5rem;
    border-radius: 0.781rem;

    ${({ theme: { nextGameSG } }) => `
        border: solid 0.081rem ${nextGameSG.border};
        background-color: ${nextGameSG.background};
        box-shadow: 0 0.125rem 0 0 ${nextGameSG.boxShadow.first},
            inset 0 0.188rem 0 0 ${nextGameSG.boxShadow.second};
    `};
`;

const BonusWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

const GamePartWrapper = styled(GamePart)`
    ${GamePartDot} {
        width: 0.938rem;
        height: 0.938rem;
    }
`;

const Moneybag = styled.img`
    width: 1.875rem;
    height: 1.875rem;
`;

const BonusPrize = styled.div`
    font-family: Signika-Bold;
    font-size: 1.625rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 80%;
    color: ${({ theme }) => theme.nextGameSG.color};
`;

export const NextGameSGPortrait: FC<NextGameSGProps> = ({
    countdownDate,
    isDisabled,
    gameParts,
    currentPart,
    prize,
    mentions,
    showMessageBubble,
    handleClick,
    handleMentions,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { moneybag } = theme.images;
    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <NextGameSGContainer showFooter={true} {...props}>
            <FooterTitleSG text={t('modalPurchase.nextGame')} />
            <TimerWrapper to={countdownDate} />
            <BonusPartContainer>
                <BonusWrapper>
                    <Moneybag src={moneybag} alt="Moneybag Image" />
                    <BonusPrize>{prize}</BonusPrize>
                </BonusWrapper>
                <GamePartWrapper
                    gameParts={gameParts}
                    currentPart={currentPart}
                />
            </BonusPartContainer>
            <BuyButton isDisabled={isDisabled} handleClick={onClick} />
            {showMessageBubble && (
                <MessageBubble
                    mentions={mentions}
                    handleClick={handleMentions}
                />
            )}
        </NextGameSGContainer>
    );
};
