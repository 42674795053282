import { FC, useState, useMemo, ChangeEvent, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CHAT_HEADER_HEIGHT_REM as CHAT_HEADER_HEIGHT_REM_MOBILE } from '../ChatHeader/mobile';
import { CHAT_HEADER_HEIGHT_REM as CHAT_HEADER_HEIGHT_REM_DESKTOP } from '../ChatHeader/desktop';
import { FOOTER_HEIGHT_REM } from '../Footer';
import { FOOTER_HEIGHT_REM as FOOTERSG_HEIGHT_REM } from '../FooterSG';
import { useSoundHandler } from '../../hooks/use-sound-handler';

const PADDING_TOP = isMobile
    ? CHAT_HEADER_HEIGHT_REM_MOBILE
    : CHAT_HEADER_HEIGHT_REM_DESKTOP;

type ChatUsersProps = HTMLAttributes<HTMLDivElement> & {
    size?: number;
    mods: string[];
    users: string[];
    onUsers: () => void;
};

const commonStyle = css`
    font-family: Roboto-Bold;

    ${isMobile
        ? `
        font-size: 1.875rem;
        line-height: 3.125rem;
    `
        : `
        font-size: 1.25rem;
        line-height: 2rem;
    `}
`;

const ChatUsersContainer = styled.div<{ size?: number }>`
    border-radius: 1rem;
    z-index: 10;
    padding: ${PADDING_TOP}rem 0 ${FOOTER_HEIGHT_REM}rem;

    ${({ size }) => `
        ${
            isMobile
                ? `
            position: fixed;
            top: 16.5rem;
            bottom: 0;
            width: 46.875rem;
            max-width: 100%;
            
        `
                : `
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            
            top: ${size}rem;
    `
        }
    `}

    background-color: ${(props) => props.theme.chatUsers.background.container};
`;

const TitleContainer = styled.div`
    font-family: Signika-SemiBold;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    display: flex;
    align-items: center;

    ${isMobile
        ? `
            font-size: 2.25rem;
            height: 7.5rem;
            padding: 2.313rem;
        `
        : `
            font-size: 1.25rem;
            height: 3.5rem;
            padding: 0 1.313rem;
    `}

    ${({ theme: { chatUsers } }) => `
        color: ${chatUsers.color.title};
        background-color: ${chatUsers.background.title};
    `};
`;

const TitleCloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    ${isMobile
        ? `
            width: 2.875rem;
            height: 2.875rem;
            margin-right: 1.875rem;
        `
        : `
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
    `}

    ${({ theme: { chatUsers } }) => `
        color: ${chatUsers.color.button};
        background-color: ${chatUsers.background.button};
    `};
`;

const ListContainer = styled.div`
    height: 100%;

    ${isMobile
        ? `
            padding: 2.875rem 1.188rem ${
                FOOTER_HEIGHT_REM + FOOTERSG_HEIGHT_REM + 0.375
            }rem 1.75rem;
        `
        : `
            padding: 0.875rem 0.375rem ${FOOTER_HEIGHT_REM - 1.5}rem 0.75rem;
    `}

    background-color: ${({ theme }) => theme.chatUsers.background.list};
`;

const ScrollContainer = styled.div`
    height: 100%;
    overflow-x: auto;

    ${({ theme: { chatUsers } }) => `
        &::-webkit-scrollbar-track {
            border-radius: 0.75rem;
            background-color: ${chatUsers.scroll.track};
            border-left: 0.438rem solid ${chatUsers.border.scroll};
            border-right: 0.438rem solid ${chatUsers.border.scroll};
        }

        &::-webkit-scrollbar {
            width: 1.625rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.75rem;
            background-color: ${chatUsers.scroll.thumb};
        }
    `};
`;

const FindTextField = styled.input`
    font-family: Signika-Medium;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 97.5%;

    border-radius: 2.5rem;
    border: solid 0.25rem ${({ theme }) => theme.chatUsers.border.textField};

    &:focus {
        outline: none;
    }

    ${isMobile
        ? `
            font-size: 2rem;
            height: 5rem;
            padding: 1rem 2.375rem;
        `
        : `
            font-size: 1.25rem;
            height: 3rem;
            padding: 0 1rem;
    `}
`;

const ModsUsersContainer = styled.div`
    margin: ${isMobile ? '2.313rem 0.563rem 0' : '0.5rem 0.563rem 0'};
`;

const ModsLabel = styled.div`
    ${commonStyle}

    color: ${({ theme }) => theme.chatUsers.color.label};
`;

const UsersLabel = styled.div`
    ${commonStyle}

    margin-top: ${isMobile ? '3rem' : '1rem'};
    color: ${({ theme }) => theme.chatUsers.color.label};
`;

const ModsItem = styled.div`
    ${commonStyle}

    font-family: Roboto;
    color: ${({ theme }) => theme.chatUsers.color.mods};
`;

const UsersItem = styled(ModsItem)`
    color: ${({ theme }) => theme.chatUsers.color.users};
`;

export const ChatUsers: FC<ChatUsersProps> = ({
    size,
    mods,
    users,
    onUsers,
    ...props
}) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState<string>('');

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const filteredUser = useMemo(
        () =>
            users.filter((user) =>
                user.toLowerCase().includes(search.toLowerCase())
            ),
        [users, search]
    );

    const filteredMods = useMemo(
        () =>
            mods.filter((mod) =>
                mod.toLowerCase().includes(search.toLowerCase())
            ),
        [mods, search]
    );

    const $onUsers = useSoundHandler(onUsers, 'click');

    return (
        <ChatUsersContainer {...props} size={size}>
            <TitleContainer>
                <TitleCloseButton onClick={$onUsers}>x</TitleCloseButton>
                {t('chatModal.usersInChat')}
            </TitleContainer>
            <ListContainer>
                <ScrollContainer>
                    <FindTextField
                        type="text"
                        placeholder="Find..."
                        value={search}
                        onChange={(e) => onSearch(e)}
                    />
                    <ModsUsersContainer>
                        <ModsLabel>{t('chatModal.moderators')}</ModsLabel>
                        {filteredMods.map((mod, i) => (
                            <ModsItem key={i}>{mod}</ModsItem>
                        ))}
                        <UsersLabel>{t('chatModal.users')}</UsersLabel>
                        {filteredUser.map((user, i) => (
                            <UsersItem key={i}>{user}</UsersItem>
                        ))}
                    </ModsUsersContainer>
                </ScrollContainer>
            </ListContainer>
        </ChatUsersContainer>
    );
};
