import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { FOOTER_HEIGHT_REM } from '../Footer';
import { FOOTER_HEIGHT_REM as FOOTERSG_HEIGHT_REM } from '../FooterSG';

const POSITION_BOTTOM = isMobile
    ? FOOTER_HEIGHT_REM + FOOTERSG_HEIGHT_REM
    : FOOTER_HEIGHT_REM;

type EmojiPickerProps = {
    show: boolean;
    onSelect: (e: EmojiSelectEvent) => void;
    onClickOutside: () => void;
};

export type EmojiSelectEvent = {
    native: string;
    shortcodes: string;
};

export const EmojiPickerContainer = styled.div`
    position: absolute;
    right: 0;
    z-index: 15;
    bottom: ${POSITION_BOTTOM}rem;
    border: 0.125rem solid ${({ theme }) => theme.emoji.border};
    border-radius: 0.938rem;
`;

export const EmojiPicker: FC<EmojiPickerProps> = ({
    show,
    onSelect,
    onClickOutside,
}) => {
    const theme = useTheme();
    const { customEmojiData } = theme;

    if (!show) {
        return null;
    }

    return (
        <EmojiPickerContainer>
            <Picker
                data={data}
                previewPosition="none"
                custom={customEmojiData}
                onEmojiSelect={onSelect}
                onClickOutside={onClickOutside}
                maxFrequentRows={2}
            />
        </EmojiPickerContainer>
    );
};
