import { ReactNode, useState, useEffect } from 'react';

export const useCalled = (
    autoDaub: boolean,
    isCalled: boolean,
    children?: ReactNode
) => {
    const [called, setCalled] = useState<boolean>(false);

    const handleClick = () => {
        if (autoDaub || children === 0) {
            return;
        }

        setCalled((called) => !called);
    };

    useEffect(() => {
        if (autoDaub) {
            setCalled(isCalled);
        }
    }, [autoDaub, isCalled]);

    const daubed = autoDaub ? isCalled : called;

    return {
        daubed,
        handleClick,
    };
};
