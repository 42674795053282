import { FC, useCallback } from 'react';
import { useHookstate } from '@hookstate/core';
import { GameCancelledModal as GameCancelledModalComponent } from '../../components/GameCancelledModal';
import { meta as metaState } from '../../stores/backend';

export const GameCancelledModal: FC = () => {
    const meta = useHookstate(metaState);

    const onClose = useCallback(() => {
        meta.modals.gameCancelled.set(false);
    }, [meta.modals.gameCancelled]);

    return (
        <GameCancelledModalComponent
            isOpen={meta.modals.gameCancelled.value}
            onClose={onClose}
        />
    );
};
