import { useHookstate } from '@hookstate/core';
import { BuyButton, BuyButtonProps } from '../../components/BuyButton';
import { useCountdown } from '../../hooks/use-countdown';
import { meta } from '../../stores/backend';

export const MobileBuyButton = (props: Omit<BuyButtonProps, 'isDisabled'>) => {
    const $meta = useHookstate(meta);
    const { seconds } = useCountdown(new Date($meta.timer.date.value));
    return <BuyButton {...props} isDisabled={seconds <= 20} />;
};
