import { FC, useState, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';

type SideGamesThumbnailProps = HTMLAttributes<HTMLImageElement> & {
    thumbnail: string;
};

const SideGamesListThumbnail = styled.img`
    border-radius: 1.25rem;
    box-shadow: 0 0.313rem 0 0 rgba(0, 0, 0, 0.3);

    ${isMobile
        ? `
            width: 20.5rem;
            height: 11.5rem;
        `
        : `
            width: 11rem;
            height: 7.125rem;
        `}
`;

export const SideGamesThumbnail: FC<SideGamesThumbnailProps> = ({
    thumbnail,
    ...props
}) => {
    const theme = useTheme();
    const { placeholder } = theme.images;

    const [error, setError] = useState<boolean>(false);

    const onError = () => {
        setError(true);
    };

    if (error) {
        return (
            <SideGamesListThumbnail
                src={placeholder}
                alt="Side Games Placeholder"
            />
        );
    }

    return (
        <SideGamesListThumbnail
            {...props}
            src={thumbnail}
            alt="Side Games Thumbnail"
            onError={onError}
        />
    );
};
