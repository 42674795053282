import { useMemo } from 'react';
import { State } from '@hookstate/core';
import { BingoWinnerMessage } from '../types/bingo-events/in/backend/winner-message';
import { Winners } from '../components/CongratulationsPanel';

export const useWinners = (
    winners: State<BingoWinnerMessage>,
    currentPart: number,
    formatCurrency: Function
): Winners[] => {
    return useMemo(
        () =>
            winners.winners.value
                .filter((winner) => winner.part === currentPart + 1)
                .map((winner) => ({
                    name: winner.userAlias,
                    amount: formatCurrency(winner.amount, winner.currency),
                })),
        [winners.winners.value, currentPart, formatCurrency]
    );
};
