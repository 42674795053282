import { chat, chatMeta } from '../../stores/chat';
import { meta } from '../../stores/backend';
import { ChatSocket } from '../../hooks/use-socket';
import { ChatPost } from '../../types/bingo-events/in/chat/post';

export const onPost = (socket: ChatSocket) => {
    const callback = (response: ChatPost) => {
        // find player id = reponse.userId
        const player = chat.players.value.find(
            (player) => player.id === response.userId
        );

        if (response.postLevel === 2) {
            meta.modals.msgCommon.set({ name: response.message, isOpen: true });
        } else if (response.postLevel === 3) {
            chat.messages.merge([
                {
                    ...response,
                    isChatMaster: player?.cm,
                    isSystemMsg: true,
                    bannedWord: true,
                },
            ]);
        } else {
            chat.messages.merge([
                {
                    ...response,
                    isChatMaster: player?.cm,
                    isSystemMsg: response.postLevel !== 1,
                },
            ]);

            if (
                (response.message.match(`@${chat.currentPlayer.name?.value}`) ||
                    response.pm) &&
                !chatMeta.chatToggle.value
            ) {
                chatMeta.mentions.set(chatMeta.mentions.value + 1);
            }
        }
    };

    socket.on('post:parlay', callback);

    return () => {
        socket.off('post:parlay', callback);
    };
};
