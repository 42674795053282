import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { CallBoard75 } from '../../components/CallBoard75';
import { CallBoard80 } from '../../components/CallBoard80';
import { CallBoard90 } from '../../components/CallBoard90';
import { useGameType } from '../../hooks/use-game-type';
import { calls, current } from '../../stores/backend';

const CALL_BOARD_COMPONENTS = {
    75: CallBoard75,
    80: CallBoard80,
    90: CallBoard90,
};

export const CallBoardPanel: FC = () => {
    const $current = useHookstate(current);
    const $calls = useHookstate(calls);

    const gameType = useGameType($current);
    const called = $calls.value;

    const CallBoard = CALL_BOARD_COMPONENTS[gameType];

    return <CallBoard called={called} />;
};
