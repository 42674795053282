import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';

export type CurrencyToggleProps = HTMLAttributes<HTMLButtonElement> & {
    currency: string;
};

export const CurrencyToggleContainer = styled.button`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 4.625rem;
    border-radius: 0.969rem;
    cursor: pointer;

    width: ${isMobile ? '11.25rem' : '8rem'};

    ${({ theme: { currencyToggle } }) => `
        border: solid 0.094rem ${currencyToggle.border};
        box-shadow:  inset 0 0.25rem 0 0 ${currencyToggle.boxShadow.first},
        inset 0 -0.25rem 0 0 ${currencyToggle.boxShadow.second};
        background-image: linear-gradient(to bottom, ${currencyToggle.backgroundGradient.upper},
            ${currencyToggle.backgroundGradient.lower} 99%);
    `};
`;

export const CurrencyToggleImage = styled.img`
    width: 2.25rem;
    height: 2.25rem;
`;

export const CurrencyToggleText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Signika-SemiBold;
    font-size: 2rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 5.438rem;
    height: 3rem;
    border-radius: 0.625rem;

    ${({ theme: { currencyToggle } }) => `
        color: ${currencyToggle.color};
        background-color: ${currencyToggle.background};
        box-shadow: inset 0 0 1.25rem 0 ${currencyToggle.textBoxShadow};
    `};
`;

export const CurrencyToggle: FC<CurrencyToggleProps> = ({
    currency,
    ...props
}) => {
    const theme = useTheme();
    const { convert } = theme.images;

    return (
        <CurrencyToggleContainer {...props}>
            <CurrencyToggleImage src={convert} alt="Convert Image" />
            <CurrencyToggleText>{currency}</CurrencyToggleText>
        </CurrencyToggleContainer>
    );
};
