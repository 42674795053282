import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type FooterTitleSGProps = HTMLAttributes<HTMLDivElement> & {
    text: string;
};

const FooterTitleSGContainer = styled.div`
    font-family: Signika-Bold;
    font-size: 2.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    ${({ theme: { footerTitle } }) => `
        color: ${footerTitle.color};
        text-shadow: 0 0.25rem 0 ${footerTitle.textShadow}, 0 -0.25rem 0 ${footerTitle.textShadow};
    `};
`;

export const FooterTitleSG: FC<FooterTitleSGProps> = ({ text, ...props }) => {
    return <FooterTitleSGContainer {...props}>{text}</FooterTitleSGContainer>;
};
