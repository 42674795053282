import B from './assets/images/emojis/B.png';
import I from './assets/images/emojis/I.png';
import N from './assets/images/emojis/N.png';
import G from './assets/images/emojis/G.png';
import O from './assets/images/emojis/O.png';
import Angry from './assets/images/emojis/Angry.png';
import BrokenHeart from './assets/images/emojis/BrokenHeart.png';
import cake from './assets/images/emojis/cake.png';
import clock from './assets/images/emojis/clock.png';
import Confused from './assets/images/emojis/Confused.png';
import cool from './assets/images/emojis/cool.png';
import crying from './assets/images/emojis/crying.png';
import devil from './assets/images/emojis/devil.png';
import Devious from './assets/images/emojis/Devious.png';
import Embarrased from './assets/images/emojis/Embarrased.png';
import flower from './assets/images/emojis/flower.png';
import Geek from './assets/images/emojis/Geek.png';
import Guilty from './assets/images/emojis/Guilty.png';
import Happy from './assets/images/emojis/Happy.png';
import HardLaugh from './assets/images/emojis/HardLaugh.png';
import heart from './assets/images/emojis/heart.png';
import InLove from './assets/images/emojis/InLove.png';
import Kiss from './assets/images/emojis/Kiss.png';
import Lunch from './assets/images/emojis/Lunch.png';
import moon from './assets/images/emojis/moon.png';
import PalmTree from './assets/images/emojis/PalmTree.png';
import present from './assets/images/emojis/present.png';
import Sarcastic from './assets/images/emojis/Sarcastic.png';
import Sick from './assets/images/emojis/Sick.png';
import star from './assets/images/emojis/star.png';
import sun from './assets/images/emojis/sun.png';
import Surprised from './assets/images/emojis/Surprised.png';
import Tongue from './assets/images/emojis/Tongue.png';
import VeryAngry from './assets/images/emojis/VeryAngry.png';
import VeryHappy from './assets/images/emojis/VeryHappy.png';
import Wink from './assets/images/emojis/Wink.png';
import Wondering from './assets/images/emojis/Wondering.png';
import Worried from './assets/images/emojis/Worried.png';

const Emojis = {
    B,
    I,
    N,
    G,
    O,
    Angry,
    BrokenHeart,
    cake,
    clock,
    Confused,
    cool,
    crying,
    devil,
    Devious,
    Embarrased,
    flower,
    Geek,
    Guilty,
    Happy,
    HardLaugh,
    heart,
    InLove,
    Kiss,
    Lunch,
    moon,
    PalmTree,
    present,
    Sarcastic,
    Sick,
    star,
    sun,
    Surprised,
    Tongue,
    VeryAngry,
    VeryHappy,
    Wink,
    Wondering,
    Worried,
};

export default Emojis;
