import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type ModalTitleProps = HTMLAttributes<HTMLDivElement> & {
    title: string;
};

export const ModalTitleContainer = styled.div`
    font-family: Signika-Bold;
    font-size: 3.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.75rem;
    letter-spacing: normal;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: ${({
        theme: {
            modalTitle: { backgroundGradient },
        },
    }) =>
        `linear-gradient(to bottom, ${backgroundGradient.upper}, ${backgroundGradient.lower})`};
`;

export const ModalTitle: FC<ModalTitleProps> = ({ title, ...props }) => {
    return <ModalTitleContainer {...props}>{title}</ModalTitleContainer>;
};
