import { State } from '@hookstate/core';
import { GameState } from '../stores/backend';
import { CardItem } from './use-cards';

export type StripSortedItem = {
    stripId: string;
    cards: Omit<CardItem, 'togo'>[];
};

export const usePickCards = (
    game: State<GameState>,
    cards: Record<string, string[]>
) => {
    const $cards = Object.keys(cards).map((id) => {
        const cardNumbers = (cards[id] || []).map(Number);
        return {
            cardId: id,
            cardNumbers,
        };
    });

    if (game.game_strip_active.value) {
        const indices: Record<string, number> = {};
        const list: StripSortedItem[] = [];

        for (let index = 0; index < $cards.length; index++) {
            const card = $cards[index];
            const [stripId, cardId] = card.cardId.split('-');
            const newCard: Omit<CardItem, 'togo'> = {
                cardId,
                cardNumbers: card.cardNumbers,
            };

            const groupIndex = indices[stripId];

            if (typeof groupIndex === 'undefined') {
                indices[stripId] = list.length;
                list.push({
                    stripId,
                    cards: [newCard],
                });
            } else {
                list[groupIndex].cards.push(newCard);
            }
        }

        return {
            type: 'stripped' as const,
            list,
        };
    }

    return {
        type: 'regular' as const,
        list: $cards,
    };
};
