import { meta } from './meta';
import { next, isMaxCardsBought } from './game';
import { createTimer } from '../../lib/create-timer';

export const createTimeRemainingLoop = (timeRemaining: number) => {
    const date = new Date().getTime() + timeRemaining;

    const { id, end } = createTimer(date, (seconds) => {
        if (!meta.enabled.buying.value && end) {
            end();
            return;
        }

        if (seconds <= 20 || isMaxCardsBought(next)) {
            meta.enabled.buying.set(false);
        }
    });

    meta.timer.merge({
        id,
        date,
    });

    return id;
};
