import { State } from '@hookstate/core';

import { GameState } from '../stores/backend';

export const useBogo = (game: State<GameState>) => {
    const { buy = 1, get = 0 } = game.purchase_bogo?.value || {};

    return {
        purchaseBuy: buy,
        purchaseGet: get,
    };
};
