import { FC, useState, useEffect, ReactNode } from 'react';
import { useHookstate } from '@hookstate/core';
import styled from 'styled-components';
import { Arrow } from '../../components/Arrow';
import './index.css';
import { Header } from '../Header';
import { CallBoardPanel } from '../CallBoardPanel';
import { BallCallsPanel } from '../BallCallsPanel';
import { CurrentGamePanel } from '../CurrentGamePanel';
import { GameStatePanel } from '../GameStatePanel';
import { NextGamePanel } from '../NextGamePanel';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { current, meta } from '../../stores/backend';
import { Togo } from '../Togo';

interface Slide {
    id: number;
    content: ReactNode;
}

export const PositionedArrow = styled(Arrow)`
    position: absolute;
    top: 6.5rem;
    z-index: 1;

    ${(props) =>
        props.orientation === 'right' ? 'right: 0.5em' : 'left: 0.5rem'};
`;

export const PaddedContainer = styled.div`
    padding: 5% 0;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 15.5rem;
`;

const slidesData = [
    {
        id: 1,
        content: (
            <PaddedContainer>
                <Header />
            </PaddedContainer>
        ),
    },
    {
        id: 2,
        content: (
            <FlexContainer>
                <CallBoardPanel />
                <Togo />
            </FlexContainer>
        ),
    },
    {
        id: 3,
        content: (
            <FlexContainer>
                <BallCallsPanel />
                <Togo />
            </FlexContainer>
        ),
    },
    {
        id: 4,
        content: (
            <FlexContainer>
                <CurrentGamePanel />
                <Togo />
            </FlexContainer>
        ),
    },
    {
        id: 5,
        content: (
            <PaddedContainer>
                <GameStatePanel />
            </PaddedContainer>
        ),
    },
    {
        id: 6,
        content: (
            <PaddedContainer>
                <NextGamePanel />
            </PaddedContainer>
        ),
    },
];

export const GamePanelsSlider: FC = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [currentSlide, setCurrentSlide] = useState<Slide[]>(slidesData);

    const $current = useHookstate(current);
    const $meta = useHookstate(meta);
    const bought = useBoughtTickets($current);

    const gameMode = $current.game_mode.value;

    const nextSlide = () => {
        setCurrentSlideIndex(
            (prevIndex) => (prevIndex + 1) % currentSlide.length
        );
    };

    const prevSlide = () => {
        setCurrentSlideIndex((prevIndex) =>
            prevIndex === 0 ? currentSlide.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        let slide: Slide[] = slidesData;

        if (
            gameMode === 'GAME_OVER' ||
            (gameMode === 'GAME_MODE' && bought === 0) ||
            gameMode === 'PURCHASE_MODE' ||
            $meta.panels.partWinner.value
        ) {
            slide = slide.filter((slide) => slide.id !== 2 && slide.id !== 3);
        }

        if (gameMode !== 'GAME_MODE' || bought === 0) {
            slide = slide.filter((slide) => slide.id !== 4);
        }

        if (gameMode === 'GAME_MODE' && bought > 0) {
            slide = slide.filter((slide) => slide.id !== 5);
        }

        setCurrentSlide(slide);
    }, [gameMode, bought, $meta.panels.partWinner.value]);

    return (
        <div className="slider-container">
            <PositionedArrow handleClick={prevSlide} orientation="left" />
            <div className="slider">
                <div
                    className="slides"
                    style={{
                        width: '100%', // Set the width using the calculated value
                        transform: `translateX(-${currentSlideIndex * 100}%)`,
                        transition: 'transform 0.3s ease-in-out',
                    }}
                >
                    {currentSlide.map((slide) => (
                        <div key={slide.id} className="slide">
                            {slide.content}
                        </div>
                    ))}
                </div>
            </div>
            <PositionedArrow handleClick={nextSlide} orientation="right" />
        </div>
    );
};
