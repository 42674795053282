import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useCountdown } from '../../hooks/use-countdown';

type CountdownModalProps = HTMLAttributes<HTMLDivElement> & {
    to: Date;
};

const CountdownModalOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
`;

export const CountdownModalContainer = styled.div`
    position: relative;

    ${isMobile
        ? `
        margin-top: 20rem;
    `
        : `
        margin-top: 27rem;
        margin-left: 23.5rem;
    `};
`;

export const CountdownModalImage = styled.img`
    ${isMobile
        ? `
        width: 28.438rem;
        height: 31.25rem;
    `
        : `
        width: 24.438rem;
        height: 27.25rem;
    `};
`;

export const CountdownModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    left: 0;
    top: 0;
    color: ${(props) => props.theme.countdownModal.color};

    ${isMobile
        ? `
        width: 28.438rem;
        height: 31.25rem;
    `
        : `
        width: 24.438rem;
        height: 27.25rem;
    `};
`;

export const CountdownModalSeconds = styled.div`
    line-height: 0.68;
    margin: 0.938rem;
    font-size: ${isMobile ? '16rem' : '13rem'};
`;

export const CountdownModalText = styled.div`
    width: 14.438rem;
    line-height: 1;
    font-size: ${isMobile ? '2.031rem' : '1.75rem'};
`;

export const CountdownModal: FC<CountdownModalProps> = ({ to, ...props }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const { countdownTimer } = theme.images;
    const { seconds } = useCountdown(to);

    if (seconds === 0) {
        return null;
    }

    return (
        <CountdownModalOverlay>
            <CountdownModalContainer {...props}>
                <CountdownModalImage src={countdownTimer} />
                <CountdownModalContent>
                    <CountdownModalSeconds>{seconds}</CountdownModalSeconds>
                    <CountdownModalText>
                        {t('startClock.gameBegin')}
                    </CountdownModalText>
                </CountdownModalContent>
            </CountdownModalContainer>
        </CountdownModalOverlay>
    );
};
