import { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';

/**
 * The zepplin spec uses 750px width as the base of the design.
 * To accommodate proper CSS rem calculations, we had to use such a width with the
 * 16px base font-size and thew view ports width for all font-sizes under 750px width resolution.
 */

const baseWidth = isMobile ? '750' : '1400';
const fontSize = isMobile ? '16' : '14';

const GlobalStyle = createGlobalStyle`
    :root {
        --baseWidth: ${baseWidth};
        --fontSize: ${fontSize};
    }

	html {
        color: ${({ theme }) => theme.color};
        font-size: ${fontSize}px;
        overflow: hidden;
		background: ${({ theme: { bodyBackground } }) =>
            `linear-gradient(${bodyBackground.gradientTop}, ${bodyBackground.gradientBottom}) no-repeat`};
    }

    html, body, #root {
        height: 100%;
    }

    #root {
        overflow-y: hidden;
        overflow-x: hidden;
		background: ${({ theme: { bodyBackground } }) =>
            `linear-gradient(${bodyBackground.gradientTop}, ${bodyBackground.gradientBottom}) no-repeat`};
    }

    @media (max-width: ${baseWidth}px) {
        html {
            font-size: calc(100vw * var(--fontSize) / var(--baseWidth));
        }
    }

    .ReactModal__Content__Message--after-open-override {
        top: 30vh !important;
        outline: none !important;
    }

    .ReactModal__Content--after-open-override {
        padding: 2.188rem !important;
        outline: none !important;
        z-index: 0 !important;
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
    }
    
    .ReactModal__Overlay--before-open {
        opacity: 0;
    }

    .emoji-mart-scroll {
        width: 318.5px;
    }

`;

export default GlobalStyle;
