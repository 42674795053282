import { BackendSocket } from '../../hooks/use-socket';
import { account, meta, next } from '../../stores/backend';
import { InBuyCards } from '../../types/bingo-events/in/backend/buy-cards';

export const onBuyCards = (socket: BackendSocket) => {
    const callback = (response: InBuyCards) => {
        if (response.success) {
            const { data } = response;
            account.set({
                wagered: data.wagered,
                player_cash: data.player_cash,
                player_bonus: data.player_bonus,
            });
            next.merge({
                player_cards: data.cards,
                game_free_cards: data.totalFreeCards,
            });
        } else {
            meta.modals.errorBuy.merge({
                name: response.msg,
                isOpen: true,
            });
        }
    };

    socket.on('bingo:buyCards', callback);

    return () => {
        socket.off('bingo:buyCards', callback);
    };
};
