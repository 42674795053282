import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';

type SpecialBannerProps = HTMLAttributes<HTMLDivElement> & {
    text?: string;
};

const SpecialBannerContainer = styled.div`
    font-family: Signika-Bold;
    font-size: 1.625rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    position: relative;
    width: max-content;
    height: 3.75rem;
    margin-left: 1.438rem;
    padding: 0.813rem 1.063rem 0.813rem 2.563rem;
    border-radius: 0.375rem;

    ${({ theme: { specialBanner } }) => `
        color: ${specialBanner.color};
        border: solid 0.063rem ${specialBanner.border};
        background-color: ${specialBanner.background};
        box-shadow: 0 0.25rem 0 0 ${specialBanner.boxShadow.first},
        inset 0 0.25rem 0 0 ${specialBanner.boxShadow.second};
    `};
`;

const SpecialBannerStar = styled.img`
    position: absolute;
    top: 0.063rem;
    left: -1.438rem;
    width: 3.625rem;
    height: 3.625rem;
`;

export const SpecialBanner: FC<SpecialBannerProps> = ({ text, ...props }) => {
    const theme = useTheme();
    const { starImage2 } = theme.images;

    return (
        <SpecialBannerContainer {...props}>
            <SpecialBannerStar src={starImage2} alt="Star Image" />
            {text}
        </SpecialBannerContainer>
    );
};
