import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export const CARD_COLORS = {
    default: css`
        ${({ theme: { commonCard } }) => `
            box-shadow: 0 0.313rem 0 0 ${commonCard.boxShadow.first.default},
                0 0.625rem 0 0 ${commonCard.boxShadow.second},
                inset 0 0.188rem 0 0 ${commonCard.boxShadow.third.default};
            background-color: ${commonCard.background.default}
        `};
    `,
    winner: css`
        ${({ theme: { commonCard } }) => `
            box-shadow: 0 0.313rem 0 0 ${commonCard.boxShadow.first.winner},
                0 0.625rem 0 0 ${commonCard.boxShadow.second},
                inset 0 0.188rem 0 0 ${commonCard.boxShadow.third.winner};
            background-color: ${commonCard.background.winner};
        `};
    `,
    oneTG: css`
        box-shadow: 0 0.313rem 0 0
                ${(props) => props.theme.commonCard.boxShadow.first.oneTG},
            0 0.625rem 0 0 ${(props) => props.theme.commonCard.boxShadow.second},
            inset 0 0.188rem 0 0
                ${(props) => props.theme.commonCard.boxShadow.third.oneTG};
        background-color: ${(props) => props.theme.commonCard.background.oneTG};
    `,
    twoTG: css`
        box-shadow: 0 0.313rem 0 0
                ${(props) => props.theme.commonCard.boxShadow.first.twoTG},
            0 0.625rem 0 0 ${(props) => props.theme.commonCard.boxShadow.second},
            inset 0 0.188rem 0 0
                ${(props) => props.theme.commonCard.boxShadow.third.twoTG};
        background-color: ${(props) => props.theme.commonCard.background.twoTG};
    `,
    threeTG: css`
        box-shadow: 0 0.313rem 0 0
                ${(props) => props.theme.commonCard.boxShadow.first.threeTG},
            0 0.625rem 0 0 ${(props) => props.theme.commonCard.boxShadow.second},
            inset 0 0.188rem 0 0
                ${(props) => props.theme.commonCard.boxShadow.third.threeTG};
        background-color: ${(props) =>
            props.theme.commonCard.background.threeTG};
    `,
};

export type CommonCardContainerProps = {
    cardType: keyof typeof CARD_COLORS;
};

export type CommonCardProps = HTMLAttributes<HTMLDivElement> &
    CommonCardContainerProps;

export const CommonCardContainer = styled.div<CommonCardContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20.75rem;
    height: 23.125rem;
    padding: 0.938rem 0.5rem 0.625rem;
    border-radius: 1.213rem;

    ${({ cardType }) => CARD_COLORS[cardType]};
`;

export const CommonCard: FC<CommonCardProps> = ({
    cardType,
    children,
    ...props
}) => {
    return (
        <CommonCardContainer cardType={cardType} {...props}>
            {children}
        </CommonCardContainer>
    );
};
