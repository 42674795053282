import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { createTiles } from '@parlaygames/bingo-utility';
import { CommonPanel } from '../CommonPanel';
import { CallBoardNumber } from '../CallBoardNumber';
import { CallBoardText, BingoLetter } from '../CallBoardText';

export type CallBoard75Props = HTMLAttributes<HTMLDivElement> & {
    called: number[];
};

export const CallBoard75Container = styled(CommonPanel)`
    box-shadow: none;
    background-image: none;
    padding: 0 0.313rem 0 0;
    overflow: hidden;
    background-color: ${({ theme }) => theme.callBoard.board75.background};
`;

export const SquareContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CallBoardNumberContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-basis: 93.5%;
`;

export const TILES = createTiles(1, 75, 15, false);

export const CallBoard75: FC<CallBoard75Props> = ({ called, ...props }) => {
    const BINGO = 'BINGO';

    return (
        <CallBoard75Container type="secondary" {...props}>
            {TILES.map((tiles, index) => {
                const letter = BINGO[index] as BingoLetter;
                return (
                    <SquareContainer key={index}>
                        <CallBoardText letter={letter} />
                        <CallBoardNumberContainer>
                            {tiles.map((tile) => (
                                <CallBoardNumber
                                    number={tile}
                                    isCalled={called.includes(tile)}
                                    key={tile}
                                >
                                    {tile}
                                </CallBoardNumber>
                            ))}
                        </CallBoardNumberContainer>
                    </SquareContainer>
                );
            })}
        </CallBoard75Container>
    );
};
