import { FC } from 'react';
import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { MessageModal as MessageModalComponent } from '../../components/MessageModal';
import { meta, closeModal } from '../../stores/backend';

const onClose = () => closeModal('msgCommon');

export const MessageModal: FC = () => {
    const { t } = useTranslation();

    const $meta = useState(meta);

    const message = t($meta.modals.msgCommon.name.value) || t('error.general');

    return (
        <MessageModalComponent
            onClose={onClose}
            message={message}
            isOpen={$meta.modals.msgCommon.isOpen.value}
        />
    );
};
