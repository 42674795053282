import { FC, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const FOOTER_HEIGHT_REM = isMobile ? 7.5 : 5.5;
export const PADDING_VERTICAL_REM = isMobile ? 1.25 : 0;
export const PADDING_HORIZONTAL_REM = isMobile ? 1.875 : 0.5;

export type FooterProps = HTMLAttributes<HTMLDivElement> & {
    pathname: string;
};

const FooterContainer = styled.div<{
    pathname: string;
}>`
    display: flex;
    justify-content: flex-end;
    width: 46.875rem;
    max-width: 100%;
    position: fixed;

    height: ${FOOTER_HEIGHT_REM}rem;
    padding: ${PADDING_VERTICAL_REM}rem ${PADDING_HORIZONTAL_REM}rem;

    ${({ pathname, theme: { footer } }) => `
        bottom: ${pathname === '/side' ? '6.25rem' : 0};
        border: solid 0.094rem ${footer.border};
        box-shadow: inset 0 0.25rem 0 0 ${footer.boxShadow.first},
            inset 0 -0.25rem 0 0 ${footer.boxShadow.second};
        background-image: linear-gradient(to bottom, ${
            footer.backgroundGradient.upper
        },
            ${footer.backgroundGradient.lower} 99%);
    `};
`;

export const Footer: FC<FooterProps> = (props) => (
    <FooterContainer {...props} />
);
