import A from './assets/images/daubers/A.svg';
import B from './assets/images/daubers/B.svg';
import C from './assets/images/daubers/C.svg';
import D from './assets/images/daubers/D.svg';
import E from './assets/images/daubers/E.svg';
import F from './assets/images/daubers/F.svg';
import G from './assets/images/daubers/G.svg';
import H from './assets/images/daubers/H.svg';
import I from './assets/images/daubers/I.svg';
import J from './assets/images/daubers/J.svg';
import K from './assets/images/daubers/K.svg';
import L from './assets/images/daubers/L.svg';
import M from './assets/images/daubers/M.svg';
import N from './assets/images/daubers/N.svg';
import O from './assets/images/daubers/O.svg';
import P from './assets/images/daubers/P.svg';
import Q from './assets/images/daubers/Q.svg';
import R from './assets/images/daubers/R.svg';
import S from './assets/images/daubers/S.svg';
import T from './assets/images/daubers/T.svg';

const DauberStyles = {
    A,
    B,
    C,
    D,
    E,
    F,
    G,
    H,
    I,
    J,
    K,
    L,
    M,
    N,
    O,
    P,
    Q,
    R,
    S,
    T,
};

export default DauberStyles;
