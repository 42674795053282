import { FC, useCallback, useMemo } from 'react';
import { useHookstate } from '@hookstate/core';
import { useMobileOrientation } from 'react-device-detect';
import { FooterSG } from '../../components/FooterSG';
import { NextGameSGPortrait } from '../../components/NextGameSG/portrait';
import { NextGameSGLandscape } from '../../components/NextGameSG/landscape';
import { Part } from '../../components/GamePart';
import { useGamePart } from '../../hooks/use-game-part';
import { useCountdown } from '../../hooks/use-countdown';
import { useCycleGameParts } from '../../hooks/use-cycle-game-parts';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useOpenChat } from '../../hooks/use-open-chat';
import { useChatPayload } from '../../hooks/use-chat';
import {
    meta as metaState,
    current as currentState,
    next as nextState,
} from '../../stores/backend/';
import {
    chat as chatState,
    chatMeta as chatMetaState,
} from '../../stores/chat';

export type ToggleFooterProps = {
    onToggleFooter: () => void;
};

export const NextGameSG: FC<ToggleFooterProps> = ({ onToggleFooter }) => {
    const meta = useHookstate(metaState);
    const next = useHookstate(nextState);
    const current = useHookstate(currentState);
    const $chat = useHookstate(chatState);
    const $chatMeta = useHookstate(chatMetaState);

    const gameCurrency = next.game_currency.value;

    const formatCurrency = useFormatCurrency(next, meta);

    const bought = useBoughtTickets(current);
    const { allGameParts } = useGamePart(next, meta);
    const { seconds } = useCountdown(new Date(meta.timer.date.value));
    const { chatPayload } = useChatPayload();

    const size = useMemo(
        () => Object.keys(allGameParts).length,
        [allGameParts]
    );

    const index = useCycleGameParts(size);

    const date = useMemo(
        () => new Date(meta.timer.date.value),
        [meta.timer.date.value]
    );

    const onBuy = useCallback(() => {
        meta.modals.buy.set(true);
    }, [meta.modals.buy]);

    const onMentions = useOpenChat($chat, $chatMeta, chatPayload);

    const orientation = useMobileOrientation();

    const Component = orientation.isPortrait
        ? NextGameSGPortrait
        : NextGameSGLandscape;

    if (
        (current.game_mode.value !== 'GAME_MODE' || bought === 0) &&
        seconds > 0
    ) {
        return (
            <Component
                showFooter={meta.footers.sideGames.value}
                isDisabled={!meta.enabled.buying.value}
                countdownDate={date}
                gameParts={size as Part}
                currentPart={(index + 1) as Part}
                mentions={$chatMeta.mentions.value}
                showMessageBubble={
                    !!$chat.rooms.value.length && orientation.isPortrait
                }
                prize={formatCurrency(
                    allGameParts[index]?.prize || 0,
                    gameCurrency
                )}
                handleClick={onBuy}
                handleMentions={onMentions}
                onToggleFooter={onToggleFooter}
            />
        );
    }

    return <FooterSG showFooter={true} />;
};
