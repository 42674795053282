import { useHookstate } from '@hookstate/core';
import { useEffect, useState } from 'react';
import { screenConfig as screenConfigState } from '../stores/backend';
import defaultTheme from '../themes/default';

export const useAppTheme = () => {
    const [theme, setTheme] = useState(defaultTheme);

    const { screenConfig } = useHookstate(screenConfigState);

    const skin = screenConfig.room.skin.value.toLowerCase();

    useEffect(() => {
        if (!skin) {
            return;
        }

        const fetchCssVendorTheme = async (): Promise<string | null> => {
            try {
                const response = await fetch(`/vendor/${skin}.css`);
                if (response.ok) {
                    const cssText = await response.text();
                    if (!cssText.includes('<html')) {
                        return cssText;
                    }
                }
            } catch (e) {}

            return null;
        };

        const fetchVendorTheme = async (): Promise<
            typeof defaultTheme | null
        > => {
            try {
                const response = await fetch(`/vendors/${skin}.json`);
                if (response.ok) {
                    const data = await response.json();
                    return data as typeof defaultTheme;
                }
            } catch (e) {}

            return null;
        };

        const getLocalTheme = () =>
            import(`../themes/${skin}.ts`)
                .then(
                    (value: { default: typeof defaultTheme }) => value.default
                )
                .catch(() => null);

        Promise.all([
            fetchCssVendorTheme(),
            fetchVendorTheme(),
            getLocalTheme(),
        ]).then(([cssTheme, vendorTheme, localTheme]) => {
            if (cssTheme) {
                const style = document.createElement('style');
                style.id = 'app-vendor-theme';
                style.innerText = cssTheme;
                document.head.appendChild(style);
            } else {
                const newTheme = vendorTheme || localTheme;
                if (newTheme) {
                    setTheme({ ...defaultTheme, ...newTheme });
                }
            }
        });
    }, [skin]);

    return theme;
};
