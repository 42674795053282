import { Players } from '../types/bingo-events/in/chat/join';

export const useSortUsers = (users: Players[]) => {
    const sortedUsers = users.slice().sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });

    return sortedUsers;
};
