import { FC, useCallback, useEffect, useState } from 'react';
import { AppProps as BingoMarketingModalProps } from '@parlaygames/bingomarketingmodal';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { HeaderMobile } from '../../components/Header/mobile';
import { HeaderDesktop } from '../../components/Header/desktop';
import { useAccount } from '../../hooks/use-account';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { getQuery } from '../../lib/query';
import {
    account,
    current,
    meta,
    openModal,
    getBalance,
    screenConfig as screenConfigState,
} from '../../stores/backend';

export const onToggleCurrency = () => {
    meta.currency.set((currency) =>
        currency === current.player_currency.value
            ? current.game_currency.value
            : current.player_currency.value
    );
};

const onSettingsOpen = () => {
    openModal({ key: 'settings' });
};

export const Header: FC = () => {
    const [isMax, setIsMax] = useState<boolean>(false);

    const { SESSIONID, ROOMID } = getQuery();

    const $current = useHookstate(current);
    const $meta = useHookstate(meta);
    const $account = useHookstate(account);
    const { screenConfig, sideGames } = useHookstate(screenConfigState);

    const { cash, bonus, totalAccount } = useAccount($current, $meta, $account);
    const formatCurrency = useFormatCurrency($current, $meta);

    const { spaceId, environmentId, accessToken } =
        screenConfig.site.data.contentful;

    const hideCurrencyToggle =
        current.game_currency.value === current.player_currency.value;

    const isAutoOpen = useCallback(() => {
        return (
            !!spaceId?.value && !!environmentId?.value && !!accessToken?.value
        );
    }, [spaceId, environmentId, accessToken]);

    const getAccountStatus = () =>
        Promise.resolve({
            cash: formatCurrency($account.player_cash.value),
            bonus: formatCurrency($account.player_bonus.value),
            balance: formatCurrency(getBalance()),
        });

    const marketingModalConfig = {
        getAccountStatus,
        contentfulSpace: spaceId?.value || '',
        contentfulEnvironment: environmentId?.value || '',
        contentfulAccessToken: accessToken?.value || '',
        jSessionToken: SESSIONID,
        siteId: screenConfig.site.siteCode.value || '',
        roomId: ROOMID,
    } as BingoMarketingModalProps;

    const onMarketingModalToggle = useCallback(() => {
        $meta.modals.marketing.set((value) => !value);
    }, [$meta.modals.marketing]);

    const onToggleScreen = () => {
        if (isMax) {
            document.exitFullscreen();
            setIsMax(false);

            return;
        }

        const element = document.body;

        element?.requestFullscreen();
        setIsMax(true);
    };

    useEffect(() => {
        if (
            (!$current.game_sid.value || !$meta.loaded.screenConfig.value) &&
            isAutoOpen() &&
            !$meta.modals.marketing.value
        ) {
            $meta.modals.marketing.set(true);
        }
    }, [
        $current.game_sid.value,
        $meta.loaded.screenConfig.value,
        $meta.modals.marketing,
        isAutoOpen,
    ]);

    if (!$current.game_sid.value || !$meta.loaded.screenConfig.value) {
        return null;
    }

    const Component = isMobile ? HeaderMobile : HeaderDesktop;

    return (
        <Component
            isMax={isMax}
            cash={cash}
            bonus={bonus}
            total={totalAccount}
            currency={$meta.currency.value}
            username={$current.player_alias.value}
            gameId={$current.game_sid.value}
            hideSideGames={sideGames.value.length === 0}
            hideCurrencyToggle={hideCurrencyToggle}
            marketingModalConfig={marketingModalConfig}
            isOpen={$meta.modals.marketing.value}
            onSettingsOpen={onSettingsOpen}
            onMarketingModalToggle={onMarketingModalToggle}
            onToggleCurrency={onToggleCurrency}
            onToggleScreen={onToggleScreen}
        />
    );
};
