import { FC, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useHookstate } from '@hookstate/core';
import styled from 'styled-components';
import { CongratulationsPanel } from '../../components/CongratulationsPanel';
import { GoodluckPanel } from '../../components/GoodluckPanel';
import { SalesClosedModal } from '../../components/SalesClosedModal';
import { Part } from '../../components/GamePart';
import { CountdownModal } from '../CountDownModal';
import { TicketPurchasedPanel } from '../TicketPurchasedPanel';
import { useCountdown } from '../../hooks/use-countdown';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useWinners } from '../../hooks/use-winners';
import { useHidePartWinners } from '../../hooks/use-hide-part-winners';
import { current, meta, winners as winnersState } from '../../stores/backend';

export const GameStatePanelContainer = styled.div`
    width: 100%;
    margin-bottom: ${isMobile ? '1rem' : '0.5rem'};
`;

export const GameStatePanel: FC = () => {
    const $current = useHookstate(current);
    const $winners = useHookstate(winnersState);
    const $meta = useHookstate(meta);

    const bought = useBoughtTickets($current);

    const formatCurrency = useFormatCurrency($current, $meta);

    const { seconds } = useCountdown(new Date($meta.timer.date.value));

    const gamePartDelay = $current.game_partdelay.value;
    const currentPart = $current.current_part.value || 0;

    const winners = useWinners($winners, currentPart, formatCurrency);

    const isPartWinnerPreviewVisible = useHidePartWinners(
        winners,
        gamePartDelay,
        seconds
    );

    const parts = useMemo(
        () => Object.keys($current.game_parts.value).length,
        [$current.game_parts]
    );

    if ($current.game_mode.value !== 'GAME_MODE' || bought === 0) {
        if (seconds > 10) {
            return (
                <GameStatePanelContainer>
                    <TicketPurchasedPanel />
                    {seconds <= 20 && <SalesClosedModal />}
                </GameStatePanelContainer>
            );
        }

        if (seconds > 0) {
            return (
                <GameStatePanelContainer>
                    <GoodluckPanel />
                    <CountdownModal />
                </GameStatePanelContainer>
            );
        }
    }

    if (
        isPartWinnerPreviewVisible &&
        currentPart + 1 < parts &&
        winners.length > 0
    ) {
        $meta.panels.partWinner.set(true);
        return (
            <GameStatePanelContainer>
                <CongratulationsPanel
                    winners={winners}
                    part={(currentPart + 1) as Part}
                    gameParts={parts as Part}
                />
            </GameStatePanelContainer>
        );
    }

    $meta.panels.partWinner.set(false);
    return null;
};
