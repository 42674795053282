import { createState } from '../../lib/create-state';
import { meta } from './meta';

type RecentWinnerState = Array<{
    name: string;
    gameSessionId: number;
    totalPrize: number;
    jackpotPrize: number;
    currency: string;
    gameContinued: boolean;
    parts: Array<{
        name: string;
        winners: Array<{
            alias: string;
            prize: number;
            call: number;
            callNumber: number;
        }>;
        extraWinners: Array<{
            alias: string;
            points: number;
            call: number;
            callNumber: number;
        }>;
        consolationWinners?: Array<{
            alias: string;
            prize: number;
            call: number;
        }>;
    }>;
}>;

class Error {
    constructor() {
        this.timestamp = '';
        this.key = '';
        this.message = '';
    }

    timestamp: string;
    key: string;
    message: string;
}

export const DEFAULT_RECENT_WINNER_STATE: RecentWinnerState = [
    {
        name: '',
        gameSessionId: 0,
        totalPrize: 0,
        jackpotPrize: 0,
        currency: '',
        gameContinued: false,
        parts: [
            {
                name: '',
                winners: [{ alias: '', prize: 0, call: 0, callNumber: 0 }],
                extraWinners: [
                    {
                        alias: '',
                        points: 0,
                        call: 0,
                        callNumber: 0,
                    },
                ],
                consolationWinners: [{ alias: '', prize: 0, call: 0 }],
            },
        ],
    },
];

export const [recentWinners, resetRecentWinners] = createState(
    DEFAULT_RECENT_WINNER_STATE
);

export const getRecentWinners = async (roomNumber: number, token: string) => {
    meta.loaders.recWinners.set(true);

    try {
        const response = await fetch(
            `/site-api/v2/bingo/rooms/${roomNumber}/recentWinners`,
            {
                redirect: 'follow',
                headers: {
                    'x-session-token': token,
                },
            }
        );

        if (response.ok) {
            const data = await response.json();
            recentWinners.set(data);
            meta.loaded.recWinners.set(true);
        }
    } catch (error: any) {
        if (error instanceof Error) {
            meta.modals.msgCommon.set({ name: error.key, isOpen: true });
        }
    } finally {
        meta.loaders.recWinners.set(false);
    }
};
