import { createState } from '../../lib/create-state';
import { ChatJoin, Players } from '../../types/bingo-events/in/chat/join';

export type PlayersState = Pick<ChatJoin, 'players'>;
export type RoomsState = Pick<ChatJoin, 'rooms'>;
export type Messages = {
    message: string;
    isSystemMsg: boolean;
    alias?: string;
    userId?: string;
    isChatMaster?: boolean;
    bannedWord?: boolean;
};

export type ChatState = PlayersState &
    RoomsState & {
        currentPlayer: Players;
        currentRoomId: number;
        messages: Messages[];
        msg: string;
        initialized: boolean;
        trial: boolean;
        autoOpen: number;
    };

export type ChatMetaState = {
    initialized: boolean;
    isEjected: boolean;
    isBanned: boolean;
    isDisabled: boolean;
    chatEnabled: boolean;
    chatToggle: boolean;
    roomsToggle: boolean;
    usersToggle: boolean;
    emojiToggle: boolean;
    mentions: number;
};

export const DEFAULT_CHAT_STATE: ChatState = {
    players: [],
    rooms: [],
    currentPlayer: {
        cm: false,
        id: '',
        level: 0,
        muted: false,
        name: '',
    },
    currentRoomId: 0,
    messages: [],
    msg: '',
    initialized: false,
    trial: false,
    autoOpen: 0,
};

export const DEFAULT_CHAT_META_STATE: ChatMetaState = {
    initialized: false,
    isEjected: false,
    isBanned: false,
    isDisabled: false,
    chatToggle: false,
    roomsToggle: false,
    usersToggle: false,
    emojiToggle: false,
    mentions: 0,
    chatEnabled: (localStorage.getItem('chat') || 'true') === 'true',
};

export const [chat, resetChat] = createState(DEFAULT_CHAT_STATE);
export const [chatMeta, resetChatMeta] = createState(DEFAULT_CHAT_META_STATE);
