import { useCallback, ChangeEvent } from 'react';
import { useIncrement } from './use-increment';
import { useUpdatableState } from './use-updatable-state';
import { AutobuySubmissionProps } from '../components/AutobuyModal/mobile';

export const useAutobuyOptions = (
    options: {
        maxLossLimit: number;
        gamesLimit: number;
        games: number;
        tickets: number;
        maxTicketPrice: number;
        maxLoss: number;
        fixedPrice: boolean;
        anyPrice: boolean;
        activation: boolean;
    },
    enableMaxLoss: boolean,
    enableUnlimited: boolean,
    enableMaxCards: boolean,
    onActivate: (props: AutobuySubmissionProps) => void
) => {
    const INC_TICKETS = {
        min: 1,
        max: 100,
        change: 1,
    };

    const INC_MAX_TICKET_PRICE = {
        min: 0.25,
        max: 10,
        change: 0.25,
    };

    const INC_MAX_LOSS = {
        min: 1,
        max: options.maxLossLimit,
        change: 1,
    };

    const INC_GAMES = {
        min: 1,
        max: options.gamesLimit,
        change: 1,
    };

    const [games, incGames, decGames] = useIncrement(options.games, INC_GAMES);
    const [tickets, incTickets, decTickets] = useIncrement(
        options.tickets,
        INC_TICKETS
    );
    const [fixedPrice, setFixedPrice] = useUpdatableState(options.fixedPrice);
    const [anyPrice, setAnyPrice] = useUpdatableState(options.anyPrice);
    const [maxTicketPrice, incMaxTicketPrice, decMaxTicketPrice] = useIncrement(
        options.maxTicketPrice,
        INC_MAX_TICKET_PRICE
    );
    const [maxLoss, incMaxLoss, decMaxLoss] = useIncrement(
        options.maxLoss,
        INC_MAX_LOSS
    );
    const [unlimited, setUnlimited] = useUpdatableState(false);
    const [maxCards, setMaxCards] = useUpdatableState(false);
    const [activation, setActivation] = useUpdatableState(options.activation);

    const handleCheckAnyPrice = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setAnyPrice(e.target.checked);
        },
        [setAnyPrice]
    );

    const handleCheckFixedPrice = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setFixedPrice(e.target.checked);
        },
        [setFixedPrice]
    );

    const handleCheckUnlimited = (e: ChangeEvent<HTMLInputElement>) => {
        setUnlimited(e.target.checked);
    };

    const handleCheckMaxCards = (e: ChangeEvent<HTMLInputElement>) => {
        setMaxCards(e.target.checked);
    };

    const handleCheckActivation = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;
            setActivation(checked);
            onActivate({
                games,
                tickets,
                maxTicketPrice,
                maxLoss,
                anyPrice,
                fixedPrice,
                enableMaxLoss,
                enableUnlimited,
                enableMaxCards,
                activation: checked,
            });
        },
        [
            setActivation,
            onActivate,
            games,
            tickets,
            maxTicketPrice,
            maxLoss,
            anyPrice,
            fixedPrice,
            enableMaxLoss,
            enableUnlimited,
            enableMaxCards,
        ]
    );

    return {
        INC_TICKETS,
        INC_MAX_TICKET_PRICE,
        INC_MAX_LOSS,
        INC_GAMES,
        games,
        tickets,
        fixedPrice,
        anyPrice,
        maxTicketPrice,
        maxLoss,
        unlimited,
        maxCards,
        activation,
        incGames,
        decGames,
        incTickets,
        decTickets,
        incMaxTicketPrice,
        decMaxTicketPrice,
        incMaxLoss,
        decMaxLoss,
        handleCheckAnyPrice,
        handleCheckFixedPrice,
        handleCheckUnlimited,
        handleCheckMaxCards,
        handleCheckActivation,
    };
};
