import { FC, useEffect, PropsWithChildren } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import NoSleep from 'nosleep.js';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Header } from './containers/Header';
import { BingoClassic } from './containers/BingoClassic';
import { SideGames } from './containers/SideGames';
import { BackgroundSounds } from './containers/BackroundSounds';
import { BuyModal } from './containers/BuyModal';
import { PickCardsModal } from './containers/PickCardsModal';
import { AutobuyModal } from './containers/AutobuyModal';
import { RecentWinnersModal } from './containers/RecentWinnersModal';
import { GameCancelledModal } from './containers/GameCancelledModal';
import { BuyingInvalidModal } from './containers/BuyingInvalidModal';
import { MessageModal } from './containers/MessageModal';
import { SettingsModal } from './containers/SettingsModal';
import { GameInfoModal } from './containers/GameInfoModal';
import { PartWinnerModalSG } from './containers/PartWinnerModalSG';
import { DauberStyleModal } from './containers/DauberStyleModal';
import { DauberColorModal } from './containers/DauberColorModal';
import { useBackend, BackendContext } from './hooks/use-backend';
import { useChat, ChatContext } from './hooks/use-chat';
import { chatMeta as chatMetaState } from './stores/chat';
import {
    screenConfig as screenConfigState,
    meta as metaState,
    getScreenConfig,
} from './stores/backend';
import {
    useSoundInitialization,
    SoundContext,
} from './hooks/use-sound-handler';
import GlobalStyle from './globalStyle';
import { getQuery } from './lib/query';

const noSleep = new NoSleep();

const PageNotFound = () => {
    return <h1>Page Not Found!</h1>;
};

const BackendContextProvider: FC<PropsWithChildren> = (props) => {
    const backendPayload = useBackend();

    if (!backendPayload) {
        return null;
    }

    return <BackendContext.Provider {...props} value={{ backendPayload }} />;
};

const ChatContextProvider: FC<PropsWithChildren> = (props) => {
    const chatPayload = useChat();

    if (!chatPayload) {
        return null;
    }

    return <ChatContext.Provider {...props} value={{ chatPayload }} />;
};

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: ${isMobile ? '46.875rem' : '100rem'};
    height: 100%;
    margin: 0 auto;
`;

const AppLayout: FC = () => {
    const { screenConfig, language } = useHookstate(screenConfigState);
    const meta = useHookstate(metaState);
    const chatMeta = useHookstate(chatMetaState);
    const { pathname } = useLocation();

    const sounds = useSoundInitialization({
        loaded: meta.loaded.screenConfig.value,
        sound: meta.sound.value,
        language: language.value,
        caller: screenConfig.room.caller.value.toLowerCase(),
        announcer: screenConfig.room.announcer.value.toLowerCase(),
    });

    const { CSSOVERRIDEURL } = getQuery();

    useEffect(() => {
        getScreenConfig();
    }, []);

    useEffect(() => {
        const enableNoSleep = () => {
            noSleep.enable();
            document.removeEventListener('touchstart', enableNoSleep, false);
        };

        document.addEventListener('touchstart', enableNoSleep, false);
    }, []);

    useEffect(() => {
        if (CSSOVERRIDEURL) {
            const link = document.createElement('link');

            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('href', CSSOVERRIDEURL);
            link.setAttribute('crossorigin', 'anonymous');

            document.head.appendChild(link);
        }
    }, [CSSOVERRIDEURL]);

    return (
        <BackendContextProvider>
            <ChatContextProvider>
                <SoundContext.Provider value={sounds}>
                    <GlobalStyle />
                    <Wrapper>
                        {isMobile &&
                        chatMeta.chatToggle.value &&
                        pathname !== '/side' ? null : (
                            <Header />
                        )}
                        <Routes>
                            <Route path="/" element={<BingoClassic />} />
                            <Route path="/side" element={<SideGames />} />
                            <Route path="/*" element={<PageNotFound />} />
                        </Routes>
                        <BackgroundSounds />
                        <BuyModal />
                        <PickCardsModal />
                        <AutobuyModal />
                        <GameCancelledModal />
                        <BuyingInvalidModal />
                        <SettingsModal />
                        <GameInfoModal />
                        <PartWinnerModalSG />
                        <RecentWinnersModal />
                        <DauberStyleModal />
                        <DauberColorModal />
                        <MessageModal />
                    </Wrapper>
                </SoundContext.Provider>
            </ChatContextProvider>
        </BackendContextProvider>
    );
};

export default AppLayout;
