import {
    NavigateOptions,
    To,
    useNavigate as useReactRouterNavigate,
} from 'react-router-dom';
import { Params, getQuery } from '../lib/query';

const query = getQuery();

const setQueryParams = (params: URLSearchParams) => {
    for (const key in query) {
        params.set(key, query[key as keyof Params]);
    }
};

export const useNavigate = () => {
    const navigate = useReactRouterNavigate();

    const patchNavigate = (to: number | To, options?: NavigateOptions) => {
        if (typeof to === 'number') {
            navigate(to);
        } else if (typeof to === 'object') {
            const params = new URLSearchParams(to.search);
            setQueryParams(params);
            navigate(
                {
                    ...to,
                    search: params.toString(),
                },
                options
            );
        } else {
            const url = new URL(to, window.location.origin);
            setQueryParams(url.searchParams);
            navigate(
                {
                    hash: url.hash,
                    search: url.search,
                    pathname: url.pathname,
                },
                options
            );
        }
    };

    return patchNavigate;
};
