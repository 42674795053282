import { ChatSocket } from '../../hooks/use-socket';
import { ChatPayload } from './payload';

export const onConnect = (socket: ChatSocket, payload: ChatPayload) => {
    const callback = () => {
        payload.connectChat();
    };

    socket.on('connect', callback);

    return () => {
        socket.off('connect', callback);
    };
};
