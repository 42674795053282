import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useState } from '@hookstate/core';
import { BingoCardListMobile } from '../../components/BingoCardList/mobile';
import { BingoCardListDesktop } from '../../components/BingoCardList/desktop';
import { useCardListVisible } from '../../hooks/use-card-list-visible';
import { useGamePart } from '../../hooks/use-game-part';
import { useGameType } from '../../hooks/use-game-type';
import { calls, current, meta, next } from '../../stores/backend';

export const BingoCardList: FC = () => {
    const $current = useState(current);
    const $next = useState(next);
    const $calls = useState(calls);
    const $meta = useState(meta);

    const { isVisible, isCurrent, game, cards } = useCardListVisible(
        $current,
        $next,
        $calls,
        $meta
    );

    const gameType = useGameType(game);
    const called = isCurrent ? $calls.value : [];
    const { patterns, matches } = useGamePart(game, $meta);

    const hasFreeSpace = game.game_hasFreeSpace.value;

    const Component = isMobile ? BingoCardListMobile : BingoCardListDesktop;

    if (isVisible === false) {
        return null;
    }

    return (
        <Component
            cards={cards}
            called={called}
            matches={matches}
            gameType={gameType}
            patterns={patterns}
            hasFreeSpace={hasFreeSpace}
            autoDaub={$meta.autoDaub.value}
            dauberColor={$meta.dauberColor.value}
            dauberStyle={$meta.dauberStyle.value}
            currentPart={$current.current_part.value}
        />
    );
};
