import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { CurrentGamePanel } from '../CurrentGamePanel';
import { NextGamePanel } from '../NextGamePanel';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { current } from '../../stores/backend';

export const GamePanel: FC = () => {
    const $current = useHookstate(current);
    const bought = useBoughtTickets($current);

    const gameMode = $current.game_mode.value;

    if (gameMode !== 'GAME_MODE' || bought === 0) {
        return <NextGamePanel />;
    }

    return <CurrentGamePanel />;
};
