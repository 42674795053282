import { BackendSocket } from '../../hooks/use-socket';
import { BingoJackpotWinnerMessage } from '../../types/bingo-events/in/backend/jackpot-winner-message';
import { jackpotWinner, meta } from '../../stores/backend';

export const onJackpotWinnerMessage = (socket: BackendSocket) => {
    const callback = (data: BingoJackpotWinnerMessage) => {
        jackpotWinner.set(data);
        meta.modals.jackpot.set(true);
    };

    socket.on('bingo:JackpotWinnerMessage', callback);

    return () => {
        socket.off('bingo:JackpotWinnerMessage', callback);
    };
};
