import { FC } from 'react';

import {
    IncrementButtonContainer,
    IncrementButtonProps,
} from '../IncrementButton';

import { useSoundHandler } from '../../hooks/use-sound-handler';

export const DecrementButton: FC<IncrementButtonProps> = ({
    handleClick,
    ...props
}) => {
    const MINUS = '-';
    const onClick = useSoundHandler(handleClick, 'click');
    return (
        <IncrementButtonContainer onClick={onClick} {...props}>
            {MINUS}
        </IncrementButtonContainer>
    );
};
