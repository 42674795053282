import { State } from '@hookstate/core';
import { GameState, MetaState } from '../stores/backend';
import { CardItem, useCards } from './use-cards';

export type StripSortedItem = {
    stripId: string;
    cards: CardItem[];
};

export type SortedCardsResult =
    | {
          type: 'stripped';
          list: StripSortedItem[];
      }
    | {
          type: 'regular';
          list: CardItem[];
      };

export const sortCardsByTogo = (c1: CardItem, c2: CardItem) =>
    c1.togo - c2.togo;

export const useSortedCards = (
    game: State<GameState>,
    calls: State<number[]>,
    meta: State<MetaState>,
    sorted: boolean
): SortedCardsResult => {
    const cards = useCards(game, meta, calls);

    if (game.game_strip_active.value) {
        const indices: Record<string, number> = {};
        const list: StripSortedItem[] = [];

        for (let index = 0; index < cards.length; index++) {
            const card = cards[index];
            const [stripId, cardId] = card.cardId.split('-');
            const newCard: CardItem = {
                cardId,
                cardNumbers: card.cardNumbers,
                togo: card.togo,
            };

            const groupIndex = indices[stripId];

            if (typeof groupIndex === 'undefined') {
                indices[stripId] = list.length;
                list.push({
                    stripId,
                    cards: [newCard],
                });
            } else {
                list[groupIndex].cards.push(newCard);
            }
        }

        if (sorted) {
            for (const item of list) {
                item.cards.sort(sortCardsByTogo);
            }

            list.sort((i1, i2) => i1.cards[0].togo - i2.cards[0].togo);
        }

        return {
            type: 'stripped',
            list,
        };
    }

    if (sorted) {
        cards.sort(sortCardsByTogo);
    }

    return {
        type: 'regular',
        list: cards,
    };
};
