export type TimerOptions = {
    callback: (seconds: number) => unknown;
    end: number;
};

export const getCurrentTime = () => new Date().getTime();

export const createTimer = (
    dateMs: number,
    callback: (seconds: number) => unknown
) => {
    let id: NodeJS.Timer;

    const end = () => clearInterval(id);

    const ticker = () => {
        const seconds = Math.floor((dateMs - getCurrentTime()) / 1000);

        if (seconds <= 0) {
            end();
        }

        if (seconds < 0) {
            return;
        }

        callback(seconds);
    };

    ticker();
    id = setInterval(ticker, 1000);

    return { id, end };
};
