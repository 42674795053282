import { createState as createHookState } from '@hookstate/core';

export const createState = <T>(defaultValue: T) => {
    const getNewState = (): T => JSON.parse(JSON.stringify(defaultValue));

    const state = createHookState(getNewState());

    const reset = () => {
        state.set(getNewState());
    };

    return [state, reset] as const;
};
