import { useState, useEffect } from 'react';

export const useCycleGameParts = (size: number, delay = 2000) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (size < 2) {
            return;
        }

        const id = setInterval(() => {
            setIndex((index) => (index + 1) % size);
        }, delay);

        return () => {
            clearInterval(id);
        };
    }, [size, delay]);

    return index;
};
