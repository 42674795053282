import { FC } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CommonPanel } from '../CommonPanel';
import { CountdownTimer } from '../CountdownTimer';
import { BuyButton } from '../BuyButton';
import { BuyButtonText } from '../BuyButton/mobile';
import { useCountdown } from '../../hooks/use-countdown';

export type TicketsPurchasedPanelProps = {
    to: Date;
    wagered: string;
    maxTickets: number;
    freeCards: number;
    ticketsPurchased: number;
    totalPromoCards: number;
    chatOpen: boolean;
    handleClick: () => void;
};

export const TicketsPurchasedPanelValueBase = css`
    margin-top: 0.188rem;
    margin-left: 0.313rem;
    color: ${({ theme: { ticketsPurchasedPanel } }) =>
        ticketsPurchasedPanel.color.value};
`;

export const TicketsPurchasedPanelContainer = styled(CommonPanel)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    height: auto;
    min-height: 12.125rem;
    padding: 1.438rem 1.5rem;
`;

export const TicketsPurchasedPanelTime = styled(CountdownTimer)`
    text-align: center;
    margin-right: 1.188rem;
    font-size: 5.75rem;

    ${isMobile
        ? `
        flex-basis: 34%;   
    `
        : `
        flex-basis: 28%;
       
    `}
`;

export const TicketsPurchasedPanelText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: ${isMobile ? '63%' : '70%'};
`;

export const TicketsPurchasedPanelLabel = styled.span`
    line-height: 1.06;
    text-align: center;

    font-size: ${isMobile ? '2.125rem' : '3rem'};
    color: ${({ theme: { ticketsPurchasedPanel } }) =>
        ticketsPurchasedPanel.color.label};
`;

export const TicketsPurchasedPanelLabelWagered = styled(
    TicketsPurchasedPanelLabel
)`
    line-height: 1.38;
    font-size: 1.625rem;
    margin-bottom: 0.438rem;
`;

export const TicketsPurchasedPanelValue = styled(TicketsPurchasedPanelLabel)`
    ${TicketsPurchasedPanelValueBase};
`;

export const TicketsPurchasedPanelValueWagered = styled(
    TicketsPurchasedPanelLabelWagered
)`
    ${TicketsPurchasedPanelValueBase};
`;

export const TimerPanelUpperText = styled.div`
    font-size: ${isMobile ? '1.625rem' : '2.25rem'};
    line-height: 1.06;
    margin-bottom: 0.438rem;
    text-align: center;
    color: ${(props) => props.theme.timerPanel.color.upperText};
`;

export const TimerPanelLowerText = styled.div`
    font-size: 1.563rem;
    line-height: 1.08;
    text-align: center;
    color: ${(props) => props.theme.timerPanel.color.lowerText};
`;

export const BuyButtonWrapper = styled(BuyButton)`
    height: 4rem;

    ${BuyButtonText} {
        font-size: 2.25rem;
    }
`;

export const TicketsPurchasedPanel: FC<TicketsPurchasedPanelProps> = ({
    to,
    wagered,
    maxTickets,
    freeCards,
    ticketsPurchased,
    totalPromoCards,
    chatOpen,
    handleClick,
    ...props
}) => {
    const { t } = useTranslation();
    const { seconds } = useCountdown(to);

    const $ticketsPurchased = ticketsPurchased - freeCards;

    return (
        <TicketsPurchasedPanelContainer {...props} type="secondary">
            <TicketsPurchasedPanelTime to={to} />
            <TicketsPurchasedPanelText>
                {maxTickets === $ticketsPurchased ? (
                    <>
                        <TimerPanelUpperText>
                            {t('callboardMaxPurchaseBox.maxTicketsBought')}
                        </TimerPanelUpperText>
                        <TicketsPurchasedPanelLabelWagered>
                            {t('callboardMaxPurchaseBox.wagered')}
                            <TicketsPurchasedPanelValueWagered>
                                {wagered}
                            </TicketsPurchasedPanelValueWagered>
                        </TicketsPurchasedPanelLabelWagered>
                        <TimerPanelLowerText>
                            {t('callboardMaxPurchaseBox.pleaseWaitGameBegin')}
                        </TimerPanelLowerText>
                    </>
                ) : (
                    <>
                        <TicketsPurchasedPanelLabel>
                            {t('callboardPurchaseBox.ticketsPurchased')}
                            <TicketsPurchasedPanelValue>
                                {$ticketsPurchased - totalPromoCards}
                            </TicketsPurchasedPanelValue>
                        </TicketsPurchasedPanelLabel>
                        <TicketsPurchasedPanelLabelWagered>
                            {t('callboardMaxPurchaseBox.wagered')}
                            <TicketsPurchasedPanelValueWagered>
                                {wagered}
                            </TicketsPurchasedPanelValueWagered>
                        </TicketsPurchasedPanelLabelWagered>
                        {chatOpen && isMobile ? (
                            <BuyButtonWrapper
                                isDisabled={seconds <= 20}
                                handleClick={handleClick}
                            />
                        ) : null}
                    </>
                )}
            </TicketsPurchasedPanelText>
        </TicketsPurchasedPanelContainer>
    );
};
