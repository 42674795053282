import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import { CountdownModal as CountdownModalComponent } from '../../components/CountdownModal';
import { meta as metaState } from '../../stores/backend';

export const CountdownModal: FC = () => {
    const meta = useHookstate(metaState);
    const to = new Date(meta.timer.date.value);
    return <CountdownModalComponent to={to} />;
};
