import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type MoreButtonProps = HTMLAttributes<HTMLButtonElement> &
    HTMLAttributes<HTMLImageElement> & {
        handleClick: () => void;
    };

const MoreButtonContainer = styled.button`
    background: transparent;
    width: 4.375rem;
    height: 4.375rem;
    border: none;
    padding: 0;
    cursor: pointer;
`;

const MorebuttonImage = styled.img`
    width: 4.375rem;
    height: 4.375rem;
    image-rendering: -webkit-optimize-contrast;
`;

export const MoreButton: FC<MoreButtonProps> = ({ handleClick, ...props }) => {
    const theme = useTheme();
    const { moreButton } = theme.images;
    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <MoreButtonContainer {...props} onClick={onClick}>
            <MorebuttonImage src={moreButton} alt="Kebab Icon" />
        </MoreButtonContainer>
    );
};
