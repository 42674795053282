import { useCallback, FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useHookstate } from '@hookstate/core';
import { ChatHeaderMobile } from '../../components/ChatHeader/mobile';
import { ChatHeaderDesktop } from '../../components/ChatHeader/desktop';
import { useCurrentRoom } from '../../hooks/use-current-room';
import { chat, chatMeta } from '../../stores/chat/';

type ChatHeaderProps = {
    size: number;
    handleToggle?: (size?: number) => void;
};

export const ChatHeader: FC<ChatHeaderProps> = ({ size, handleToggle }) => {
    const $chat = useHookstate(chat);
    const $chatMeta = useHookstate(chatMeta);

    const roomName = useCurrentRoom(
        $chat.rooms.value,
        $chat.currentRoomId.value
    );

    const onChatroom = useCallback(() => {
        $chatMeta.roomsToggle.set(!$chatMeta.roomsToggle.value);
        if ($chatMeta.usersToggle.value) {
            $chatMeta.usersToggle.set(false);
        }
    }, [$chatMeta.roomsToggle, $chatMeta.usersToggle]);

    const onUsers = useCallback(() => {
        $chatMeta.usersToggle.set(!$chatMeta.usersToggle.value);
        if ($chatMeta.roomsToggle.value) {
            $chatMeta.roomsToggle.set(false);
        }
    }, [$chatMeta.usersToggle, $chatMeta.roomsToggle]);

    const onClose = useCallback(() => {
        if ($chatMeta.usersToggle.value) {
            $chatMeta.usersToggle.set(false);
        }
        if ($chatMeta.roomsToggle.value) {
            $chatMeta.roomsToggle.set(false);
        }

        $chatMeta.chatToggle.set(false);
    }, [$chatMeta.chatToggle, $chatMeta.usersToggle, $chatMeta.roomsToggle]);

    const Component = isMobile ? ChatHeaderMobile : ChatHeaderDesktop;

    return (
        <Component
            size={size}
            roomName={roomName}
            users={$chat.players.length}
            onChatroom={onChatroom}
            onUsers={onUsers}
            onClose={onClose}
            handleToggle={handleToggle}
        />
    );
};
