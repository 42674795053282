import { chat } from '../../stores/chat';
import { ChatSocket } from '../../hooks/use-socket';
import { ChatLeave } from '../../types/bingo-events/in/chat/leave';

export const onLeave = (socket: ChatSocket) => {
    const callback = (response: ChatLeave) => {
        const newPlayers = chat.players
            .get({ noproxy: true })
            .filter((player) => player.id !== response.playerId);

        chat.players.set(newPlayers);

        if (response.message) {
            chat.messages.merge([
                { message: response.message, isSystemMsg: true },
            ]);
        }
    };

    socket.on('leave:parlay', callback);

    return () => {
        socket.off('leave:parlay', callback);
    };
};
