import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FooterSG } from '../FooterSG';
import { Ball75 } from '../Ball75';
import { Ball80 } from '../Ball80';
import { Ball90 } from '../Ball90';
import { ToGoPanel } from '../ToGoPanel';
import { GamePart, GamePartProps, GamePartDot } from '../GamePart';
import {
    CallsCard,
    CallsCardProps,
    CallsText,
    CallsNumber,
} from '../CallsCard';
import { MessageBubbleWrapper } from '../NextGameSG/landscape';

type PlayModeSGProps = CallsCardProps &
    GamePartProps & {
        showFooter: boolean;
        calledBalls: number[];
        togos: number[];
        mentions: number;
        showMessageBubble: boolean;
        handleMentions: () => void;
        onToggleFooter: () => void;
    };

const PlayModeSGContainer = styled(FooterSG)`
    align-items: center;
    justify-content: space-evenly;
    padding: 0.25rem 3.5rem 0.25rem 1rem;
    width: 100%;
    left: 0;
    right: 0;
`;

const CallsCardWrapper = styled(CallsCard)`
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.313rem 0 0 0;
    margin-right: 0.75rem;
    border-width: 0.125rem;
    border-radius: 0.5rem;

    ${CallsText} {
        font-size: 0.875rem;
        line-height: 0.75rem;
    }

    ${CallsNumber} {
        font-size: 1.75rem;
        line-height: 1.75rem;
    }
`;

const FlexRowWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 20rem;
`;

const FlexColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 20rem;
`;

const GamePartWrapper = styled(GamePart)`
    margin-bottom: 0.25rem;

    ${GamePartDot} {
        width: 0.75rem;
        height: 0.75rem;
    }
`;

const CalledBallsWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    height: 3.125rem;
    overflow: hidden;
`;

const BallTrayItem = styled.div<{ x: number }>`
    user-select: none;
    position: absolute;
    transition: 0.5s ease-in-out left, 0.5s ease-in-out transform;
    transform: ${(props) => `rotate(${props.x * 360}deg)`};
    left: ${(props) => props.x * 4.25 + 'rem'};
`;

const TogoPanelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ToGoPanelWrapper = styled(ToGoPanel)`
    width: 4rem;
    height: 1.75rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    border: none;
`;

const BALLS = Array.from({ length: 90 }).map((_v, i) => i + 1);
const BALL_COMPONENTS = {
    75: Ball75,
    80: Ball80,
    90: Ball90,
};

export const PlayModeSGLandscape: FC<PlayModeSGProps> = ({
    calls,
    calledBalls,
    togos,
    gameType,
    gameParts,
    currentPart,
    mentions,
    showMessageBubble,
    handleMentions,
    ...props
}) => {
    const { t } = useTranslation();
    // slice first before reverse to make a new copy of the array
    const $recentBalls = calledBalls.slice().reverse();
    const Ball = BALL_COMPONENTS[gameType];

    return (
        <PlayModeSGContainer {...props}>
            <FlexRowWrapper>
                <CallsCardWrapper calls={calls} gameType={gameType} />
                <CalledBallsWrapper>
                    {BALLS.map((ball) => {
                        return (
                            <BallTrayItem
                                as={Ball}
                                size="xxsmall"
                                key={ball}
                                number={ball}
                                x={$recentBalls.indexOf(ball)}
                            />
                        );
                    })}
                </CalledBallsWrapper>
            </FlexRowWrapper>
            <FlexColWrapper>
                <GamePartWrapper
                    gameParts={gameParts}
                    currentPart={currentPart}
                />
                <TogoPanelContainer>
                    {togos.map((togo, index) => {
                        const LABEL = {
                            1: t('cardDeck.OneToGo'),
                            2: t('cardDeck.TwoToGo'),
                            3: t('cardDeck.ThreeToGo'),
                            4: t('cardDeck.FourOrMoreToGo'),
                        };

                        return (
                            <ToGoPanelWrapper
                                key={index}
                                label={LABEL[(index + 1) as keyof typeof LABEL]}
                                value={togo}
                            />
                        );
                    })}
                </TogoPanelContainer>
            </FlexColWrapper>
            {showMessageBubble && (
                <MessageBubbleWrapper
                    mentions={mentions}
                    handleClick={handleMentions}
                />
            )}
        </PlayModeSGContainer>
    );
};
