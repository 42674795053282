import { FC, HTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { CommonBall, CommonBallContainerProps } from '../CommonBall';

export type BallColor = 'red' | 'yellow' | 'blue' | 'gray';

export type Ball80Letter = 'R' | 'Y' | 'B' | 'G';

export type Ball80ColorProps = {
    color: BallColor;
};

export type Ball80Props = HTMLAttributes<HTMLDivElement> & {
    number: number;
} & CommonBallContainerProps;

const OVERLAY_SIZE = {
    big: css`
        width: 8.938rem;
        height: 8.938rem;
    `,
    small: css`
        width: 6.188rem;
        height: 6.188rem;
    `,
    xsmall: css`
        width: 4.25rem;
        height: 4.25rem;
    `,
    xxsmall: css`
        width: 2.75rem;
        height: 2.75rem;
    `,
};

const INNER_CIRCLE_SIZE = {
    big: css`
        width: 4.875rem;
        height: 4.875rem;
    `,
    small: css`
        width: 3.375rem;
        height: 3.375rem;
    `,
    xsmall: css`
        width: 2.313rem;
        height: 2.313rem;
    `,
    xxsmall: css`
        width: 1.5rem;
        height: 1.5rem;
    `,
};

const NUMBER_SIZE = {
    big: css`
        font-size: 3.625rem;
    `,
    small: css`
        font-size: 2.5rem;
    `,
    xsmall: css`
        font-size: 1.625rem;
    `,
    xxsmall: css`
        font-size: 1rem;
    `,
};

export const Ball80Overlay = styled.img``;

export const Ball80InnerCircle = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: ${({ theme }) => theme.ball80.background};
`;

export const Ball80Number = styled.div`
    color: ${({ theme }) => theme.ball80.color}; ;
`;

export const Ball80Container = styled(CommonBall)<Ball80ColorProps>`
    ${({ color, size, theme }) => `
        border-color: ${theme.ball80[color].border};
        background-image: radial-gradient(
            circle at 50% 0,
            ${theme.ball80[color].backgroundGradient.first} 1%,
            ${theme.ball80[color].backgroundGradient.second} 15%,
            ${theme.ball80[color].backgroundGradient.third} 50%,
            ${theme.ball80[color].backgroundGradient.fourth} 120%
        );

        ${Ball80Overlay} {
            ${OVERLAY_SIZE[size]}
        }

        ${Ball80InnerCircle} {
            ${INNER_CIRCLE_SIZE[size]}
        }

        ${Ball80Number} {
            ${NUMBER_SIZE[size]}
        }
    `};
`;

export const LETTER_COLORS = {
    R: 'red',
    Y: 'yellow',
    B: 'blue',
    G: 'gray',
};

export const Ball80: FC<Ball80Props> = ({ number, size, ...props }) => {
    const RYBG = 'RYBG';
    /**
        Default letter is needed because BallCalls Component will pass a number more than 80.
        It will cause an undefined value for letter.
    */
    const letter = (RYBG[Math.floor((number - 1) / 20)] as Ball80Letter) || 'R';
    let color = LETTER_COLORS[letter] as BallColor;

    const theme = useTheme();
    const { ball80Overlay } = theme.images;

    return (
        <Ball80Container {...props} color={color} size={size}>
            <Ball80Overlay src={ball80Overlay} />
            <Ball80InnerCircle>
                <Ball80Number>{number}</Ball80Number>
            </Ball80InnerCircle>
        </Ball80Container>
    );
};
