import { State } from '@hookstate/core';
import { GameState, MetaState } from '../stores/backend';
import { useBoughtTickets } from './use-bought-tickets';
import { useSortedCards } from './use-sorted-cards';

export const useCardListVisible = (
    current: State<GameState>,
    next: State<GameState>,
    calls: State<number[]>,
    meta: State<MetaState>
) => {
    const bought = useBoughtTickets(current);

    const isCurrent = bought > 0;

    const game =
        isCurrent && current.game_sid.value !== next.game_sid.value
            ? current
            : next;

    const cards = useSortedCards(game, calls, meta, isCurrent);

    const isVisible = !(
        cards.list.length === 0 ||
        ((current.game_mode.value === 'GAME_OVER' ||
            current.game_mode.value === 'GAME_CANCELLED') &&
            game === current)
    );

    return {
        game,
        cards,
        isCurrent,
        isVisible,
    };
};
