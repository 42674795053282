import { State } from '@hookstate/core';
import { createState } from '../../lib/create-state';
import { BingoLoadGameResponseData } from '../../types/bingo-events/in/backend/load-game-response';

export type GameState = Omit<
    BingoLoadGameResponseData,
    'current_calls' | 'wagered' | 'player_cash' | 'player_bonus'
> & { current_part: number };

export const DEFAULT_GAME_STATE: GameState = {
    currencies: {},
    player_currency: '',
    game_sid: 0,
    game_desc: '',
    game_mode: null,
    game_type: '75BALL',
    game_cards: 0,
    game_parts: {},
    game_number: 0,
    game_players: 0,
    player_alias: '',
    player_trial: false,
    game_currency: '',
    game_free_cards: 0,
    game_max_call: 0,
    game_call_freq: 0,
    game_card_cols: 0,
    game_card_rows: 0,
    game_cards_max: 0,
    game_partdelay: 0,
    game_strip_only: false,
    game_players_min: 0,
    game_purchasedur: 0,
    game_ticket_cost: 0,
    game_hasFreeSpace: false,
    game_strip_active: false,
    game_free_buy_confirm: false,
    game_mode_time_remaining: 0,
    current_part: 0,
    fixed_game: 0,
    game_strip_cost: 10,
};

export const [current, resetCurrent] = createState(DEFAULT_GAME_STATE);

export const [next, resetNext] = createState(DEFAULT_GAME_STATE);

export const getTicketCost = (game: State<GameState>) =>
    game.game_strip_only.value
        ? game.game_strip_cost.value
        : game.game_ticket_cost.value;

export const getBoughtTickets = (game: State<GameState>) =>
    Object.keys(game.player_cards.value || {}).length;

export const getMaxCardsCanBuy = (game: State<GameState>) => {
    const freeCards = game.game_free_cards.value || 0;
    const maxCards = game.game_cards_max.value || 0;
    const bought = getBoughtTickets(game);
    return maxCards - bought - freeCards;
};

export const isMaxCardsBought = (game: State<GameState>) => {
    const freeCards = game.game_free_cards.value || 0;
    const maxCards = game.game_cards_max.value || 0;
    const bought = getBoughtTickets(game);
    return bought > 0 && bought - freeCards === maxCards;
};

export const isFreeBuyConfirm = (game: State<GameState>) =>
    !game.game_free_buy_confirm.value &&
    !isMaxCardsBought(game) &&
    (game.game_ticket_cost.value === 0 ||
        (game.game_strip_cost.value === 0 && game.game_strip_only.value));
