import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CommonModal, CommonModalContainer } from '../CommonModal';
import { CommonToggle } from '../CommonToggle';
import { ModalTitle } from '../ModalTitle';
import { ModalLabel } from '../ModalLabel';
import { SettingsMenuButton } from '../SettingsMenuButton';
import { SettingsModalProps } from './mobile';
import { DauberSelect } from '../DauberSelect';

export const SettingsModalContainer = styled(CommonModalContainer)`
    padding: 0 0 3.563rem;
    left: 16.875rem;
`;

export const MarginWrapper = styled.div`
    margin: 2rem 4.813rem 0.688rem;
`;

export const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
`;

export const LabelWrapper = styled(ModalLabel)`
    font-size: 2.5rem;
    letter-spacing: normal;
    color: ${({ theme }) => theme.settingsModal.color.label};
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
`;

export const Divider = styled.hr`
    width: 85%;
    border-width: 0;
    height: 0.125rem;
    margin: 0 auto;

    background-color: ${({
        theme: {
            autobuyModal: { color },
        },
    }) => color.divider};
`;

export const SettingsModalVersion = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    font-family: Signika-SemiBold;
    font-size: 1.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: 100%;
    height: 3.563rem;

    ${({ theme: { settingsModal } }) => `
        color: ${settingsModal.color.version};
        background-image: linear-gradient(to bottom, ${settingsModal.backgroundGradient.upper} 2%,
            ${settingsModal.backgroundGradient.lower} 97%);
    `};
`;

export const SettingsModalDesktop: FC<SettingsModalProps> = ({
    currency,
    version,
    sound,
    chat,
    autoDaub,
    hideChat,
    onHelpDesk,
    onRecentWinners,
    onResponsibleGaming,
    onToggleSound,
    onToggleCurrency,
    onToggleChat,
    onToggleDaub,
    onDauberStyle,
    onDauberColor,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <CommonModal {...props} Container={SettingsModalContainer}>
            <MarginWrapper>
                <ModalTitle title={t('modalOptions.settings')} />
                <ButtonsWrapper>
                    <SettingsMenuButton onClick={onHelpDesk}>
                        {t('modalOptions.helpLink')}
                    </SettingsMenuButton>
                    <SettingsMenuButton onClick={onRecentWinners}>
                        {t('modalOptions.recentWinners')}
                    </SettingsMenuButton>
                    <SettingsMenuButton onClick={onResponsibleGaming}>
                        {t('modalOptions.responsibleGaming')}
                    </SettingsMenuButton>
                </ButtonsWrapper>
            </MarginWrapper>
            <Divider />
            <MarginWrapper>
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.sound')} />
                    <CommonToggle checked={sound} onChange={onToggleSound} />
                </FlexWrapper>
                {!hideChat && (
                    <FlexWrapper>
                        <LabelWrapper label={t('modalOptions.chat')} />
                        <CommonToggle checked={chat} onChange={onToggleChat} />
                    </FlexWrapper>
                )}
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.autoDaub')} />
                    <CommonToggle checked={autoDaub} onChange={onToggleDaub} />
                </FlexWrapper>
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.dauberStyle')} />
                    <DauberSelect type="STYLE" onClick={onDauberStyle} />
                </FlexWrapper>
                <FlexWrapper>
                    <LabelWrapper label={t('modalOptions.dauberColour')} />
                    <DauberSelect type="COLOR" onClick={onDauberColor} />
                </FlexWrapper>
            </MarginWrapper>
            <SettingsModalVersion>{version}</SettingsModalVersion>
        </CommonModal>
    );
};
