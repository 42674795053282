import { State } from '@hookstate/core';
import { GameState } from '../stores/backend';
import { useGameType } from './use-game-type';

export const useCardBuy = (game: State<GameState>) => {
    const gameType = useGameType(game);

    let cardsToBuy = 1;

    const stripOnly = game.game_strip_only.value;
    const ticketCost = game.game_ticket_cost.value;
    const stripCost = game.game_strip_cost.value;
    const fixedGame = game.fixed_game.value;
    const stripSize = gameType === 90 ? 6 : 3;
    const cardMax = game.game_cards_max.value || 0;

    const isFreeGame = stripOnly ? stripCost === 0 : ticketCost === 0;

    if (stripOnly && !fixedGame) {
        cardsToBuy = stripSize;
    } else if (fixedGame) {
        cardsToBuy = fixedGame;
    } else if (isFreeGame) {
        cardsToBuy = stripOnly ? cardMax / stripSize : cardMax;
    }

    return cardsToBuy;
};
