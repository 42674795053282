import { HTMLAttributes, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

export type InfoButtonProps = HTMLAttributes<HTMLDivElement>;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const LoadingImage = styled.img`
    width: 13rem;
    height: 13rem;
    margin-bottom: 1rem;
    animation: rotation 2s infinite linear;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`;

const LoadingText = styled.div`
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 2.5rem;
    line-height: 2.5rem;
`;

export const Loading: FC = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { loading } = theme.images;

    return (
        <LoadingContainer {...props}>
            <LoadingImage src={loading} />
            <LoadingText>{t('preLoader.loading')}...</LoadingText>
        </LoadingContainer>
    );
};
