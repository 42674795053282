import { FC, useEffect, useCallback } from 'react';
import { useHookstate } from '@hookstate/core';
import { isMobile } from 'react-device-detect';
import { PickCardsModalMobile } from '../../components/PickCardsModal/mobile';
import { PickCardsModalDesktop } from '../../components/PickCardsModal/desktop';
import { useGameType } from '../../hooks/use-game-type';
import { useSpecialGame } from '../../hooks/use-special-game';
import { useTicketPrice } from '../../hooks/use-ticket-price';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { usePickCards } from '../../hooks/use-pick-cards';
import { useBackendPayload } from '../../hooks/use-backend';
import { next, meta, pickedCards } from '../../stores/backend';

export const PickCardsModal: FC = () => {
    const $next = useHookstate(next);
    const $meta = useHookstate(meta);
    const $pickedCards = useHookstate(pickedCards);

    const gameType = useGameType($next);
    const special = useSpecialGame($next, $meta);
    const ticketPrice = useTicketPrice($next);
    const cards = usePickCards($next, $pickedCards.cards.value);
    const formatCurrency = useFormatCurrency($next, $meta);
    const { backendPayload } = useBackendPayload();

    const onClose = useCallback(() => {
        $meta.modals.pickCards.set(false);
    }, [$meta.modals.pickCards]);

    const onNewCards = useCallback(() => {
        backendPayload.getPickedCards();
    }, [backendPayload]);

    const onSelected = useCallback(
        (map: boolean[] | boolean[][]) => {
            if (cards.type === 'regular') {
                const selected = cards.list
                    .filter(
                        (card, index: number) =>
                            map[index] &&
                            !$pickedCards.bought.value.includes(card.cardId)
                    )
                    .map((card) => card.cardId);

                $pickedCards.selected.set(selected);
            } else {
                const selected = cards.list.map((strip, stripIndex) => {
                    return strip.cards
                        .filter((card, cardIndex) => {
                            return (
                                (map[stripIndex] as boolean[])[cardIndex] &&
                                !$pickedCards.bought.value.includes(
                                    strip.stripId + '-' + card.cardId
                                )
                            );
                        })
                        .map((card) => {
                            return strip.stripId + '-' + card.cardId;
                        });
                });

                const combined: string[] = [];

                selected.forEach((strip) => {
                    combined.push(...strip);
                });

                $pickedCards.selected.set(combined);
            }
        },
        [$pickedCards.selected, $pickedCards.bought.value, cards]
    );

    const onBuy = () => {
        backendPayload.buyTicket($pickedCards.selected.value);
        onClose();
    };

    useEffect(() => {
        if ($meta.modals.pickCards.value) {
            backendPayload.getPickedCards();
        }
    }, [$meta.modals.pickCards.value, backendPayload]);

    const Component = isMobile ? PickCardsModalMobile : PickCardsModalDesktop;

    return (
        <Component
            cards={cards}
            gameType={gameType}
            special={special}
            ticketPrice={ticketPrice}
            stripActive={$next.game_strip_active.value}
            stripsOnly={$next.game_strip_only.value}
            fixedGame={$next.fixed_game.value}
            hasFreeSpace={$next.game_hasFreeSpace.value}
            gameCardsMax={$next.game_cards_max.value}
            gameCurrency={$next.game_currency.value}
            freeCards={$next.game_free_cards.value || 0}
            bought={$pickedCards.bought.value}
            selected={$pickedCards.selected.value}
            hasNext={$pickedCards.hasNext.value}
            countdownDate={new Date($meta.timer.date.value)}
            isOpen={$meta.modals.pickCards.value}
            isDisabled={!$meta.enabled.buying.value}
            onClose={onClose}
            handleBuy={onBuy}
            handleSelected={onSelected}
            handleNewCards={onNewCards}
            formatCurrency={formatCurrency}
        />
    );
};
