import { useState } from '@hookstate/core';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { SettingsModalMobile } from '../../components/SettingsModal/mobile';
import { SettingsModalDesktop } from '../../components/SettingsModal/desktop';
import { onToggleCurrency } from '../Header';
import { useChatPayload } from '../../hooks/use-chat';
import {
    meta,
    screenConfig,
    closeModal,
    openModal,
} from '../../stores/backend';
import { current } from '../../stores/backend';
import { chat, chatMeta } from '../../stores/chat';
import pkg from '../../../package.json';

const onToggleSound = () => {
    meta.sound.set((sound) => !sound);
    localStorage.setItem('sound', meta.sound.value + '');
};

const onToggleDaub = () => {
    meta.autoDaub.set((autoDaub) => !autoDaub);
    localStorage.setItem('autoDaub', meta.autoDaub.value + '');
};

const onClose = () => closeModal('settings');

const onHelpDesk = () => {
    window.open(screenConfig.screenConfig.site.helpUrl.value, '_blank');
};

const onRecentWinners = () => {
    openModal({ key: 'recWinners' });
};

const onResponsibleGaming = () => {
    window.open(screenConfig.screenConfig.site.responsibleUrl.value, '_blank');
};

const onDauberStyle = () => {
    meta.modals.dauberStyle.set((dauberStyle) => !dauberStyle);
};

const onDauberColor = () => {
    meta.modals.dauberColor.set((dauberColor) => !dauberColor);
};

export const SettingsModal: FC = () => {
    const $meta = useState(meta);
    const $chatMeta = useState(chatMeta);
    const $chat = useState(chat);
    const $current = useState(current);

    const { chatPayload } = useChatPayload();

    const [defaultRoom] = $chat.rooms.value.filter((room) => room.defaultOpen);

    const hideCurrencyToggle =
        $current.game_currency.value === $current.player_currency.value;

    const onToggleChat = () => {
        $chatMeta.chatEnabled.set((chat) => !chat);
        localStorage.setItem('chat', $chatMeta.chatEnabled.value + '');

        if (!$chatMeta.chatEnabled.value) {
            chatPayload.leaveRoom($chat.currentRoomId.value);
            $chat.messages.set([]);
            $chat.currentRoomId.set(defaultRoom.id);
        }
    };

    const Component = isMobile ? SettingsModalMobile : SettingsModalDesktop;

    return (
        <Component
            currency={$meta.currency.value}
            isOpen={$meta.modals.settings.value}
            sound={$meta.sound.value}
            autoDaub={$meta.autoDaub.value}
            chat={$chatMeta.chatEnabled.value}
            hideCurrencyToggle={hideCurrencyToggle}
            hideChat={
                $chatMeta.isBanned.value ||
                $chatMeta.isDisabled.value ||
                $chat.rooms.value.length === 0
            }
            version={`v${pkg.version}`}
            onHelpDesk={onHelpDesk}
            onRecentWinners={onRecentWinners}
            onResponsibleGaming={onResponsibleGaming}
            onToggleSound={onToggleSound}
            onToggleDaub={onToggleDaub}
            onToggleCurrency={onToggleCurrency}
            onToggleChat={onToggleChat}
            onDauberStyle={onDauberStyle}
            onDauberColor={onDauberColor}
            onClose={onClose}
        />
    );
};
