import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';

export type IncrementButtonProps = HTMLAttributes<HTMLButtonElement> & {
    handleClick?: () => void;
    disabled?: boolean;
};

export const IncrementButtonContainer = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Signika-Bold;
    font-size: 3.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 0.625rem;
    padding: 0;

    ${({ theme: { incrementButton }, disabled }) => `
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        color: ${incrementButton.color};
        border: solid 0.094rem ${incrementButton.border};
        box-shadow: inset 0 0.25rem 0 0 ${incrementButton.boxShadow.first},
            inset 0 -0.25rem 0 0  ${incrementButton.boxShadow.second};

        ${
            disabled
                ? `
                background-image: none;
                box-shadow: none;
                background-color: gray;
            `
                : `
                background-image: linear-gradient(to bottom, ${incrementButton.backgroundGradient.upper},
                    ${incrementButton.backgroundGradient.lower} 99%)
                
            `
        }
        
    `};
`;

export const IncrementButton: FC<IncrementButtonProps> = ({
    handleClick,
    ...props
}) => {
    const PLUS = '+';

    const onClick = useSoundHandler(handleClick, 'click');

    return (
        <IncrementButtonContainer onClick={onClick} {...props}>
            {PLUS}
        </IncrementButtonContainer>
    );
};
