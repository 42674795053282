import { createState } from '../../lib/create-state';

import { BingoJackpotWinnerMessage } from '../../types/bingo-events/in/backend/jackpot-winner-message';

export type JackpotWinnerState = BingoJackpotWinnerMessage;

export const DEFAULT_JACKPOT_WINNER_STATE: JackpotWinnerState = {
    gameSessionId: 0,
    currency: '',
    amount: 0,
    userId: '',
    userAlias: '',
    pendingWin: false,
    playerSessionId: '',
};

export const [jackpotWinner, resetJackpotWinner] = createState(
    DEFAULT_JACKPOT_WINNER_STATE
);
