import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';
import {
    DauberCommonModal,
    DauberCommonModalProps,
    DauberCommonModalContainer,
} from '../DauberCommonModal';

export type DauberStyleModalProps = DauberCommonModalProps & {
    fill: string;
    onSelect: (value: string) => void;
    onDefault: () => void;
};

export const DauberStyleModalContainer = styled(DauberCommonModalContainer)`
    width: 32.25rem;
    height: 36rem;
    left: ${isMobile ? '0' : '17rem'};
`;

export const DauberStyleModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 3.25rem;
`;

export const DauberStyleModalTitle = styled.div`
    font-family: Signika-Bold;
    font-size: 2rem;
    color: ${({ theme: { dauberModal } }) => dauberModal.title.color};
`;

export const DauberStyleModalDefaultButton = styled.button`
    font-family: Signika-Medium;
    font-size: 1.25rem;
    border-radius: 0.313rem;
    padding: 0.25rem 1rem;
    cursor: pointer;

    ${({ theme: { settingsMenuButton } }) => `
        color: ${settingsMenuButton.color};
        box-shadow: ${settingsMenuButton.boxShadow.first} 0px 0.25rem 0px 0px inset,
        ${settingsMenuButton.boxShadow.second} 0px -0.25rem 0px 0px inset;
        border: 0.094rem solid ${settingsMenuButton.border};
        background-image: linear-gradient(
        ${settingsMenuButton.backgroundGradient.upper},
        ${settingsMenuButton.backgroundGradient.lower} 99%
    );
    `}
`;

export const DauberStyleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 1.5rem;
    row-gap: 1.875rem;
    height: 28rem;
    margin-top: 2rem;
    overflow-y: auto;

    ${({ theme: { dauberModal } }) => `
        background-color: ${dauberModal.background};
    

        &::-webkit-scrollbar-track {
            border-radius: 0.75rem;
            background-color: ${dauberModal.scroll.track};
            border-left: 0.438rem solid ${dauberModal.border};
            border-right: 0.438rem solid ${dauberModal.border};
        }

        &::-webkit-scrollbar {
            width: 1.625rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.75rem;
            background-color: ${dauberModal.scroll.thumb};
        }
    `};
`;

export const DauberStyleItem = styled.svg<{ fill: string }>`
    ${({ fill }) => fill && `fill: ${fill}`};
    cursor: pointer;
`;

export const DauberStyleModal: FC<DauberStyleModalProps> = ({
    fill,
    onSelect,
    onDefault,
    ...props
}) => {
    const theme = useTheme();

    const { dauberStyle } = theme.images;
    const { dauberColor } = theme;

    return (
        <DauberCommonModal {...props} Container={DauberStyleModalContainer}>
            <DauberStyleModalHeader>
                <DauberStyleModalTitle>Dauber Style</DauberStyleModalTitle>
                <DauberStyleModalDefaultButton onClick={onDefault}>
                    DEFAULT
                </DauberStyleModalDefaultButton>
            </DauberStyleModalHeader>
            <DauberStyleContainer>
                {Object.keys(dauberStyle).map((item) => {
                    const Component = dauberStyle[item];
                    return (
                        <DauberStyleItem
                            as={Component as any}
                            key={item}
                            fill={dauberColor[fill]}
                            onClick={() => onSelect(item)}
                        />
                    );
                })}
            </DauberStyleContainer>
        </DauberCommonModal>
    );
};
