import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const BallHoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    height: 10rem;
    border-radius: 5.563rem;
    overflow: hidden;
    ${({ theme: { ballHole } }) => `
        box-shadow: 0 0.375rem 0 0 ${ballHole.boxShadow.upper}, inset 0 0.313rem 0.5rem 0 ${ballHole.boxShadow.mid},
            inset 0 0.063rem 0 0 ${ballHole.boxShadow.lower};
        border: solid 0.5rem ${ballHole.border};
        background-image: radial-gradient(circle at 50% 50%, ${ballHole.backgroundGradient.upper}, ${ballHole.backgroundGradient.lower} 82%);
    `};
`;

export const BallHole: FC<PropsWithChildren> = (props) => {
    return <BallHoleContainer> {props.children} </BallHoleContainer>;
};
