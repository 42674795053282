import { FC } from 'react';
import { useHookstate } from '@hookstate/core';
import styled from 'styled-components';
import { BallCalls } from '../../components/BallCalls';
import { CallsCard } from '../../components/CallsCard';
import { useGameType } from '../../hooks/use-game-type';
import {
    calls,
    current,
    nextCalls as nextCallsState,
} from '../../stores/backend';

export const BallCallsPanelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const MarginedCallsCard = styled(CallsCard)`
    margin-right: 0.875rem;
`;

export const BallCallsPanel: FC = () => {
    const $current = useHookstate(current);
    const $calls = useHookstate(calls);
    const $nextCalls = useHookstate(nextCallsState);

    const gameType = useGameType($current);
    const called = $calls.value;
    const nextCalls = $nextCalls.value;

    return (
        <BallCallsPanelContainer>
            <MarginedCallsCard
                calls={called.length}
                nextCalls={nextCalls}
                gameType={gameType}
            />
            <BallCalls gameType={gameType} calledBalls={called} />
        </BallCallsPanelContainer>
    );
};
