import { useState, useEffect } from 'react';

export const useUpdatableState = <State>(defaultState: State) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        setState(defaultState);
    }, [defaultState]);

    return [state, setState] as const;
};
