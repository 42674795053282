import { ChatSocket } from '../../hooks/use-socket';
import { screenConfig as screenConfigState } from '../../stores/backend/screen-config';
import { getQuery } from '../../lib/query';

export type ChatPayload = ReturnType<typeof createChatPayload>;

export const createChatPayload = (socket: ChatSocket) => {
    const { USERID, SESSIONID } = getQuery();
    const { screenConfig } = screenConfigState;

    const connectChat = () => {
        socket.emit('connect:parlay', {
            appConfig: {
                configID: parseInt(
                    screenConfig.room.gameId.value.match(/\d+/g)![0]
                ),
                siteID: screenConfig.site.siteId.value,
                dtlID: screenConfig.room.localeId.value,
                style: null,
                chatPoweredBy: 'bingo',
            },
            userData: {
                id: USERID,
                number: SESSIONID,
            },
        });
    };

    const sendMessage = (msg: string) => {
        socket.emit('message:parlay', {
            message: msg,
        });
    };

    const changeRoom = (roomId: number) => {
        socket.emit('join:parlay', roomId);
    };

    const leaveRoom = (roomId: number) => {
        socket.emit('leave:parlay', roomId);
    };

    return {
        connectChat,
        sendMessage,
        changeRoom,
        leaveRoom,
    };
};
