import { BackendSocket } from '../../hooks/use-socket';
import { BingoJackpotContributionMessage } from '../../types/bingo-events/in/backend/jackpot-contribution-message';
import { jackpotContribution, current } from '../../stores/backend';

export const onJackpotContributionMessage = (socket: BackendSocket) => {
    const callback = (data: BingoJackpotContributionMessage) => {
        jackpotContribution.set(data);
        if (current.jackpot && current.jackpot.value?.id === data.id) {
            current.jackpot.merge({
                value: data.amount,
            });
        }
    };

    socket.on('bingo:JackpotContributionMessage', callback);

    return () => {
        socket.off('bingo:JackpotContributionMessage', callback);
    };
};
