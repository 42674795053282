import { useState, FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useHookstate } from '@hookstate/core';
import styled from 'styled-components';
import { BallCallsPanel } from '../BallCallsPanel';
import { CallBoardPanel } from '../CallBoardPanel';
import { Arrow } from '../../components/Arrow';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { current, meta } from '../../stores/backend';

type DisplayState = {
    container: 'ballCalls' | 'callBoard';
};

const BallCallsContainer = styled.div`
    position: relative;
    width: 100%;
    height: 12.125rem;
    overflow: hidden;
    margin-bottom: ${isMobile ? '1.1875rem' : '0.5rem'};
`;

const BallsContainer = styled.div<DisplayState>`
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 12.125rem;
    transition: 0.5s ease-in-out right;
    right: ${(props) => (props.container === 'ballCalls' ? '0' : '105%')};
`;

const CallBoardContainer = styled.div<DisplayState>`
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 12.125rem;
    transition: 0.5s ease-in-out left;
    left: ${(props) => (props.container === 'callBoard' ? '0' : '105%')};
`;

export const MarginedArrow = styled(Arrow)`
    ${(props) =>
        props.orientation === 'left'
            ? 'margin-left: 1.625rem'
            : 'margin-right: 1.125rem'};
`;

export const BallCallsSlider: FC = () => {
    const $current = useHookstate(current);

    const $meta = useHookstate(meta);

    const [display, setDisplay] = useState<DisplayState>({
        container: 'ballCalls',
    });

    const gameMode = $current.game_mode.value;

    const bought = useBoughtTickets($current);

    if (
        gameMode !== 'GAME_MODE' ||
        (gameMode === 'GAME_MODE' && bought === 0) ||
        $meta.panels.partWinner.value
    ) {
        return null;
    }

    return (
        <BallCallsContainer key={$current.game_sid?.value}>
            <BallsContainer container={display.container}>
                <MarginedArrow
                    orientation="right"
                    handleClick={() => {
                        setDisplay({ container: 'callBoard' });
                    }}
                />
                <BallCallsPanel />
            </BallsContainer>
            <CallBoardContainer container={display.container}>
                <CallBoardPanel />
                <MarginedArrow
                    orientation="left"
                    handleClick={() => {
                        setDisplay({ container: 'ballCalls' });
                    }}
                />
            </CallBoardContainer>
        </BallCallsContainer>
    );
};
