import { FC, HTMLAttributes, useState } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';
import { SideGamesThumbnail } from '../SideGamesThumbnail';
import { FOOTER_HEIGHT_REM, FOOTER_LANDSCAPE_HEIGHT_REM } from '../FooterSG';

export type SideGames = {
    title: string;
    thumbnail: string;
    src: string;
};

export type SideGamesListProps = HTMLAttributes<HTMLDivElement> & {
    size?: number;
    showFooter: boolean;
    sideGames: SideGames[];
    onToggleChat?: (size?: number) => void;
};

const SideGamesListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    row-gap: 2.25rem;
    column-gap: 1.875rem;
`;

const SideGamesListItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 20.5rem;
    height: 14.188rem;
    cursor: pointer;
`;

const SideGamesListTitle = styled.div`
    font-family: Signika-Bold;
    font-size: 1.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.theme.color};
`;

const SideGamesFrameContainer = styled.div<{
    isPortrait: boolean;
    showFooter: boolean;
}>`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;

    bottom: ${({ isPortrait, showFooter }) =>
        isPortrait
            ? `${FOOTER_HEIGHT_REM}rem`
            : `${showFooter ? FOOTER_LANDSCAPE_HEIGHT_REM : 0}rem`};
`;

const SideGamesFrameClose = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
`;

const SideGamesFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

export const SideGamesListMobile: FC<SideGamesListProps> = ({
    sideGames,
    showFooter,
    ...props
}) => {
    const theme = useTheme();
    const orientation = useMobileOrientation();
    const { closeButton } = theme.images;

    const [showIframe, setShowIframe] = useState<boolean>(false);
    const [iframeSource, setIframSource] = useState<string>('');

    const onClick = (src: string) => {
        setIframSource(src);
        setShowIframe((prevState) => !prevState);
    };

    const onClose = () => {
        setShowIframe((prevState) => !prevState);
    };

    return (
        <SideGamesListContainer {...props}>
            {sideGames.map(({ title, thumbnail, src }) => (
                <SideGamesListItems key={title} onClick={() => onClick(src)}>
                    <SideGamesThumbnail thumbnail={thumbnail} />
                    <SideGamesListTitle>{title}</SideGamesListTitle>
                </SideGamesListItems>
            ))}
            {showIframe && (
                <SideGamesFrameContainer
                    isPortrait={orientation.isPortrait}
                    showFooter={showFooter}
                >
                    <SideGamesFrame src={iframeSource} />
                    <SideGamesFrameClose
                        src={closeButton}
                        alt="Close Button Image"
                        onClick={onClose}
                    />
                </SideGamesFrameContainer>
            )}
        </SideGamesListContainer>
    );
};
