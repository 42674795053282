import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const BallTrayContainer = styled.div`
    width: 100%;
    min-width: 32.25rem;
    height: 7.75rem;
    padding-top: 0.25rem;
    padding-left: 9.625rem;
    border-radius: 5.563rem;
    overflow: hidden;

    ${({ theme: { ballTray } }) => `
        border: solid 0.5rem ${ballTray.border};
        box-shadow: 0 0.375rem 0 0 ${ballTray.boxShadow.first}, inset 0 0.313rem 0.5rem 0 ${ballTray.boxShadow.second};
        background-image: linear-gradient(
            to bottom,
            ${ballTray.background.gradientTop} -24%,
            ${ballTray.background.gradientMiddle} 49%,
            ${ballTray.background.gradientBottom} 126%
        );
    `};
`;

export const BallsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: -2.5rem;
`;

export const BallTray: FC<PropsWithChildren> = (props) => {
    return (
        <BallTrayContainer {...props}>
            <BallsWrapper>{props.children}</BallsWrapper>
        </BallTrayContainer>
    );
};
