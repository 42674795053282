import { useEffect, useState } from 'react';
import { createTimer } from '../lib/create-timer';

export const useCountdown = (to: Date) => {
    const [formatted, setFormatted] = useState('');
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const time = to.getTime();

        if (isNaN(time)) {
            return;
        }

        const { end } = createTimer(time, (seconds) => {
            const mm = Math.floor(seconds / 60)
                .toString()
                .padStart(2, '0');
            const ss = (seconds % 60).toString().padStart(2, '0');

            setFormatted(`${mm}:${ss}`);
            setSeconds(seconds);
        });

        return end;
    }, [to]);

    return { formatted, seconds };
};
