import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

type ModalLabelProps = HTMLAttributes<HTMLDivElement> & {
    label: string;
};

export const ModalLabelContainer = styled.div`
    font-family: Signika-Bold;
    font-size: 2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: ${({ theme }) => theme.modalLabel.color};
`;

export const ModalLabel: FC<ModalLabelProps> = ({ label, ...props }) => {
    return <ModalLabelContainer {...props}>{label}</ModalLabelContainer>;
};
